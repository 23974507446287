import { useState } from 'react';
import MuiTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

interface IProps extends TooltipProps {
  show?: boolean;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '0.75rem',
  },
}));

function Tooltip({ children, show, ...rest }: IProps) {
  const [open, setOpen] = useState(false);

  function handleOpenCloseTooltip(open: boolean) {
    if (show === undefined || show === true) {
      setOpen(open);
    }
  }

  return (
    <StyledTooltip
      {...rest}
      open={open}
      onOpen={() => handleOpenCloseTooltip(true)}
      onClose={() => handleOpenCloseTooltip(false)}
    >
      {children}
    </StyledTooltip>
  );
}

export default Tooltip;
