import axios from '../axios';
import { ITask } from '../types/tasks';

export function createTask(requestBody: Partial<ITask>) {
  return axios.post<ITask>('/task/create', requestBody);
}

export function updateTask(requestBody: Partial<ITask>) {
  return axios.post<ITask>('/task/update', requestBody);
}

export function getTasks(orderId: string, orderTeamId: string) {
  return axios.get<ITask[]>('/task/findByTeam', { params: { orderId, orderTeamId } });
}

export function deleteTask(taskId: string) {
  return axios.delete('/task/delete', { params: { id: taskId } });
}
