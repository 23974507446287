import { Currency, IAttachment } from './common';

export enum InvoiceStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  CANCEL = 'CANCEL',
}

export interface IInvoice {
  _id: string;
  invoiceId: string;
  orderId: string;
  teamId: string;
  teamName: string;
  outSourceClientId: string;
  outSourceClientName: string;
  amount: number;
  currency: Currency;
  description: string;
  paymentSource: string;
  status: InvoiceStatus;
  attachments: IAttachment[];
}

export interface IFilterState {
  invoiceId: string;
  orderId: string;
  teamName: string;
  status: string;
}
