import { GridColDef, GridRowParams, GridRowsProp } from '@mui/x-data-grid';
import { INVOICE_STATUS_COLOR_MAP, INVOICE_STATUS_MAP } from '../../constants';

import SS from '../shared/styles';

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  type?: 'invoices' | 'related-invoices';
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  onRowClick?(params: GridRowParams): void;
}

function InvoicesTable(props: IProps) {
  const {
    page,
    pageSize,
    rows,
    totalElements,
    loading,
    type = 'invoices',
    onPageChange,
    onPageSizeChange,
    onRowClick,
  } = props;
  const isRelatedInvoices = type === 'related-invoices';

  const columns: GridColDef[] = [
    {
      field: 'invoiceId',
      headerName: 'Invoice Id',
      flex: 1,
      sortable: false,
      ...(isRelatedInvoices && {
        renderCell: (params) => (
          <SS.Link to={`/invoices/${window.btoa(params.row._id)}`} target="_blank">
            {params.row.invoiceId}
          </SS.Link>
        ),
      }),
    },
    { field: 'orderId', headerName: 'Order Id', flex: 1, sortable: false },
    { field: 'teamName', headerName: 'Team Name', flex: 1, sortable: false },
    { field: 'outSourceClientName', headerName: 'External User', flex: 1, sortable: false },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      sortable: false,
      renderCell: (params) => `${params.row?.currency} ${params.row?.amount}`,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <SS.Status sx={{ bgcolor: INVOICE_STATUS_COLOR_MAP[params.row?.status] }}>
          {INVOICE_STATUS_MAP[params.row?.status] || params.row?.status}
        </SS.Status>
      ),
    },
  ];

  return (
    <SS.DataGrid
      sx={{
        ...(isRelatedInvoices && { border: 'none' }),
        '.MuiDataGrid-row': { cursor: onRowClick ? 'pointer' : 'default' },
      }}
      rows={rows}
      columns={columns}
      getRowId={(row) => row._id}
      disableColumnFilter
      disableColumnMenu
      disableSelectionOnClick
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      rowCount={totalElements}
      page={page}
      pageSize={pageSize}
      paginationMode="server"
      loading={loading}
      pagination
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      {...(!isRelatedInvoices && { onRowClick })}
    />
  );
}

export default InvoicesTable;
