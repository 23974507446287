import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { red } from '@mui/material/colors';
import { Link } from 'react-router-dom';

const Styles = {
  PageTitle: styled(Typography)(() => ({
    color: '#172B70',
  })),
  BorderedBox: styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(1.5, 0, 1.5),
    border: '1px solid #E4EBF8',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
  })),
  DataGrid: styled(DataGrid)(() => ({
    height: 'calc(100vh - 200px) !important',
    '.MuiDataGrid-row': { cursor: 'pointer' },
  })),
  DeleteTitle: styled(Typography)(({ theme }) => ({
    fontSize: '1.125rem',
    color: red[500],
    marginBottom: theme.spacing(2),
    borderBottom: '1px solid #d8dee4',
  })),
  Link: styled(Link)(() => ({
    color: '#172B70',
  })),
  Status: styled('span')(({ theme }) => ({
    padding: theme.spacing(0.5),
    fontSize: 12,
    color: '#ffffff',
    borderRadius: 4,
  })),
  Snackbar: styled(Snackbar)(({ theme }) => ({
    '& .MuiPaper-root': { backgroundColor: '#ffffff', color: 'unset' },
    '& .MuiSnackbarContent-message': {
      maxWidth: 304,
      [theme.breakpoints.down('xs')]: { maxWidth: 208 },
    },
  })),
};

export default Styles;
