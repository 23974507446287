import { Currency, PaymentMethod } from './types/common';
import { InvoiceStatus } from './types/invoices';
import { OrderStatus, PaymentStatus } from './types/orders';
import { TaskStatus, TaskType } from './types/tasks';
import { UserRole, UserZone } from './types/users';

const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const PASSWORD_REGEXP = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const NUMBERS_REGEXP = /^\d+$/;
const URL_REGEXP =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const PAN_REGEXP = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

const APP_URL = process.env.REACT_APP_URL || 'http://localhost:3000';

const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY;

const REGEX = {
  EMAIL: EMAIL_REGEXP,
  PASSWORD: PASSWORD_REGEXP,
  NUMBERS: NUMBERS_REGEXP,
  URL: URL_REGEXP,
  PAN: PAN_REGEXP,
};

const LOCAL_STORAGE_TOKEN_KEY_MAP = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
};

const DEFAULT_ORDER_STATUS = [
  'NEW',
  'ASSIGNED',
  'IN_PROGRESS',
  'REVIEW',
  'RE_WORK',
  'OUTSOURCE',
  'COMPLETED',
];

const USER_ROLE_MAP: { [key: string]: string } = {
  [UserRole.EMPLOYEE]: 'Employee',
  [UserRole.MANAGER]: 'Manager',
  [UserRole.SALES]: 'Sales',
  [UserRole.SUPER_ADMIN]: 'Super Admin',
  [UserRole.TEAM_LEAD]: 'Team Lead',
};

const USER_ZONE_MAP: { [key: string]: string } = {
  [UserZone.ERODE]: 'Erode',
  [UserZone.NAMAKKAL]: 'Namakkal',
};

const EXTERNAL_USER_PAYMENT_METHOD_MAP: { [key: string]: string } = {
  [PaymentMethod.BANK]: 'Bank',
  [PaymentMethod.PAYPAL]: 'Paypal',
};

const ORDER_PAYMENT_METHOD_MAP: { [key: string]: string } = {
  [PaymentMethod.BANK]: 'Bank',
  [PaymentMethod.CRYPTO]: 'Crypto',
  [PaymentMethod.PAYPAL]: 'Paypal',
  [PaymentMethod.SKRILL]: 'Skrill',
  [PaymentMethod.STRIPE]: 'Stripe',
  [PaymentMethod.TWO_CHECKOUT]: 'Two Checkout',
  [PaymentMethod.WEB_MONEY]: 'Web Money',
};

const PAYMENT_STATUS_MAP: { [key: string]: string } = {
  [PaymentStatus.PAID]: 'Paid',
  [PaymentStatus.PARTIAL_PAID]: 'Partially Paid',
  [PaymentStatus.UN_PAID]: 'Unpaid',
  [PaymentStatus.REFUND]: 'Refund',
};

const ORDER_STATUS_MAP: { [key: string]: string } = {
  [OrderStatus.NEW]: 'New',
  [OrderStatus.IN_PROGRESS]: 'In Progress',
  [OrderStatus.COMPLETED]: 'Completed',
  [OrderStatus.CORRECTION]: 'Correction',
  [OrderStatus.CANCELED]: 'Canceled',
};

const ORDER_STATUS_COLOR_MAP: { [key: string]: string } = {
  [OrderStatus.NEW]: '#1976d2',
  [OrderStatus.IN_PROGRESS]: '#673ab7',
  [OrderStatus.COMPLETED]: '#2e7d32',
  [OrderStatus.CORRECTION]: '#663c00',
  [OrderStatus.CANCELED]: '#f44336',
};

const ORDER_TEAM_STATUS_COLOR_MAP: { [key: string]: string } = {
  NEW: '#1976d2',
  IN_PROGRESS: '#673ab7',
  COMPLETED: '#2e7d32',
  RE_WORK: '#663c00',
  OUTSOURCE: '#f44336',
  REVIEW: '#ff5722',
  ASSIGNED: '#9c27b0',
};

const INVOICE_STATUS_MAP: { [key: string]: string } = {
  [InvoiceStatus.PENDING]: 'Pending',
  [InvoiceStatus.PAID]: 'Paid',
  [InvoiceStatus.CANCEL]: 'Canceled',
};

const INVOICE_STATUS_COLOR_MAP: { [key: string]: string } = {
  [InvoiceStatus.PENDING]: '#1976d2',
  [InvoiceStatus.PAID]: '#2e7d32',
  [InvoiceStatus.CANCEL]: '#f44336',
};

const TASK_STATUS_MAP: { [key: string]: string } = {
  [TaskStatus.OPEN]: 'Open',
  [TaskStatus.REVIEW]: 'Review',
  [TaskStatus.CLOSED]: 'Closed',
  [TaskStatus.RE_OPEN]: 'Reopen',
};

const TASK_TYPE_MAP: { [key: string]: string } = {
  [TaskType.IN_HOUSE]: 'In House',
  [TaskType.OUTSOURCE]: 'Outsource',
};

const ROUTE_ROLE_MAP = {
  users: [UserRole.SUPER_ADMIN],
  'external-users': [UserRole.SUPER_ADMIN, UserRole.MANAGER, UserRole.TEAM_LEAD],
  clients: [UserRole.SUPER_ADMIN, UserRole.SALES],
  teams: [UserRole.SUPER_ADMIN],
  categories: [UserRole.SUPER_ADMIN],
  invoices: [UserRole.SUPER_ADMIN, UserRole.MANAGER, UserRole.TEAM_LEAD],
  reports: [UserRole.SUPER_ADMIN],
};

const CURRENCIES = Object.keys(Currency) as Currency[];

export {
  REGEX,
  APP_URL,
  TINY_API_KEY,
  LOCAL_STORAGE_TOKEN_KEY_MAP,
  USER_ROLE_MAP,
  USER_ZONE_MAP,
  PAYMENT_STATUS_MAP,
  ORDER_PAYMENT_METHOD_MAP,
  ORDER_STATUS_MAP,
  ORDER_STATUS_COLOR_MAP,
  ORDER_TEAM_STATUS_COLOR_MAP,
  INVOICE_STATUS_MAP,
  INVOICE_STATUS_COLOR_MAP,
  DEFAULT_ORDER_STATUS,
  TASK_STATUS_MAP,
  TASK_TYPE_MAP,
  ROUTE_ROLE_MAP,
  EXTERNAL_USER_PAYMENT_METHOD_MAP,
  CURRENCIES,
};
