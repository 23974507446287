import { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import * as yup from 'yup';

import S from './styles';
import { REGEX } from '../../constants';
import { signIn } from '../../requests/auth';
import localStorage from '../../utils/local-storage';
import useSnackbar from '../../hooks/useSnackbar';

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().matches(REGEX.PASSWORD, { message: ' ' }).required('Password is required'),
});

function SignIn() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async ({ email, password }) => {
      setIsLoading(true);
      try {
        const { jwtToken, refreshToken } = (await signIn({ email, password })).data;
        localStorage.setAccessToken(jwtToken);
        localStorage.setRefreshToken(refreshToken);
        setIsLoading(false);
        const pathname = searchParams.get('redirectUrl') || '/';
        navigate(pathname, { replace: true });
      } catch (error) {
        const errorMessage =
          ((error as AxiosError)?.response?.data as any)?.message ||
          'An error occurred. Please try again.';
        setIsLoading(false);
        showSnackbar({ severity: 'error', message: errorMessage });
      }
    },
  });

  return (
    <S.SignInContainer>
      <Container maxWidth="sm">
        <S.Title variant="h5">Welcome Back!</S.Title>
        <Typography variant="h6" className="sub-title">
          Sign in
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            type="email"
            name="email"
            label="Email"
            sx={{ mt: 1, mb: 1 }}
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            variant="outlined"
            type="password"
            name="password"
            label="Password"
            sx={{ mt: 1, mb: 1 }}
            fullWidth
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
            <LoadingButton
              color="primary"
              variant="contained"
              size="large"
              className="sign-in-button"
              type="submit"
              loading={isLoading}
            >
              SIGN IN
            </LoadingButton>
            <S.Link to="/password/reset">Forgot Password?</S.Link>
          </Stack>
        </form>
      </Container>
    </S.SignInContainer>
  );
}

export default SignIn;
