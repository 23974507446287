import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';
import capitalize from 'lodash/capitalize';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Autocomplete from '@mui/material/Autocomplete';
import DialogTitle from '@mui/material/DialogTitle';

import Messages from './Messages';
import Attachments from '../shared/Attachments';
import S from './styles';

import useAuth from '../../hooks/useAuth';
import useSnackbar from '../../hooks/useSnackbar';
import { ITask, TaskStatus, TaskType } from '../../types/tasks';
import { createTask, updateTask } from '../../requests/tasks';
import { ITeam } from '../../types/teams';
import { getTaskStatusOptions, getTaskTypeOptions } from '../../utils/utils';
import { IOrderTeam } from '../../types/orders';
import { IAttachment, IDropdown, MessageType } from '../../types/common';
import { UserRole } from '../../types/users';
import { getExternalUsersForDropdown } from '../../requests/users';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface IProps {
  open: boolean;
  task: ITask | null;
  orderId: string;
  mode: 'create' | 'update';
  orderTeam: IOrderTeam;
  team?: ITeam;
  readOnly?: boolean;
  handleClose(refreshTasks?: boolean): void;
}

interface IExternalUsersState {
  content: IDropdown[];
  totalElements: number;
}

const validationSchema = yup.object({
  name: yup.string().required('Title is required'),
  description: yup.string(),
  assignedToId: yup.string(),
  assignedToName: yup.string(),
  status: yup.string(),
  type: yup.string(),
  attachments: yup.array().of(yup.object()),
});

function AddEditTask({
  open,
  mode,
  orderId,
  task,
  orderTeam,
  team,
  readOnly,
  handleClose,
}: IProps) {
  const { user } = useAuth();
  const isCreateMode = mode === 'create';
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [externalUsers, setExternalUsers] = useState<IExternalUsersState>({
    content: [],
    totalElements: 0,
  });
  const formik = useFormik<Partial<ITask>>({
    validationSchema,
    initialValues: getInitialValues(task),
    onSubmit: handleSubmit,
  });
  const isSales = user?.role === UserRole.SALES;

  useEffect(() => {
    if (open) {
      fetchExternalUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    formik.resetForm({ values: getInitialValues(task) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, task]);

  function fetchExternalUsers() {
    getExternalUsersForDropdown({ page: 0, pageSize: 1000 })
      .then((res) =>
        setExternalUsers({ content: res.data.content, totalElements: res.data.totalElements })
      )
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message ||
          'An error occurred while fetching external users.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function getInitialValues(data: ITask | null) {
    return {
      name: data?.name || '',
      description: data?.description || '',
      assignedToId: data?.assignedToId || '',
      assignedToName: data?.assignedToName || '',
      status: data?.status || TaskStatus.OPEN,
      type: data?.type || TaskType.IN_HOUSE,
      attachments: data?.attachments || [],
    };
  }

  function handleSubmit(data: Partial<ITask>) {
    setIsLoading(true);
    const request = isCreateMode ? createTask : updateTask;
    request({
      ...data,
      orderId,
      orderTeamId: orderTeam.id,
      taskId: task?.taskId,
      id: task?.id,
    })
      .then(() => {
        showSnackbar({ severity: 'success', message: `Task ${mode}d successfully` });
        handleClose(true);
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsLoading(false));
  }

  function handleSetAttachments(attachments: IAttachment[]) {
    formik.setFieldValue('attachments', attachments);
  }

  function handleDialogClose() {
    handleClose(false);
  }

  function getAssignee() {
    return team?.members?.find((m) => m.id === formik.values.assignedToId) || null;
  }

  return (
    <Dialog fullWidth maxWidth="md" scroll="body" open={open} onClose={handleDialogClose}>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <DialogTitle>
          {capitalize(mode)} Task
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 24,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pt: `8px !important` }}>
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                name="type"
                label="Type"
                size="small"
                fullWidth
                value={formik.values.type}
                onChange={formik.handleChange}
                InputProps={{ readOnly }}
              >
                {getTaskTypeOptions().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                name="name"
                label="Title"
                size="small"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                InputProps={{ readOnly }}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <S.MessageInputContainer sx={{ padding: 0, border: 0 }}>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ size: ['small', false, 'large', 'huge'] }],
                      ['bold', 'italic'],
                      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                      ['link', 'clean', 'unlink'],
                    ],
                  }}
                  readOnly={readOnly}
                  placeholder="Description"
                  value={formik.values.description}
                  onChange={(html) => formik.setFieldValue('description', html)}
                />
              </S.MessageInputContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                options={
                  (formik.values.type === TaskType.OUTSOURCE
                    ? externalUsers.content
                    : team?.members) || []
                }
                value={getAssignee()}
                onChange={(_, newValue) => {
                  formik.setFieldValue('assignedToId', newValue?.id);
                  formik.setFieldValue('assignedToName', newValue?.name);
                }}
                readOnly={readOnly}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Assignee"
                    size="small"
                    error={formik.touched.assignedToId && Boolean(formik.errors.assignedToId)}
                    helperText={formik.touched.assignedToId && formik.errors.assignedToId}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                name="status"
                label="Status"
                size="small"
                fullWidth
                value={formik.values.status}
                onChange={formik.handleChange}
                InputProps={{ readOnly }}
              >
                {getTaskStatusOptions().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Attachments
            attachments={formik.values.attachments || []}
            setAttachments={handleSetAttachments}
            style={{ marginTop: 16 }}
            disabled={readOnly}
            disableDownload={isSales}
          />
          {!readOnly && (
            <LoadingButton
              sx={{ mt: 3 }}
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
            >
              {isCreateMode ? 'Create Task' : 'Save Changes'}
            </LoadingButton>
          )}
        </DialogContent>
      </form>
      {!isCreateMode && task && (
        <>
          <h4 style={{ color: '#172B70', marginTop: 0, padding: '0px 24px' }}>Messages</h4>
          <Messages id={task.id} messageType={MessageType.TASK} />
        </>
      )}
    </Dialog>
  );
}

export default AddEditTask;
