import dayjs from 'dayjs';
import MuiAvatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { IAudit } from '../../types/audits';

const AuditItem = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  '& > div:first-child': { flex: 1 },
  '& > div:last-child': { textAlign: 'right' },
  '&:nth-child(odd)': { backgroundColor: '#F5F5F5', borderRadius: 4 },
}));

const ModifiedByNameContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(0.5),
}));

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  border: '0.3px solid #FF6F24',
  backgroundColor: '#FFEBE6',
  color: '#FF6F24',
  marginRight: theme.spacing(1),
}));

function Audits({ audits }: { audits: IAudit[] }) {
  function getFormattedDate(date: string) {
    return dayjs(date).format('DD MMM YYYY');
  }

  function getFormattedTime(date: string) {
    return dayjs(date).format('h:mm A');
  }

  return (
    <div>
      {audits.length ? (
        audits.map((audit) => (
          <AuditItem key={audit.id}>
            <div>
              <ModifiedByNameContainer>
                <div>
                  <Avatar></Avatar>
                </div>
                <Box sx={{ color: '#919AAD', fontSize: '0.875rem' }}>{audit.modifiedByName}</Box>
              </ModifiedByNameContainer>
              <Box sx={{ fontSize: '0.875rem', mt: 1 }}>{audit.detail || ''}</Box>
            </div>
            <div>
              <Box sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                {getFormattedDate(audit.modifiedTime)}
              </Box>
              <Box sx={{ fontSize: '0.75rem' }}>{getFormattedTime(audit.modifiedTime)}</Box>
            </div>
          </AuditItem>
        ))
      ) : (
        <div>No history available</div>
      )}
    </div>
  );
}

export default Audits;
