import * as yup from 'yup';
import { useFormik } from 'formik';
import { AxiosError } from 'axios';
import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SS from '../shared/styles';
import Audits from '../shared/Audits';
import PageTitleWithBack from '../shared/PageTitleWithBack';

import { ITeam } from '../../types/teams';
import useSnackbar from '../../hooks/useSnackbar';
import { createTeam, getTeamDetails, updateTeam } from '../../requests/teams';
import { getUsersByRoles } from '../../requests/users';
import { IMinimalUserWithRole, UserRole } from '../../types/users';
import { AuditEntity, IAudit } from '../../types/audits';
import { getAuditsByEntity } from '../../requests/audits';
import { DEFAULT_ORDER_STATUS } from '../../constants';

interface IProps {
  mode: 'create' | 'update';
  id: string;
  fetchTeams(): void;
  onDeleteClick(id: string, name: string, callback: () => void): void;
}

const validationSchema = yup.object({
  name: yup.string().required('Team name is required'),
  manager: yup.object().nullable().required('Manager is required'),
  lead: yup.object().nullable().required('Lead is required'),
  employees: yup.array().min(1, 'At least one employee is required').required(),
  orderStatus: yup
    .array()
    .of(yup.string())
    .min(1, 'At least one order status is required')
    .required(),
});

function TeamDetails({ mode, id, fetchTeams, onDeleteClick }: IProps) {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [teamDetails, setTeamDetails] = useState<ITeam | null>(null);
  const [teamLeads, setTeamLeads] = useState<IMinimalUserWithRole[]>([]);
  const [managers, setManagers] = useState<IMinimalUserWithRole[]>([]);
  const [employees, setEmployees] = useState<IMinimalUserWithRole[]>([]);
  const [audits, setAudits] = useState<IAudit[]>([]);
  const [showAudits, setShowAudits] = useState(false);
  const formik = useFormik<Partial<ITeam>>({
    validationSchema: validationSchema,
    initialValues: getInitialValues(null, mode),
    onSubmit: handleSubmit,
  });
  const isCreateMode = mode === 'create';

  useEffect(() => {
    if (!isCreateMode && id) {
      fetchTeamDetails(id);
    }
    fetchTeamLeads();
    fetchManagers();
    fetchEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, id]);

  function getInitialValues(team: ITeam | null, mode: 'create' | 'update'): Partial<ITeam> {
    return {
      name: team?.name || '',
      lead: team?.lead,
      manager: team?.manager,
      employees: team?.employees || [],
      orderStatus: DEFAULT_ORDER_STATUS,
    };
  }

  function fetchTeamDetails(id: string) {
    setIsLoading(true);
    getTeamDetails(id)
      .then((res) => {
        setIsLoading(false);
        setTeamDetails(res.data);
        fetchAudits(res.data._id);
        formik.resetForm({ values: getInitialValues(res.data, mode) });
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        const errorMessage =
          (error?.response?.data as any)?.message ||
          'An error occurred while fetching team details. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchAudits(id: string) {
    getAuditsByEntity(id, AuditEntity.TEAM)
      .then((res) => setAudits(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message ||
          'An error occurred while fetching audits. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchTeamLeads() {
    getUsersByRoles([UserRole.TEAM_LEAD])
      .then((res) => setTeamLeads(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred while fetching team leads.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchManagers() {
    getUsersByRoles([UserRole.MANAGER])
      .then((res) => setManagers(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred while fetching managers.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchEmployees() {
    getUsersByRoles([UserRole.EMPLOYEE])
      .then((res) => setEmployees(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred while fetching employees.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleSubmit(data: Partial<ITeam>) {
    if (!isCreateMode && !teamDetails?.status) {
      return;
    }

    setIsUpdating(true);
    const request = isCreateMode ? createTeam : updateTeam;
    const requestBody: Partial<ITeam> = {
      ...data,
      ...(!isCreateMode && { _id: teamDetails?._id }),
    };
    request(requestBody)
      .then((res) => {
        setIsUpdating(false);
        setTeamDetails(res.data);
        showSnackbar({ severity: 'success', message: `Team ${mode}d successfully` });
        if (isCreateMode) {
          navigate(`/teams/${window.btoa(res.data._id)}`, { replace: true });
        } else {
          fetchAudits(res.data._id);
        }
        fetchTeams();
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        setIsUpdating(false);
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleDelete() {
    if (teamDetails) {
      onDeleteClick(teamDetails._id, teamDetails.name, () => {
        navigate('/teams', { replace: true });
        fetchTeams();
      });
    }
  }

  function handleBackClick() {
    navigate('/teams', { replace: true });
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <PageTitleWithBack title={`${capitalize(mode)} Team`} onBackClick={handleBackClick} />
        </div>
        {!isCreateMode && teamDetails?.status && (
          <div>
            <Button
              color="error"
              variant="contained"
              onClick={handleDelete}
              startIcon={<DeleteIcon />}
            >
              Delete Team
            </Button>
          </div>
        )}
      </Box>
      <SS.BorderedBox>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  label="Team Name"
                  fullWidth
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  options={teamLeads}
                  value={formik.values.lead || null}
                  onChange={(_, newValue) => formik.setFieldValue('lead', newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="lead"
                      label="Lead Name"
                      error={formik.touched.lead && Boolean(formik.errors.lead)}
                      helperText={formik.touched.lead && formik.errors.lead}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  options={managers}
                  value={formik.values.manager || null}
                  onChange={(_, newValue) => formik.setFieldValue('manager', newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="manager"
                      label="Manager Name"
                      error={formik.touched.manager && Boolean(formik.errors.manager)}
                      helperText={formik.touched.manager && formik.errors.manager}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  options={employees}
                  value={formik.values.employees}
                  onChange={(_, newValue) => formik.setFieldValue('employees', newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="employees"
                      label="Employees"
                      error={formik.touched.employees && Boolean(formik.errors.employees)}
                      helperText={formik.touched.employees && formik.errors.employees}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {(isCreateMode || teamDetails?.status) && (
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                loading={isUpdating}
              >
                {isCreateMode ? 'Create Team' : 'Save Changes'}
              </LoadingButton>
            )}
            {!isCreateMode && teamDetails && (
              <>
                <Box sx={{ mt: 2, mb: 2, pt: 2, borderTop: '1px solid #d8dee4' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={showAudits ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    onClick={() => setShowAudits(!showAudits)}
                  >
                    {`${showAudits ? 'Hide' : 'Show'} History`}
                  </Button>
                </Box>
                <Collapse in={showAudits}>
                  <Audits audits={audits} />
                </Collapse>
              </>
            )}
          </form>
        )}
      </SS.BorderedBox>
    </Container>
  );
}

export default TeamDetails;
