export function getReportTitle(reportType: string | undefined) {
  switch (reportType) {
    case 'sales':
      return 'Sales';
    case 'clients':
      return 'Clients';
    default:
      return null;
  }
}
