import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import SS from '../shared/styles';
import useAuth from '../../hooks/useAuth';
import useSnackbar from '../../hooks/useSnackbar';
import { IUser } from '../../types/users';
import { REGEX } from '../../constants';
import { updateUserDetails } from '../../requests/users';

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phoneNo: yup.string(),
  password: yup.string().matches(REGEX.PASSWORD),
});

function Profile() {
  const { user, fetchUserDetails } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const formik = useFormik<Partial<IUser>>({
    validationSchema: validationSchema,
    initialValues: getInitialValues(user),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    fetchUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      formik.resetForm({ values: getInitialValues(user) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function getInitialValues(user: IUser | null): Partial<IUser> {
    return {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phoneNo: user?.phoneNo || '',
      email: user?.email || '',
      empId: user?.empId || '',
      password: '',
    };
  }

  function handleSubmit({ password, ...data }: Partial<IUser>) {
    setIsUpdating(true);
    updateUserDetails({ email: user?.email, ...data, ...(password && { password }) })
      .then(() => {
        setIsUpdating(false);
        showSnackbar({ severity: 'success', message: 'Profile updated successfully' });
        fetchUserDetails();
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        setIsUpdating(false);
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  return (
    <Container maxWidth="md">
      <SS.PageTitle variant="h6" sx={{ mb: 1 }}>
        Profile
      </SS.PageTitle>
      <form onSubmit={formik.handleSubmit}>
        <SS.BorderedBox>
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="email"
                label="Email"
                fullWidth
                InputProps={{ readOnly: true }}
                value={formik.values.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="empId"
                label="Employee Id"
                fullWidth
                InputProps={{ readOnly: true }}
                value={formik.values.empId}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstName"
                label="First Name"
                fullWidth
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="lastName"
                label="Last Name"
                fullWidth
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="phoneNo"
                label="Phone Number"
                fullWidth
                value={formik.values.phoneNo}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={2} columnSpacing={1} mt={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                name="password"
                autoComplete="off"
                label="New Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText="Use 8 to 32 characters, one digit, one symbol (!@#$%^&amp;*), one lowercase and uppercase letter"
              />
            </Grid>
          </Grid>
          <LoadingButton
            color="primary"
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            loading={isUpdating}
          >
            Save Changes
          </LoadingButton>
        </SS.BorderedBox>
      </form>
    </Container>
  );
}

export default Profile;
