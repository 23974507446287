import * as yup from 'yup';
import { useFormik } from 'formik';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import EuroIcon from '@mui/icons-material/Euro';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import Attachments from '../shared/Attachments';
import Messages from '../Orders/Messages';
import PageTitleWithBack from '../shared/PageTitleWithBack';
import Audits from '../shared/Audits';
import SS from '../shared/styles';

import useSnackbar from '../../hooks/useSnackbar';
import { IInvoice, InvoiceStatus } from '../../types/invoices';
import { Currency, IAttachment } from '../../types/common';
import { createInvoice, getInvoiceDetails, updateInvoice } from '../../requests/invoices';
import { getInvoiceStatusOptions } from '../../utils/utils';
import { IOrderList } from '../../types/orders';
import { getOrders } from '../../requests/orders';
import { getOrderTeams } from '../../requests/teams';
import { IMinimalTeam } from '../../types/teams';
import { IExternalUser } from '../../types/users';
import { getExternalUsers } from '../../requests/users';
import { AuditEntity, IAudit } from '../../types/audits';
import { getAuditsByEntity } from '../../requests/audits';

interface IProps {
  mode?: 'create' | 'update';
  id: string;
  fetchInvoices(): void;
}

const validationSchema = yup.object({
  orderId: yup.string().required('Order is required'),
  teamId: yup.string().required('Team is required'),
  outSourceClientId: yup.string().required('External user is required'),
  amount: yup.number().required('Amount is required'),
  currency: yup.string(),
  description: yup.string(),
  paymentSource: yup.string(),
  status: yup.string(),
  attachments: yup.array().of(yup.object()),
});

function InvoiceDetails({ mode = 'create', id, fetchInvoices }: IProps) {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [isExternalUsersLoading, setIsExternalUsersLoading] = useState(false);
  const [isTeamsLoading, setIsTeamsLoading] = useState(false);
  const [audits, setAudits] = useState<IAudit[]>([]);
  const [activeTab, setActiveTab] = useState('comments');
  const [orders, setOrders] = useState<{
    content: IOrderList[];
    totalElements: number;
  }>({ content: [], totalElements: 0 });
  const [externalUsers, setExternalUsers] = useState<{
    content: IExternalUser[];
    totalElements: number;
  }>({ content: [], totalElements: 0 });
  const [teams, setTeams] = useState<IMinimalTeam[]>([]);
  const [invoiceDetails, setInvoiceDetails] = useState<IInvoice | null>(null);
  const formik = useFormik<Partial<IInvoice>>({
    validationSchema,
    initialValues: getInitialValues(null),
    onSubmit: handleSubmit,
  });
  let ordersCancelToken: CancelTokenSource;
  let externalUsersCancelToken: CancelTokenSource;
  const orderId = formik.values.orderId;
  const isCreateMode = mode === 'create';

  useEffect(() => {
    if (!isCreateMode && id) {
      fetchInvoiceDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, id]);

  useEffect(() => {
    if (orderId) {
      fetchTeams(orderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  function getInitialValues(invoice: IInvoice | null): Partial<IInvoice> {
    return {
      orderId: invoice?.orderId || '',
      teamId: invoice?.teamId || '',
      teamName: invoice?.teamName || '',
      outSourceClientId: invoice?.outSourceClientId || '',
      outSourceClientName: invoice?.outSourceClientName || '',
      amount: invoice?.amount || ('' as any),
      currency: invoice?.currency || Currency.INR,
      description: invoice?.description || '',
      paymentSource: invoice?.paymentSource || '',
      status: invoice?.status || InvoiceStatus.PENDING,
      attachments: invoice?.attachments || [],
    };
  }

  function fetchOrdersBySearch(event: React.ChangeEvent<{}>, search: string) {
    if (event.type === 'change' && search.length > 1) {
      if (typeof ordersCancelToken !== 'undefined') {
        ordersCancelToken.cancel('Operation canceled due to new request.');
      }

      ordersCancelToken = axios.CancelToken.source();
      fetchOrders(search);
    }
  }

  function fetchOrders(orderId: string) {
    setIsOrdersLoading(true);
    getOrders(
      {
        page: 0,
        pageSize: 100,
        filterFields: [
          {
            key: 'orderId',
            searchOperation: 'like',
            value: orderId,
          },
        ],
      },
      ordersCancelToken ? ordersCancelToken.token : undefined
    )
      .then((res) => {
        setOrders({ content: res.data.content, totalElements: res.data.totalElements });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsOrdersLoading(false));
  }

  function fetchExternalUsersBySearch(event: React.ChangeEvent<{}>, search: string) {
    if (event.type === 'change' && search.length > 1) {
      if (typeof externalUsersCancelToken !== 'undefined') {
        externalUsersCancelToken.cancel('Operation canceled due to new request.');
      }

      externalUsersCancelToken = axios.CancelToken.source();
      fetchExternalUsers(search);
    }
  }

  function fetchExternalUsers(name: string) {
    setIsExternalUsersLoading(true);
    getExternalUsers(
      {
        page: 0,
        pageSize: 100,
        filterFields: [
          {
            key: 'name',
            searchOperation: 'like',
            value: name,
          },
        ],
      },
      externalUsersCancelToken ? externalUsersCancelToken.token : undefined
    )
      .then((res) => {
        setExternalUsers({ content: res.data.content, totalElements: res.data.totalElements });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsExternalUsersLoading(false));
  }

  function fetchTeams(orderId: string) {
    setIsTeamsLoading(true);
    getOrderTeams(orderId)
      .then((res) => setTeams(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsTeamsLoading(false));
  }

  function fetchAudits(id: string) {
    getAuditsByEntity(id, AuditEntity.INVOICE)
      .then((res) => setAudits(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message ||
          'An error occurred while fetching audits. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchInvoiceDetails(id: string) {
    setIsLoading(true);
    getInvoiceDetails(id)
      .then((res) => {
        setIsLoading(false);
        setInvoiceDetails(res.data);
        formik.resetForm({ values: getInitialValues(res.data) });
        fetchOrders(res.data.orderId);
        fetchExternalUsers(res.data.outSourceClientName);
        fetchAudits(res.data._id);
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleSubmit(data: Partial<IInvoice>) {
    setIsUpdating(true);
    const request = isCreateMode ? createInvoice : updateInvoice;
    const requestBody: Partial<IInvoice> = {
      ...data,
      ...(!isCreateMode && { _id: invoiceDetails?._id, invoiceId: invoiceDetails?.invoiceId }),
    };
    request(requestBody)
      .then((res) => {
        setIsUpdating(false);
        setInvoiceDetails(res.data);
        showSnackbar({ severity: 'success', message: `Invoice ${mode}d successfully` });
        if (isCreateMode) {
          navigate(`/invoices/${window.btoa(res.data._id)}`, { replace: true });
        }
        fetchInvoices();
        fetchAudits(res.data._id);
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        setIsUpdating(false);
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleTabChange(event: React.SyntheticEvent, newValue: string) {
    setActiveTab(newValue);
  }

  function handleSetAttachments(attachments: IAttachment[]) {
    formik.setFieldValue('attachments', attachments);
  }

  function handleBackClick() {
    navigate('/invoices', { replace: true });
  }

  function getActiveTabComponent() {
    switch (activeTab) {
      case 'comments':
        return <Messages id={id} type="comment" />;
      case 'history':
        return <Audits audits={audits} />;
      default:
        return <div>Invalid tab</div>;
    }
  }

  return (
    <Container maxWidth="md">
      <PageTitleWithBack
        title={isCreateMode ? 'Create Invoice' : invoiceDetails?.invoiceId || 'Invoice Details'}
        onBackClick={handleBackClick}
      />
      <SS.BorderedBox>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12}>
                <TextField
                  select
                  name="status"
                  label="Status"
                  size="small"
                  sx={{ minWidth: '200px' }}
                  value={formik.values.status}
                  onChange={formik.handleChange}
                >
                  {getInvoiceStatusOptions().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  size="small"
                  options={orders.content}
                  loading={isOrdersLoading}
                  disabled={!isCreateMode}
                  isOptionEqualToValue={(option, value) => option.orderId === value.orderId}
                  getOptionLabel={(option) => option.orderId}
                  filterOptions={(x) => x}
                  value={
                    orders.content.find((option) => option.orderId === formik.values.orderId) ||
                    null
                  }
                  onChange={(_, newValue) => {
                    formik.setValues({
                      ...formik.values,
                      orderId: newValue ? newValue.orderId : '',
                      teamId: '',
                      teamName: '',
                    });
                    setTeams([]);
                  }}
                  onInputChange={debounce(fetchOrdersBySearch, 500)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Order"
                      placeholder="Type order id to search"
                      error={formik.touched.orderId && Boolean(formik.errors.orderId)}
                      helperText={formik.touched.orderId && formik.errors.orderId}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isOrdersLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  size="small"
                  loading={isTeamsLoading}
                  disabled={!isCreateMode}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  options={teams}
                  value={teams.find((option) => option.id === formik.values.teamId) || null}
                  onChange={(_, newValue) => {
                    formik.setValues({
                      ...formik.values,
                      teamId: newValue ? newValue.id : '',
                      teamName: newValue ? newValue.name : '',
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Team"
                      error={formik.touched.teamId && Boolean(formik.errors.teamId)}
                      helperText={formik.touched.teamId && formik.errors.teamId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  size="small"
                  options={externalUsers.content}
                  loading={isExternalUsersLoading}
                  disabled={!isCreateMode}
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  getOptionLabel={(option) => option.name}
                  filterOptions={(x) => x}
                  value={
                    externalUsers.content.find(
                      (option) => option._id === formik.values.outSourceClientId
                    ) || null
                  }
                  onChange={(_, newValue) => {
                    formik.setValues({
                      ...formik.values,
                      outSourceClientId: newValue ? newValue._id : '',
                      outSourceClientName: newValue ? newValue.name : '',
                    });
                  }}
                  onInputChange={debounce(fetchExternalUsersBySearch, 500)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="External User"
                      placeholder="Type name to search"
                      error={
                        formik.touched.outSourceClientId && Boolean(formik.errors.outSourceClientId)
                      }
                      helperText={
                        formik.touched.outSourceClientId && formik.errors.outSourceClientId
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isOrdersLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="amount"
                  label="Amount"
                  type="number"
                  size="small"
                  fullWidth
                  value={formik.values.amount?.toString() || ''}
                  onChange={formik.handleChange}
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Select
                          name="currency"
                          variant="standard"
                          disableUnderline
                          size="small"
                          value={formik.values.currency}
                          onChange={formik.handleChange}
                          sx={{
                            '.MuiSelect-select': { pt: 0, pb: 0, svg: { verticalAlign: 'middle' } },
                          }}
                        >
                          <MenuItem value={Currency.EUR}>
                            <EuroIcon fontSize="small" /> {Currency.EUR}
                          </MenuItem>
                          <MenuItem value={Currency.GBP}>
                            <CurrencyPoundIcon fontSize="small" /> {Currency.GBP}
                          </MenuItem>
                          <MenuItem value={Currency.INR}>
                            <CurrencyRupeeIcon fontSize="small" /> {Currency.INR}
                          </MenuItem>
                          <MenuItem value={Currency.USD}>
                            <AttachMoneyIcon fontSize="small" /> {Currency.USD}
                          </MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="paymentSource"
                  label="Payment Source"
                  fullWidth
                  size="small"
                  value={formik.values.paymentSource}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="description"
                  label="Description"
                  multiline
                  fullWidth
                  minRows={3}
                  maxRows={3}
                  size="small"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
            {!isCreateMode && invoiceDetails && (
              <Attachments
                attachments={formik.values.attachments || []}
                setAttachments={handleSetAttachments}
                style={{ marginTop: 16 }}
              />
            )}
            <LoadingButton
              color="primary"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              loading={isUpdating}
            >
              {isCreateMode ? 'Create Invoice' : 'Save Changes'}
            </LoadingButton>
          </form>
        )}
      </SS.BorderedBox>
      {!isCreateMode && invoiceDetails && (
        <>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Comments" value="comments" />
            <Tab label="History" value="history" />
          </Tabs>
          <SS.BorderedBox sx={{ mt: 0, p: 0 }}>{getActiveTabComponent()}</SS.BorderedBox>
        </>
      )}
    </Container>
  );
}

export default InvoiceDetails;
