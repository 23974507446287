import axios from '../axios';

import { ISignInRequest, ISignInResponse, IResetPasswordRequest } from '../types/auth';

export function signIn(requestBody: ISignInRequest) {
  return axios.post<ISignInResponse>('/user/signIn', requestBody);
}

export function sendPasswordResetLink(email: string) {
  return axios.get('/user/resetPassword', { params: { email } });
}

export function resetPassword(requestBody: IResetPasswordRequest) {
  return axios.post('/user/updatePassword', requestBody);
}

export function updateAccessToken(refreshToken: string) {
  return axios.get<ISignInResponse>('/user/refreshToken', { params: { token: refreshToken } });
}
