import { styled } from '@mui/material/styles';

const Styles = {
  TitleContainer: styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > li': { width: 'auto' },
  })),
  DateRangePickerContainer: styled('div')(({ theme }) => ({
    display: 'flex',
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
    '.date-range-picker': { minWidth: 256 },
  })),
};

export default Styles;
