import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { Link, Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { GridRowParams, MuiEvent } from '@mui/x-data-grid';

import Filters from './Filters';
import OrdersTable from './OrdersTable';
import OrderForm from './OrderForm';
import OrderDetails from './OrderDetails';

import useAuth from '../../hooks/useAuth';
import useSnackbar from '../../hooks/useSnackbar';
import { UserRole } from '../../types/users';
import { IMinimalTeam } from '../../types/teams';
import { IDateFilter, IFilter, SearchParams } from '../../types/common';
import { IFilterState, ITableState, IOption } from '../../types/orders';
import { getTeamsForDropdown } from '../../requests/teams';
import { deleteOrder, exportOrders, getOrders } from '../../requests/orders';
import { downloadBlob } from '../../utils/utils';

import SS from '../shared/styles';
import ConfirmActionDialog from '../shared/ConfirmActionDialog';

const DATE_FORMAT = 'YYYY-MM-DD';
const dateFilterKeys = ['createdTime', 'dueDate', 'receivedDate'];
const filterKeys = [
  ...dateFilterKeys,
  'orderId',
  'description',
  'client.name',
  'status',
  'orderTeams.name',
  'orderTeams.status',
];
const initialFilters: IFilterState = {
  orderId: '',
  description: '',
  'client.name': '',
  status: [],
  'orderTeams.name': '',
  'orderTeams.status': [],
  date: { key: '', from: '', to: '' },
};
const initialDialogData = {
  id: '',
  name: '',
  open: false,
  isLoading: false,
  callback: () => {},
};

function Orders() {
  const { showSnackbar } = useSnackbar();
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const filtersVisibility = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogData, setDialogData] = useState(initialDialogData);
  const [filters, setFilters] = useState<IFilterState>(getInitialFilters(urlSearchParams));
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 50,
    filterFields: getFilterFields(filters),
  });
  const [orders, setOrders] = useState<ITableState>({ content: [], totalElements: 0 });
  const [teams, setTeams] = useState<IMinimalTeam[]>([]);
  const [isExporting, setIsExporting] = useState(false);
  const [tableScrollPosition, setTableScrollPosition] = useState(0);
  const canCreateOrder = user?.role === UserRole.SALES;
  const orderId = params.id || '';

  useEffect(() => {
    fetchTeams();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  useEffect(() => {
    handleScrollToPosition();
    // eslint-disable-next-line
  }, [orderId]);

  function fetchOrders() {
    setLoading(true);
    getOrders({ page, pageSize, ...(filterFields?.length && { filterFields }) })
      .then((res) => {
        setOrders({ content: res.data.content, totalElements: res.data.totalElements });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setLoading(false));
  }

  function fetchTeams() {
    getTeamsForDropdown()
      .then((res) => setTeams(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred while fetching teams.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }

  function getInitialFilters(urlSearchParams: URLSearchParams) {
    const newFilters: IFilterState = cloneDeep(initialFilters);
    const urlFilters = urlSearchParams.get('filters');
    if (!urlFilters) {
      return initialFilters;
    }

    try {
      const acceptedFilters = pick(JSON.parse(urlFilters), filterKeys);
      Object.entries(acceptedFilters).forEach(([filterKey, filterValue]) => {
        if (dateFilterKeys.includes(filterKey)) {
          const processedDates = getProcessedDates(filterValue);
          if (processedDates) {
            set(newFilters, 'date', {
              key: filterKey,
              from: processedDates.from,
              to: processedDates.to,
            });
          }
        } else {
          (newFilters as any)[filterKey] = filterValue;
        }
      });

      return newFilters;
    } catch (error) {
      console.error(error);
    }

    return initialFilters;
  }

  function getProcessedDates(value: string) {
    try {
      const [from, to] = value.split('~');
      const fromDate = dayjs(from);
      const toDate = dayjs(to);
      if (fromDate.isValid() && toDate.isValid()) {
        if (fromDate.isAfter(toDate)) {
          return { from: toDate.format(DATE_FORMAT), to: fromDate.format(DATE_FORMAT) };
        } else {
          return { from: fromDate.format(DATE_FORMAT), to: toDate.format(DATE_FORMAT) };
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function getFilterFields(filters: IFilterState) {
    const newFilters: IFilter[] = [];
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterKey === 'date') {
        const { key, from, to } = filterValue as IDateFilter;
        const fromDate = from ? `${from}T00:00:00.000Z` : '';
        const toDate = to ? `${to}T23:59:59.999Z` : '';
        if (key && fromDate && toDate) {
          newFilters.push({ key, searchOperation: 'btn', value: fromDate, toValue: toDate });
        }
      } else if (Array.isArray(filterValue) && filterValue.length) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'in',
          value: (filterValue as IOption[]).map((v) => v.value),
        });
      } else if (typeof filterValue === 'string' && filterValue.length) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'like',
          value: filterValue,
        });
      }
    });
    return newFilters;
  }

  function handleApplyFilters() {
    const newFilters: IFilter[] = getFilterFields(filters);
    setOrders({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
    setUrlSearchParams(getUrlSearchParams(filters));
  }

  function getUrlSearchParams(filters: IFilterState) {
    const filterSearchParams = Object.entries(filters).reduce((acc, [filterKey, filterValue]) => {
      if (filterKey === 'date') {
        const { key, from, to } = filterValue as IDateFilter;
        const fromDate = from ? `${from}T00:00:00.000Z` : '';
        const toDate = to ? `${to}T23:59:59.999Z` : '';
        if (key && fromDate && toDate) {
          set(acc, key, `${fromDate}~${toDate}`);
        }
      } else if (
        (typeof filterValue === 'string' || Array.isArray(filterValue)) &&
        filterValue.length
      ) {
        (acc as any)[filterKey] = filterValue;
      }
      return acc;
    }, {});
    return {
      ...urlSearchParams,
      ...(Object.keys(filterSearchParams).length > 0 && {
        filters: JSON.stringify(filterSearchParams),
      }),
    };
  }

  function handleClearFilters() {
    setFilters(initialFilters);
    setOrders({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: [] });
    setUrlSearchParams({});
  }

  function handleExportOrders() {
    setIsExporting(true);
    showSnackbar({
      severity: 'info',
      message: 'Please wait while we prepare the data. Your file will be downloaded any moment',
    });
    exportOrders({ ...(filterFields?.length && { filterFields }) })
      .then((res) => {
        downloadBlob('Orders', res.data);
        showSnackbar({ severity: 'success', message: 'File has been downloaded' });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message ||
          'An error occurred while exporting. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsExporting(false));
  }

  function handleRowClick(
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>
  ) {
    const url = `/orders/${window.btoa(params.row._id)}`;
    if (event.ctrlKey) {
      window.open(url, '_blank');
    } else {
      handleUpdateTableScrollPosition();
      navigate(url);
    }
  }

  function handleUpdateTableScrollPosition() {
    const ele = document.getElementsByClassName('MuiDataGrid-virtualScroller')[0];
    if (ele) {
      setTableScrollPosition(ele.scrollTop);
    }
  }

  function handleScrollToPosition() {
    setTimeout(() => {
      const ele = document.getElementsByClassName('MuiDataGrid-virtualScroller')[0];
      if (tableScrollPosition !== 0 && ele) {
        ele.scrollTop = tableScrollPosition;
      }
    }, 1000);
  }

  function handleDialogOpen(id: string, name: string, callback: () => void) {
    setDialogData({ id, name, callback, open: true, isLoading: false });
  }

  function handleDialogClose() {
    setDialogData(initialDialogData);
  }

  function handleDeleteOrder() {
    const { id, callback } = dialogData;
    if (!id) {
      showSnackbar({ severity: 'error', message: 'Unable to delete order' });
      return;
    }

    setDialogData({ ...dialogData, isLoading: true });
    deleteOrder(id)
      .then(() => {
        showSnackbar({
          severity: 'success',
          message: 'Order deleted successfully',
        });
        callback();
        setDialogData(initialDialogData);
      })
      .catch((error: AxiosError) => {
        setDialogData({ ...dialogData, isLoading: false });
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function getMode() {
    return params.id === 'create' ? 'create' : 'update';
  }

  function getId() {
    if (getMode() === 'create') {
      return '';
    }

    const id = params.id || '';
    try {
      return window.atob(id);
    } catch (error) {
      return id;
    }
  }

  if (getMode() === 'create' && !canCreateOrder) {
    return <Navigate to="/orders" replace />;
  }

  return (
    <>
      {!params.id ? (
        <div>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
          >
            <SS.PageTitle variant="h6">Orders</SS.PageTitle>
            {canCreateOrder && (
              <Button component={Link} to="/orders/create" color="primary" startIcon={<AddIcon />}>
                Create
              </Button>
            )}
          </Box>
          <Filters
            visibility={filtersVisibility}
            teams={teams}
            filters={[filters, setFilters]}
            isExporting={isExporting}
            activeFilterCount={filterFields?.length || 0}
            onApplyFilters={handleApplyFilters}
            onClearFilters={handleClearFilters}
            onExportOrders={handleExportOrders}
          />
          <OrdersTable
            page={page}
            pageSize={pageSize}
            rows={orders.content}
            totalElements={orders.totalElements}
            loading={loading}
            fetchOrders={fetchOrders}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowClick={handleRowClick}
            onDeleteClick={handleDialogOpen}
          />
        </div>
      ) : getMode() === 'create' ? (
        <OrderForm fetchOrders={fetchOrders} />
      ) : (
        <OrderDetails id={getId()} fetchOrders={fetchOrders} />
      )}
      <ConfirmActionDialog
        open={dialogData.open}
        isLoading={dialogData.isLoading}
        title="Delete Order"
        description={`Are you sure you want to delete this order "${dialogData.name}"?`}
        onClose={handleDialogClose}
        onConfirm={handleDeleteOrder}
      />
    </>
  );
}

export default Orders;
