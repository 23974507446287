import { GridRowsProp } from '@mui/x-data-grid';

import { IMinimalCategory } from './categories';
import { IMinimalClient } from './clients';
import { Currency, IAttachment, IDateFilter, PaymentMethod } from './common';

export enum PaymentStatus {
  UN_PAID = 'UN_PAID',
  PAID = 'PAID',
  REFUND = 'REFUND',
  PARTIAL_PAID = 'PARTIAL_PAID',
}

export enum OrderStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CORRECTION = 'CORRECTION',
  CANCELED = 'CANCELED',
}

export interface IOrderList {
  _id: string;
  orderId: string;
  category: IMinimalCategory;
  client: IMinimalClient;
  receivedDate: string;
  dueDate: string;
  status: OrderStatus;
  teamStatus: { [key: string]: string };
  createdBy: string;
  createdTime: string;
  lastModifiedBy: string;
  lastModifiedTime: string;
}

export interface ICreateOrder {
  _id: string;
  referenceId: string;
  category: IMinimalCategory;
  client: IMinimalClient;
  paymentMethod: PaymentMethod;
  paymentDescription: string;
  currency: Currency;
  quantity: number;
  amount: number;
  paymentStatus: PaymentStatus;
  services: string;
  description: string;
  specialRequirement: string;
  receivedDate: string;
  dueDate: string;
  status: OrderStatus;
  attachments: IAttachment[];
}

export interface IOrderTeam {
  order_id: string;
  id: string;
  name: string;
  status: string;
  description: string;
  assignedToId: string;
  assignedToName: string;
  attachments: IAttachment[];
  reports: IAttachment[];
}

export interface IOrder extends ICreateOrder {
  orderId: string;
  orderTeams: IOrderTeam[];
  createdBy: string;
  createdTime: string;
  lastModifiedBy: string;
  lastModifiedTime: string;
}

export interface IFilterState {
  orderId: string;
  description: string;
  'client.name': string;
  status: IOption[];
  'orderTeams.name': string;
  'orderTeams.status': IOption[];
  date: IDateFilter;
}

export interface ITableState {
  content: GridRowsProp;
  totalElements: number;
}

export interface IOption {
  label: string;
  value: string;
}
