import { GridRowsProp } from '@mui/x-data-grid';

import InvoicesTable from './InvoicesTable';

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  handlePageChange(page: number): void;
  handlePageSizeChange(pageSize: number): void;
}

function RelatedInvoices({
  page,
  pageSize,
  rows,
  totalElements,
  loading,
  handlePageChange,
  handlePageSizeChange,
}: IProps) {
  return (
    <InvoicesTable
      type="related-invoices"
      page={page}
      pageSize={pageSize}
      rows={rows}
      totalElements={totalElements}
      loading={loading}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
    />
  );
}

export default RelatedInvoices;
