import { useState } from 'react';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import S from './styles';
import useSnackbar from '../../hooks/useSnackbar';
import { sendPasswordResetLink } from '../../requests/auth';

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
});

function ForgotPassword() {
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: validationSchema,
    onSubmit: async ({ email }) => {
      setIsLoading(true);
      try {
        await sendPasswordResetLink(email);
        setIsLoading(false);
        setIsEmailSent(true);
      } catch (error) {
        const errorMessage =
          ((error as AxiosError)?.response?.data as any)?.message ||
          'An error occurred. Please try again.';
        setIsLoading(false);
        showSnackbar({ severity: 'error', message: errorMessage });
      }
    },
  });

  function handleResendEmail() {
    setIsEmailSent(false);
  }

  return (
    <S.ResetPasswordContainer>
      <Container maxWidth="sm">
        {!isEmailSent ? (
          <>
            <S.Title variant="h5">Forgot Password?</S.Title>
            <p className="description">
              Enter the email address you registered with and we'll send you instructions to reset
              your password.
            </p>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                type="email"
                name="email"
                variant="outlined"
                label="Email"
                sx={{ mt: 1, mb: 1 }}
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginTop="16px"
              >
                <LoadingButton
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  loading={isLoading}
                >
                  Send Email
                </LoadingButton>
                <S.Link to="/signin">Back to Login</S.Link>
              </Stack>
            </form>
          </>
        ) : (
          <>
            <S.Title variant="h5">Check your email</S.Title>
            <p className="description">
              We have sent you the necessary instructions to reset your password.
            </p>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              marginTop="16px"
            >
              <Button color="primary" variant="contained" size="large" onClick={handleResendEmail}>
                Resend Email
              </Button>
              <S.Link to="/signin">Back to Login</S.Link>
            </Stack>
          </>
        )}
      </Container>
    </S.ResetPasswordContainer>
  );
}

export default ForgotPassword;
