import { useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import SvgIcon from '@mui/material/SvgIcon';

import S from './styles';
import { logout } from '../../utils/utils';

import { ReactComponent as BellIconSmall } from '../../assets/img/notification-bell-small.svg';
import Logo from '../../assets/img/logo.svg';

interface IProps {
  onBellIconClick(): void;
  unreadNotificationCount: number;
}

function Header({ onBellIconClick, unreadNotificationCount }: IProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        color: 'inherit',
      }}
    >
      <Toolbar>
        <div>
          <Link to="/">
            <S.Logo src={Logo} alt="StanVentures" />
          </Link>
        </div>
        <Box sx={{ flexGrow: 1 }} />
        <Box display="flex">
          <div>
            <IconButton size="large" onClick={onBellIconClick} color="inherit">
              <Badge
                sx={{ '.MuiBadge-badge': { color: '#ffffff' } }}
                badgeContent={unreadNotificationCount}
                color="primary"
              >
                <SvgIcon component={BellIconSmall} />
              </Badge>
            </IconButton>
          </div>
          <div>
            <IconButton size="large" onClick={handleMenu} color="inherit">
              <AccountCircle />
            </IconButton>
          </div>
        </Box>
      </Toolbar>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={S.Link} to="/profile" onClick={handleClose}>
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout();
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </AppBar>
  );
}

export default Header;
