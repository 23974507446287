import { AxiosError } from 'axios';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';

import useSnackbar from '../../hooks/useSnackbar';
import { importClients } from '../../requests/clients';
import { downloadBlob } from '../../utils/utils';

interface IProps {
  open: boolean;
  handleDialogClose(): void;
  fetchClients(): void;
}

function ImportClients({ open, fetchClients, handleDialogClose }: IProps) {
  const { showSnackbar } = useSnackbar();
  const [isImporting, setIsImporting] = useState(false);

  function handleImportClients(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('file', files[0]);
      setIsImporting(true);
      showSnackbar({ severity: 'info', message: 'Importing. Please wait.' });
      importClients(formData)
        .then((res) => {
          downloadBlob('Imported-Clients', res.data);
          fetchClients();
          showSnackbar({
            severity: 'success',
            message: 'Successfully imported. Please check downloaded file for import status.',
          });
        })
        .catch((error: AxiosError) => {
          const errorMessage =
            (error?.response?.data as any)?.message ||
            'An error occurred while importing clients. Please try again.';
          showSnackbar({ severity: 'error', message: errorMessage });
        })
        .finally(() => setIsImporting(false));
    }
  }

  return (
    <Dialog fullWidth disableEscapeKeyDown maxWidth="sm" open={open} onClose={handleDialogClose}>
      <DialogTitle>
        Import Clients
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          disabled={isImporting}
          sx={{
            position: 'absolute',
            right: 24,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pt: `8px !important` }}>
        <DialogContentText sx={{ mb: 2 }}>
          Instructions: Download the template, fill the details of clients and upload it to import
          clients into the system. After importing, a file will be downloaded which will be
          containing the status of the clients whether it is successfully imported or not.
        </DialogContentText>
        <Box display="flex">
          <Box mr={1}>
            <Button
              component="a"
              variant="contained"
              download
              href="/assets/Import-Clients-Template.xlsx"
            >
              Download Template
            </Button>
          </Box>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={isImporting}
            component="label"
          >
            Import Clients
            <input
              hidden
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              onChange={handleImportClients}
            />
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ImportClients;
