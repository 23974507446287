import * as yup from 'yup';
import { useFormik } from 'formik';
import { AxiosError } from 'axios';
import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import SS from '../shared/styles';
import PageTitleWithBack from '../shared/PageTitleWithBack';

import useAuth from '../../hooks/useAuth';
import useSnackbar from '../../hooks/useSnackbar';
import { IClient } from '../../types/clients';
import { createClient, getClientDetails, updateClient } from '../../requests/clients';
import { IMinimalUserWithRole, UserRole } from '../../types/users';
import { getUsersByRoles } from '../../requests/users';

interface IProps {
  mode: 'create' | 'update';
  id: string;
  fetchClients(): void;
  onDeleteClick(id: string, name: string, callback: () => void): void;
}

function getValidationSchema() {
  return yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Enter a valid email').required('Email is required'),
    salesEmp: yup.object().nullable().required('Sales employee is required'),
    forumUserName: yup.string(),
    companyName: yup.string(),
    skypeId: yup.string(),
    paypalId: yup.string(),
    phoneNo: yup.string(),
    address: yup.object({
      address: yup.string(),
      city: yup.string(),
      country: yup.string(),
      state: yup.string(),
      zipCode: yup.string(),
    }),
  });
}

function ClientDetails({ mode, id, fetchClients, onDeleteClick }: IProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [salesEmployees, setSalesEmployees] = useState<IMinimalUserWithRole[]>([]);
  const [clientDetails, setClientDetails] = useState<IClient | null>(null);
  const isCreateMode = mode === 'create';
  const showSalesEmployee = user?.role !== UserRole.SALES;
  const formik = useFormik<Partial<IClient>>({
    validationSchema: getValidationSchema(),
    initialValues: getInitialValues(null),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (!isCreateMode && id) {
      fetchClientDetails(id);
    }
    fetchSalesEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, id]);

  function getInitialValues(client: IClient | null): Partial<IClient> {
    return {
      firstName: client?.firstName || '',
      lastName: client?.lastName || '',
      email: client?.email || '',
      salesEmp:
        client?.salesEmp ||
        (showSalesEmployee
          ? undefined
          : { id: user._id, name: `${user.firstName} ${user.lastName}` }),
      forumUserName: client?.forumUserName || '',
      companyName: client?.companyName || '',
      skypeId: client?.skypeId || '',
      paypalId: client?.paypalId || '',
      phoneNo: client?.phoneNo || '',
      address: {
        address: client?.address?.address || '',
        city: client?.address?.city || '',
        country: client?.address?.country || '',
        state: client?.address?.state || '',
        zipCode: client?.address?.zipCode || '',
      },
    };
  }

  function fetchClientDetails(id: string) {
    setIsLoading(true);
    getClientDetails(id)
      .then((res) => {
        setIsLoading(false);
        setClientDetails(res.data);
        formik.resetForm({ values: getInitialValues(res.data) });
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchSalesEmployees() {
    getUsersByRoles([UserRole.SALES])
      .then((res) => setSalesEmployees(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message ||
          'An error occurred while fetching sales employees.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleSubmit(data: Partial<IClient>) {
    if (!isCreateMode && !clientDetails?.status) {
      return;
    }

    setIsUpdating(true);
    const request = isCreateMode ? createClient : updateClient;
    const requestBody: Partial<IClient> = {
      ...data,
      ...(!isCreateMode && { _id: clientDetails?._id }),
    };
    request(requestBody)
      .then((res) => {
        setIsUpdating(false);
        setClientDetails(res.data);
        showSnackbar({ severity: 'success', message: `Client ${mode}d successfully` });
        if (isCreateMode) {
          navigate(`/clients/${window.btoa(res.data._id)}`, { replace: true });
        }
        fetchClients();
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        setIsUpdating(false);
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleDelete() {
    if (clientDetails) {
      onDeleteClick(
        clientDetails._id,
        `${clientDetails.firstName} ${clientDetails.lastName}`,
        () => {
          navigate('/clients', { replace: true });
          fetchClients();
        }
      );
    }
  }

  function handleBackClick() {
    navigate('/clients', { replace: true });
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <PageTitleWithBack title={`${capitalize(mode)} Client`} onBackClick={handleBackClick} />
        </div>
        {!isCreateMode && clientDetails?.status && (
          <div>
            <Button
              color="error"
              variant="contained"
              onClick={handleDelete}
              startIcon={<DeleteIcon />}
            >
              Delete Client
            </Button>
          </div>
        )}
      </Box>
      <SS.BorderedBox>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  label="First Name"
                  fullWidth
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Email"
                  fullWidth
                  InputProps={{ readOnly: !isCreateMode }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              {showSalesEmployee && (
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    options={salesEmployees}
                    value={formik.values.salesEmp || null}
                    onChange={(_, newValue) => formik.setFieldValue('salesEmp', newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="salesEmp"
                        label="Sales Employee"
                        error={formik.touched.salesEmp && Boolean(formik.errors.salesEmp)}
                        helperText={formik.touched.salesEmp && formik.errors.salesEmp}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="forumUserName"
                  label="Forum User Name"
                  fullWidth
                  value={formik.values.forumUserName}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="companyName"
                  label="Company Name"
                  fullWidth
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="phoneNo"
                  label="Phone Number"
                  fullWidth
                  value={formik.values.phoneNo}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="paypalId"
                  label="Paypal ID"
                  fullWidth
                  value={formik.values.paypalId}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="skypeId"
                  label="Skype ID"
                  fullWidth
                  value={formik.values.skypeId}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
            <SS.PageTitle variant="body1" mt={3}>
              Address
            </SS.PageTitle>
            <Grid container rowSpacing={2} columnSpacing={1} mt={0.5}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="address.address"
                  label="Address"
                  fullWidth
                  value={formik.values.address?.address}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="address.city"
                  label="City"
                  fullWidth
                  value={formik.values.address?.city}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="address.state"
                  label="State"
                  fullWidth
                  value={formik.values.address?.state}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="address.country"
                  label="Country"
                  fullWidth
                  value={formik.values.address?.country}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="address.zipCode"
                  label="Zip Code"
                  fullWidth
                  value={formik.values.address?.zipCode}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
            {(isCreateMode || clientDetails?.status) && (
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                loading={isUpdating}
              >
                {isCreateMode ? 'Create Client' : 'Save Changes'}
              </LoadingButton>
            )}
          </form>
        )}
      </SS.BorderedBox>
    </Container>
  );
}

export default ClientDetails;
