import axios from '../axios';
import { IAttachments, IMessage, INotification, PageResponse } from '../types/common';

export function uploadFiles(
  requestBody: FormData,
  onUploadProgress?: (progressEvent: any) => void
) {
  return axios.post<IAttachments>('/upload/files', requestBody, {
    headers: { 'content-type': 'multipart/form-data' },
    ...(onUploadProgress && { onUploadProgress }),
  });
}

export function getMessages(id: string) {
  return axios.get<IMessage[]>('/message', { params: { id } });
}

export function sendMessage(requestBody: Partial<IMessage>) {
  return axios.post('/message', requestBody);
}

export function getNotifications(page: number, pageSize: number) {
  return axios.get<PageResponse<INotification>>('/notification', { params: { page, pageSize } });
}

export function updateNotificationAsRead(notificationId: string) {
  return axios.put('/notification', undefined, { params: { id: notificationId } });
}

export function getUnreadNotificationCount() {
  return axios.get<number>('/notification/count');
}
