import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import MuiSnackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import S from './styles';
import useSnackbar from '../../hooks/useSnackbar';

function Snackbar() {
  const {
    snackbar: { open, severity, message, isNotification, onAction },
    hideSnackbar,
  } = useSnackbar();

  function handleHideSnackbar(event: React.SyntheticEvent | Event, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    hideSnackbar();
  }

  if (isNotification) {
    return (
      <S.Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleHideSnackbar}
        message={message}
        action={
          <>
            <Button color="primary" size="small" onClick={onAction}>
              VIEW
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleHideSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    );
  }

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={5000}
      onClose={handleHideSnackbar}
    >
      <Alert elevation={6} variant="filled" severity={severity} onClose={handleHideSnackbar}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
}

export default Snackbar;
