import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

interface ConfirmActionDialogProps {
  open: boolean;
  isLoading: boolean;
  title?: string;
  description?: string;
  onClose(): void;
  onConfirm(): void;
}

function ConfirmActionDialog(props: ConfirmActionDialogProps) {
  const {
    open,
    isLoading,
    title = 'Confirm',
    description = 'Are you sure you want to proceed?',
    onClose,
    onConfirm,
  } = props;

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Close
        </Button>
        <LoadingButton onClick={onConfirm} color="primary" variant="contained" loading={isLoading}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmActionDialog;
