import Box from '@mui/material/Box';

import Chase from './Loaders/Chase/Chase';

import Logo from '../../assets/img/logo.svg';

function StartUpLoader() {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ marginBottom: '24px' }}>
        <img src={Logo} alt="StanVentures" />
      </Box>
      <Chase />
    </Box>
  );
}

export default StartUpLoader;
