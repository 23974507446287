import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import Audits from '../shared/Audits';

import useSnackbar from '../../hooks/useSnackbar';
import { getAuditsByEntity } from '../../requests/audits';
import { AuditEntity, IAudit } from '../../types/audits';
import { IOrder, IOrderTeam } from '../../types/orders';

interface IProps {
  order: IOrder;
  team?: IOrderTeam;
}

function History({ order, team }: IProps) {
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [audits, setAudits] = useState<IAudit[]>([]);

  useEffect(() => {
    fetchAudits(order, team);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, team]);

  function fetchAudits(order: IOrder, team?: IOrderTeam) {
    setIsLoading(true);
    getAuditsByEntity(order._id, team ? AuditEntity.ORDER_TEAM : AuditEntity.ORDER, team?.id)
      .then((res) => setAudits(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred while fetching audits.';
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ m: '-16px -16px 16px -16px', height: '4px' }}>
        {isLoading && <LinearProgress />}
      </Box>
      <Audits audits={audits} />
    </Box>
  );
}

export default History;
