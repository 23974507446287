import axios from '../axios';

import { IDashboardData, IEmployeeDashboardData, ITeamLeadDashboardData } from '../types/dashboard';

export function getDashboardData(role: 'admin' | 'sales', start: string, end: string) {
  return axios.get<IDashboardData>(`/dashboard/${role}`, {
    params: { fromDate: start, toDate: end },
  });
}

export function getDashboardDataForTeamLead(start: string, end: string) {
  return axios.get<ITeamLeadDashboardData>('/dashboard/lead', {
    params: { fromDate: start, toDate: end },
  });
}

export function getDashboardDataForEmployee(start: string, end: string) {
  return axios.get<IEmployeeDashboardData>('/dashboard/employee', {
    params: { fromDate: start, toDate: end },
  });
}
