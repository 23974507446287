import { CancelToken } from 'axios';

import axios from '../axios';
import { IDropdown, PageResponse, SearchParams } from '../types/common';
import { IExternalUser, IMinimalUserWithRole, IUser, UserRole } from '../types/users';

export function getUserDetails({ id, email }: { id?: string; email?: string } = {}) {
  return axios.get<IUser>('/user/detail', { params: { id, email } });
}

export function updateUserDetails(requestBody: Partial<IUser>) {
  return axios.post('/user/profile/update', requestBody);
}

export function getUsers(requestBody: SearchParams) {
  return axios.post<PageResponse<IUser>>('/user/find', requestBody);
}

export function createUser(requestBody: Partial<IUser>) {
  return axios.post<IUser>('/user/create', requestBody);
}

export function updateUser(requestBody: Partial<IUser>) {
  return axios.post<IUser>('/user/update', requestBody);
}

export function deleteUser(requestBody: { email: string }) {
  return axios.post<IUser>('/user/delete', requestBody);
}

export function getUsersByRoles(roles: UserRole[]) {
  return axios.get<IMinimalUserWithRole[]>('/user/dropDown', {
    params: { userRole: roles.join() },
  });
}

export function getExternalUserDetails(id: string) {
  return axios.get<IExternalUser>('/outSourceClient/detail', { params: { id } });
}

export function createExternalUser(requestBody: Partial<IExternalUser>) {
  return axios.post<IExternalUser>('/outSourceClient/create', requestBody);
}

export function updateExternalUser(requestBody: Partial<IExternalUser>) {
  return axios.post<IExternalUser>('/outSourceClient/update', requestBody);
}

export function getExternalUsers(requestBody: SearchParams, cancelToken?: CancelToken) {
  return axios.post<PageResponse<IExternalUser>>('/outSourceClient/find', requestBody, {
    cancelToken,
  });
}

export function deleteExternalUser(requestBody: { _id: string }) {
  return axios.post<IExternalUser>('/outSourceClient/delete', requestBody);
}

export function getExternalUsersForDropdown(requestBody: SearchParams) {
  return axios.post<PageResponse<IDropdown>>('/outSourceClient/find/dropdown', requestBody);
}
