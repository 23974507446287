import axios from '../axios';
import { IInvoice } from '../types/invoices';
import { PageResponse, SearchParams } from '../types/common';

export function getInvoices(requestBody: SearchParams) {
  return axios.post<PageResponse<IInvoice>>('/invoice/find', requestBody);
}

export function getInvoiceDetails(id: string) {
  return axios.get<IInvoice>('/invoice/detail', { params: { id } });
}

export function createInvoice(requestBody: Partial<IInvoice>) {
  return axios.post<IInvoice>('/invoice/create', requestBody);
}

export function updateInvoice(requestBody: Partial<IInvoice>) {
  return axios.post<IInvoice>('/invoice/update', requestBody);
}
