import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import { GridColDef, GridRowParams, GridRowsProp } from '@mui/x-data-grid';

import SS from '../shared/styles';

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  fetchClients(): void;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  onRowClick(params: GridRowParams): void;
  onDeleteClick(id: string, name: string, callback: () => void): void;
}

const initialDialogData = {
  id: '',
  name: '',
};

function ClientsTable(props: IProps) {
  const {
    page,
    pageSize,
    rows,
    totalElements,
    loading,
    fetchClients,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onDeleteClick,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogData, setDialogData] = useState(initialDialogData);

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First Name', flex: 1, sortable: false },
    { field: 'lastName', headerName: 'Last Name', flex: 1, sortable: false },
    { field: 'email', headerName: 'Email', flex: 1, sortable: false },
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 1,
      sortable: false,
      renderCell: (params) => params.row?.companyName || '',
    },
    {
      field: 'salesEmp',
      headerName: 'Sales Employee',
      flex: 1,
      sortable: false,
      renderCell: (params) => params.row?.salesEmp?.name || '',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={(event) =>
            handleOptionsClick(
              event,
              params.row._id as string,
              `${params.row.firstName} ${params.row.lastName}`
            )
          }
        >
          <MoreIcon fontSize="small" />
        </IconButton>
      ),
    },
  ];

  function handleMenuClose() {
    setAnchorEl(null);
    setDialogData(initialDialogData);
  }

  function handleOptionsClick(event: React.MouseEvent<HTMLElement>, id: string, name: string) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setDialogData({ id, name });
  }

  function handleDeleteClient() {
    if (dialogData.id) {
      onDeleteClick(dialogData.id, dialogData.name, () => {
        handleMenuClose();
        fetchClients();
      });
    }
    handleMenuClose();
  }

  return (
    <>
      <SS.DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        rowCount={totalElements}
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        loading={loading}
        pagination
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onRowClick={onRowClick}
      />
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleDeleteClient()}>Delete</MenuItem>
      </Menu>
    </>
  );
}

export default ClientsTable;
