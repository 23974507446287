import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

import OrderForm from './OrderForm';
import OrderTeam from './OrderTeam';
import History from './History';
import Messages from './Messages';
import RelatedOrders from './RelatedOrders';
import RelatedInvoices from '../Invoices/RelatedInvoices';

import PageTitleWithBack from '../shared/PageTitleWithBack';
import SS from '../shared/styles';

import useAuth from '../../hooks/useAuth';
import useSnackbar from '../../hooks/useSnackbar';
import { getOrder, getOrderTeams, getRelatedOrders } from '../../requests/orders';
import { IOrder, ITableState } from '../../types/orders';
import { ITeam } from '../../types/teams';
import { MessageType, SearchParams } from '../../types/common';
import { UserRole } from '../../types/users';
import { getInvoices } from '../../requests/invoices';

interface IProps {
  id: string;
  fetchOrders?: () => void;
}

function OrderDetails({ id, fetchOrders }: IProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState('order-details');
  const [secondaryActiveTab, setSecondaryActiveTab] = useState('messages');
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [team, setTeam] = useState<ITeam>({} as ITeam);
  const [isRelatedOrdersLoading, setIsRelatedOrdersLoading] = useState(false);
  const [relatedOrdersSearchParams, setRelatedOrdersSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
  });
  const [relatedOrders, setRelatedOrders] = useState<ITableState>({
    content: [],
    totalElements: 0,
  });
  const [isRelatedInvoicesLoading, setIsRelatedInvoicesLoading] = useState(false);
  const [relatedInvoicesSearchParams, setRelatedInvoicesSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
  });
  const [relatedInvoices, setRelatedInvoices] = useState<ITableState>({
    content: [],
    totalElements: 0,
  });

  const showRelatedOrders = ![UserRole.SALES].includes(user?.role as UserRole);
  const showRelatedInvoices = ![UserRole.EMPLOYEE, UserRole.SALES].includes(user?.role as UserRole);

  useEffect(() => {
    fetchOrder();
    fetchOrderTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const relatedOrdersPage = relatedOrdersSearchParams.page;
  const relatedOrdersPageSize = relatedOrdersSearchParams.pageSize;
  useEffect(() => {
    if (showRelatedOrders) {
      fetchRelatedOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRelatedOrders, relatedOrdersPage, relatedOrdersPageSize]);

  const relatedInvoicesPage = relatedInvoicesSearchParams.page;
  const relatedInvoicesPageSize = relatedInvoicesSearchParams.pageSize;
  useEffect(() => {
    if (order.orderId && showRelatedInvoices) {
      fetchRelatedInvoices(order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, showRelatedInvoices, relatedInvoicesPage, relatedInvoicesPageSize]);

  function fetchOrder(loading = true) {
    setIsLoading(loading);
    getOrder(id)
      .then((res) => setOrder(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred while fetching order.';
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsLoading(false));
  }

  function fetchRelatedOrders() {
    const { page, pageSize } = relatedOrdersSearchParams;
    setIsRelatedOrdersLoading(true);
    getRelatedOrders({ page, pageSize }, id)
      .then((res) =>
        setRelatedOrders({ content: res.data.content, totalElements: res.data.totalElements })
      )
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message ||
          'An error occurred while fetching related orders.';
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsRelatedOrdersLoading(false));
  }

  function handleRelatedOrdersPageChange(page: number) {
    const { pageSize } = relatedOrdersSearchParams;
    setRelatedOrdersSearchParams({ page, pageSize });
  }

  function handleRelatedOrdersPageSizeChange(pageSize: number) {
    setRelatedOrdersSearchParams({ page: 0, pageSize });
  }

  function fetchRelatedInvoices(order: IOrder) {
    const { page, pageSize } = relatedInvoicesSearchParams;
    setIsRelatedInvoicesLoading(true);
    getInvoices({
      page,
      pageSize,
      filterFields: [
        {
          key: 'orderId',
          searchOperation: 'like',
          value: order.orderId,
        },
      ],
    })
      .then((res) =>
        setRelatedInvoices({ content: res.data.content, totalElements: res.data.totalElements })
      )
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message ||
          'An error occurred while fetching related invoices.';
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsRelatedInvoicesLoading(false));
  }

  function handleRelatedInvoicesPageChange(page: number) {
    const { pageSize } = relatedInvoicesSearchParams;
    setRelatedInvoicesSearchParams({ page, pageSize });
  }

  function handleRelatedInvoicesPageSizeChange(pageSize: number) {
    setRelatedInvoicesSearchParams({ page: 0, pageSize });
  }

  function fetchOrderTeams() {
    getOrderTeams(id)
      .then((res) => setTeams(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred while fetching teams.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleTabChange(event: React.SyntheticEvent, newValue: string) {
    if (newValue !== 'order-details') {
      const team = teams.find((t) => t._id === newValue);
      if (!team) {
        showSnackbar({ severity: 'error', message: 'Team not found.' });
        setActiveTab('order-details');
        return;
      }
      fetchOrder(false);
      setTeam(team);
    } else {
      setTeam({} as ITeam);
    }
    setActiveTab(newValue);
  }

  function handleSecondaryTabChange(event: React.SyntheticEvent, newValue: string) {
    setSecondaryActiveTab(newValue);
  }

  function getActiveTabComponent() {
    switch (activeTab) {
      case 'order-details':
        return (
          <OrderForm
            order={order}
            mode="update"
            fetchOrder={fetchOrder}
            fetchOrders={fetchOrders}
          />
        );
      default:
        return (
          <OrderTeam
            orderId={id}
            team={team}
            orderTeam={getOrderTeam()}
            fetchOrder={fetchOrder}
            fetchOrders={fetchOrders}
          />
        );
    }
  }

  function getSecondaryActiveTabComponent() {
    const { page: relatedOrdersPage, pageSize: relatedOrdersPageSize } = relatedOrdersSearchParams;
    const { page: relatedInvoicesPage, pageSize: relatedInvoicesPageSize } =
      relatedOrdersSearchParams;
    switch (secondaryActiveTab) {
      case 'messages':
        return (
          <Messages
            id={id}
            messageType={activeTab === 'order-details' ? MessageType.ORDER : MessageType.TEAM}
            callback={() => {
              fetchOrder();
              fetchOrders?.();
            }}
          />
        );
      case 'related-orders':
        return (
          <RelatedOrders
            page={relatedOrdersPage}
            pageSize={relatedOrdersPageSize}
            rows={relatedOrders.content}
            totalElements={relatedOrders.totalElements}
            loading={isRelatedOrdersLoading}
            handlePageChange={handleRelatedOrdersPageChange}
            handlePageSizeChange={handleRelatedOrdersPageSizeChange}
          />
        );
      case 'related-invoices':
        return (
          <RelatedInvoices
            page={relatedInvoicesPage}
            pageSize={relatedInvoicesPageSize}
            rows={relatedInvoices.content}
            totalElements={relatedInvoices.totalElements}
            loading={isRelatedInvoicesLoading}
            handlePageChange={handleRelatedInvoicesPageChange}
            handlePageSizeChange={handleRelatedInvoicesPageSizeChange}
          />
        );
      case 'history':
        return <History order={order} team={getOrderTeam()} />;
      default:
        return <div>Invalid tab</div>;
    }
  }

  function handleBackClick() {
    navigate('/orders', { replace: true });
  }

  function getOrderTeam() {
    return order?.orderTeams?.find((t) => t.id === team?._id);
  }

  return (
    <Container maxWidth="md">
      <PageTitleWithBack title={order?.orderId || 'Order Details'} onBackClick={handleBackClick} />
      <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
        <Tab label="Order Details" value="order-details" />
        {teams?.map((team) => (
          <Tab key={team._id} label={team.name} value={team._id} />
        ))}
      </Tabs>
      <SS.BorderedBox sx={{ mt: 0 }}>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          getActiveTabComponent()
        )}
      </SS.BorderedBox>
      <Tabs
        value={secondaryActiveTab}
        onChange={handleSecondaryTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Messages" value="messages" />
        {showRelatedOrders && <Tab label="Related Orders" value="related-orders" />}
        {showRelatedInvoices && <Tab label="Related Invoices" value="related-invoices" />}
        <Tab
          label={`${activeTab === 'order-details' ? 'Order' : 'Team'} History`}
          value="history"
        />
      </Tabs>
      <SS.BorderedBox sx={{ mt: 0, p: 0 }}>{getSecondaryActiveTabComponent()}</SS.BorderedBox>
    </Container>
  );
}

export default OrderDetails;
