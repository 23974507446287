import { IAddress, PaymentMethod } from './common';

export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  SALES = 'SALES',
  MANAGER = 'MANAGER',
  TEAM_LEAD = 'TEAM_LEAD',
  EMPLOYEE = 'EMPLOYEE',
}

export enum UserZone {
  NAMAKKAL = 'NAMAKKAL',
  ERODE = 'ERODE',
}

export interface IMinimalUser {
  id: string;
  name: string;
}

export interface IUser {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  empId: string;
  zone: UserZone;
  phoneNo: string;
  role: UserRole;
  lastLogin: string;
  createdTime: string;
  status: boolean;
}

export interface IExternalUser {
  _id: string;
  name: string;
  email: string;
  paymentMethod: PaymentMethod;
  panNo: string;
  numberOfWordsCapable: number | string;
  skypeId: string;
  phoneNo: string;
  address: IAddress;
  status: boolean;
  createdBy: string;
  lastModifiedBy: string;
  createdTime: string;
  lastModifiedTime: string;
}

export interface IMinimalUserWithRole extends IMinimalUser {
  role: UserRole;
}

export interface IUsersFilterState {
  firstName: string;
  lastName: string;
  email: string;
  empId: string;
}

export interface IExternalUsersFilterState {
  name: string;
  email: string;
}
