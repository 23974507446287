import * as yup from 'yup';
import { useFormik } from 'formik';
import { AxiosError } from 'axios';
import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SS from '../shared/styles';
import Audits from '../shared/Audits';
import PageTitleWithBack from '../shared/PageTitleWithBack';

import { IMinimalTeam } from '../../types/teams';
import useSnackbar from '../../hooks/useSnackbar';
import { getTeamsForDropdown } from '../../requests/teams';
import { createCategory, getCategoryDetails, updateCategory } from '../../requests/categories';
import { AuditEntity, IAudit } from '../../types/audits';
import { getAuditsByEntity } from '../../requests/audits';
import { ICategory } from '../../types/categories';

interface IProps {
  mode: 'create' | 'update';
  id: string;
  fetchCategories(): void;
  onDeleteClick(id: string, name: string, callback: () => void): void;
}

const validationSchema = yup.object({
  name: yup.string().required('Category name is required'),
  teams: yup.array().min(1, 'At least one team is required').required(),
});

function CategoryDetails({ mode, id, fetchCategories, onDeleteClick }: IProps) {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState<ICategory | null>(null);
  const [teams, setTeams] = useState<IMinimalTeam[]>([]);
  const [audits, setAudits] = useState<IAudit[]>([]);
  const [showAudits, setShowAudits] = useState(false);
  const formik = useFormik<Partial<ICategory>>({
    validationSchema: validationSchema,
    initialValues: getInitialValues(null),
    onSubmit: handleSubmit,
  });
  const isCreateMode = mode === 'create';

  useEffect(() => {
    if (!isCreateMode && id) {
      fetchCategoryDetails(id);
    }
    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, id]);

  function getInitialValues(category: ICategory | null): Partial<ICategory> {
    return {
      name: category?.name || '',
      teams: category?.teams || [],
    };
  }

  function fetchCategoryDetails(id: string) {
    setIsLoading(true);
    getCategoryDetails(id)
      .then((res) => {
        setIsLoading(false);
        setCategoryDetails(res.data);
        fetchAudits(res.data._id);
        formik.resetForm({ values: getInitialValues(res.data) });
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        const errorMessage =
          (error?.response?.data as any)?.message ||
          'An error occurred while fetching category details. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchAudits(id: string) {
    getAuditsByEntity(id, AuditEntity.CATEGORY)
      .then((res) => setAudits(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message ||
          'An error occurred while fetching audits. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchTeams() {
    getTeamsForDropdown()
      .then((res) => setTeams(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred while fetching teams.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleSubmit(data: Partial<ICategory>) {
    if (!isCreateMode && !categoryDetails?.status) {
      return;
    }

    setIsUpdating(true);
    const request = isCreateMode ? createCategory : updateCategory;
    const requestBody: Partial<ICategory> = {
      ...data,
      ...(!isCreateMode && { _id: categoryDetails?._id }),
    };
    request(requestBody)
      .then((res) => {
        setIsUpdating(false);
        setCategoryDetails(res.data);
        showSnackbar({ severity: 'success', message: `Category ${mode}d successfully` });
        if (isCreateMode) {
          navigate(`/categories/${window.btoa(res.data._id)}`, { replace: true });
        } else {
          fetchAudits(res.data._id);
        }
        fetchCategories();
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        setIsUpdating(false);
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleDelete() {
    if (categoryDetails) {
      onDeleteClick(categoryDetails._id, categoryDetails.name, () => {
        navigate('/categories', { replace: true });
        fetchCategories();
      });
    }
  }

  function handleBackClick() {
    navigate('/categories', { replace: true });
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <PageTitleWithBack title={`${capitalize(mode)} Category`} onBackClick={handleBackClick} />
        </div>
        {!isCreateMode && categoryDetails?.status && (
          <div>
            <Button
              color="error"
              variant="contained"
              onClick={handleDelete}
              startIcon={<DeleteIcon />}
            >
              Delete Category
            </Button>
          </div>
        )}
      </Box>
      <SS.BorderedBox>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  label="Category Name"
                  fullWidth
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  options={teams}
                  value={formik.values.teams}
                  onChange={(_, newValue) => formik.setFieldValue('teams', newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="teams"
                      label="Teams"
                      error={formik.touched.teams && Boolean(formik.errors.teams)}
                      helperText={formik.touched.teams && formik.errors.teams}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {(isCreateMode || categoryDetails?.status) && (
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                loading={isUpdating}
              >
                {isCreateMode ? 'Create Category' : 'Save Changes'}
              </LoadingButton>
            )}
            {!isCreateMode && categoryDetails && (
              <>
                <Box sx={{ mt: 2, mb: 2, pt: 2, borderTop: '1px solid #d8dee4' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={showAudits ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    onClick={() => setShowAudits(!showAudits)}
                  >
                    {`${showAudits ? 'Hide' : 'Show'} History`}
                  </Button>
                </Box>
                <Collapse in={showAudits}>
                  <Audits audits={audits} />
                </Collapse>
              </>
            )}
          </form>
        )}
      </SS.BorderedBox>
    </Container>
  );
}

export default CategoryDetails;
