export enum PaymentMethod {
  TWO_CHECKOUT = 'TWO_CHECKOUT',
  BANK = 'BANK',
  CRYPTO = 'CRYPTO',
  PAYPAL = 'PAYPAL',
  STRIPE = 'STRIPE',
  SKRILL = 'SKRILL',
  WEB_MONEY = 'WEB_MONEY',
}

export enum Currency {
  INR = 'INR',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
}

export enum MessageType {
  ORDER = 'ORDER',
  TASK = 'TASK',
  TEAM = 'TEAM',
}

export interface IAddress {
  address: string;
  city: string;
  country: string;
  state: string;
  zipCode: string;
}

export interface PageResponse<T> {
  content: T[];
  totalElements: number;
  totalPages: number;
  size: number;
}

export interface IFilter {
  key: string;
  searchOperation: string;
  value?: string | number | string[] | number[] | boolean;
  toValue?: string | number;
}

export interface SearchParams {
  filterFields?: IFilter[];
  page: number;
  pageSize: number;
  sortBy?: string;
  sortDirection?: string;
}

export interface IAttachments {
  attachments: IAttachment[];
}

export interface IAttachment {
  id: string;
  name: string;
  url: string;
}

export interface IMessage {
  id: string;
  entityId: string;
  userId: string;
  type?: MessageType;
  userName: string;
  dateTime: string;
  body: string;
}

export interface IDateFilter {
  key: string;
  from: string;
  to: string;
}

export type NotificationType = 'ORDER';

export interface INotification {
  _id: string;
  description: string;
  type: NotificationType;
  typeSystemId: string;
  typeId: string;
  read: boolean;
  userEmail: string;
  createdTime: string;
}

export interface IDropdown {
  id: string;
  name: string;
}
