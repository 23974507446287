import dayjs from 'dayjs';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import EuroIcon from '@mui/icons-material/Euro';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import S from './styles';
import SS from '../shared/styles';
import PageTitleWithBack from '../shared/PageTitleWithBack';
import Attachments from '../shared/Attachments';

import useAuth from '../../hooks/useAuth';
import useSnackbar from '../../hooks/useSnackbar';
import { ICreateOrder, IOrder, OrderStatus, PaymentStatus } from '../../types/orders';
import { getClientsForDropdown } from '../../requests/clients';
import { IMinimalClient } from '../../types/clients';
import { getCategoriesForDropdown } from '../../requests/categories';
import { IMinimalCategory } from '../../types/categories';
import {
  getOrderPaymentMethodOptions,
  getOrderStatusOptions,
  getPaymentStatusOptions,
} from '../../utils/utils';
import { createOrder, markOrderAsComplete, updateOrder } from '../../requests/orders';
import { Currency, IAttachment } from '../../types/common';
import { UserRole } from '../../types/users';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function getFormattedDate(date?: string) {
  return date ? dayjs(date).format('YYYY-MM-DD') : '';
}

const validationSchema = yup.object({
  client: yup.object().nullable().required('Client is required'),
  category: yup.object().nullable().required('Category is required'),
  quantity: yup.number(),
  currency: yup.string(),
  amount: yup.number(),
  status: yup.string(),
  paymentStatus: yup.string().nullable(),
  paymentMethod: yup
    .string()
    .nullable()
    .when('paymentStatus', {
      is: (paymentStatus: PaymentStatus) =>
        [PaymentStatus.PAID, PaymentStatus.PARTIAL_PAID].includes(paymentStatus),
      then: (schema) => schema.required('Payment method is required'),
    }),
  paymentDescription: yup.string().when('paymentMethod', {
    is: (paymentMethod: string) => !!paymentMethod,
    then: (schema) => schema.required('Payment description is required'),
  }),
  services: yup.string(),
  description: yup.string(),
  specialRequirement: yup.string(),
  receivedDate: yup.string().required('Received date is required'),
  dueDate: yup.string().required('Due date is required'),
  attachments: yup.array().of(yup.object()),
});

function OrderForm({
  order,
  mode = 'create',
  fetchOrder,
  fetchOrders,
}: {
  order?: IOrder;
  mode?: 'create' | 'update';
  fetchOrder?: () => void;
  fetchOrders?: () => void;
}) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const isUpdateMode = mode === 'update';
  const [isLoading, setIsLoading] = useState(false);
  const [isMarkingComplete, setIsMarkingComplete] = useState(false);
  const [clients, setClients] = useState<IMinimalClient[]>([]);
  const [categories, setCategories] = useState<IMinimalCategory[]>([]);
  const formik = useFormik<Partial<ICreateOrder>>({
    validationSchema,
    initialValues: getInitialValues(),
    onSubmit: handleSubmit,
  });
  const isEmployee = user?.role === UserRole.EMPLOYEE;
  const showFields = {
    client: !isEmployee,
    amount: !isEmployee,
    paymentStatus: !isEmployee,
    paymentMethod: !isEmployee,
    paymentDescription: !isEmployee,
  };
  const readOnly =
    isUpdateMode &&
    [UserRole.MANAGER, UserRole.TEAM_LEAD, UserRole.EMPLOYEE].includes(user?.role as UserRole);
  const showMarkAsComplete = [UserRole.MANAGER, UserRole.TEAM_LEAD].includes(
    user?.role as UserRole
  );

  useEffect(() => {
    fetchClients();
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formik.resetForm({ values: getInitialValues(order) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  function getInitialValues(value?: IOrder): Partial<ICreateOrder> {
    return {
      _id: value?._id,
      referenceId: value?.referenceId || '',
      client: value?.client,
      category: value?.category,
      paymentMethod: value?.paymentMethod,
      paymentDescription: value?.paymentDescription || '',
      currency: value?.currency || Currency.INR,
      quantity: value?.quantity || ('' as any),
      amount: value?.amount || ('' as any),
      paymentStatus: value?.paymentStatus,
      services: value?.services || '',
      description: value?.description || '',
      specialRequirement: value?.specialRequirement || '',
      receivedDate: getFormattedDate(value?.receivedDate),
      dueDate: getFormattedDate(value?.dueDate),
      status: value?.status || OrderStatus.NEW,
      attachments: value?.attachments || [],
    };
  }

  function fetchClients() {
    getClientsForDropdown()
      .then((res) => setClients(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred while fetching clients.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchCategories() {
    getCategoriesForDropdown()
      .then((res) => setCategories(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred while fetching categories.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleSubmit(data: Partial<ICreateOrder>) {
    const { client, category, ...order } = data;
    const request = isUpdateMode ? updateOrder : createOrder;

    setIsLoading(true);
    request({
      ...order,
      ...(!isUpdateMode && { client, category }),
      receivedDate: dayjs(order.receivedDate).toISOString(),
      dueDate: dayjs(order.dueDate).endOf('day').toISOString(),
    })
      .then((res) => {
        showSnackbar({ severity: 'success', message: `Order ${mode}d successfully` });
        if (!isUpdateMode) {
          navigate(`/orders/${window.btoa(res.data._id)}`);
          fetchOrders?.();
        } else {
          fetchOrder?.();
          fetchOrders?.();
        }
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message ||
          `An error occurred while ${isUpdateMode ? 'updating' : 'creating'} order.`;
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsLoading(false));
  }

  function handleMarkAsComplete() {
    if (order) {
      setIsMarkingComplete(true);
      markOrderAsComplete(order._id)
        .then((res) => {
          showSnackbar({ severity: 'success', message: 'Order marked as completed successfully.' });
          fetchOrder?.();
          fetchOrders?.();
        })
        .catch((error: AxiosError) => {
          const errorMessage =
            (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
          showSnackbar({ severity: 'error', message: errorMessage });
        })
        .finally(() => setIsMarkingComplete(false));
    }
  }

  function handleSetAttachments(attachments: IAttachment[]) {
    formik.setFieldValue('attachments', attachments);
  }

  function handleBackClick() {
    navigate('/orders');
  }

  function renderForm() {
    return (
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container rowSpacing={2} columnSpacing={1}>
          {isUpdateMode && (
            <>
              <Grid item xs={6}>
                <TextField
                  select
                  name="status"
                  label="Status"
                  size="small"
                  fullWidth
                  value={formik.values.status || ''}
                  onChange={formik.handleChange}
                  InputProps={{ readOnly }}
                >
                  {getOrderStatusOptions().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                {showMarkAsComplete && (
                  <LoadingButton
                    variant="contained"
                    disabled={!order}
                    loading={isMarkingComplete}
                    onClick={handleMarkAsComplete}
                    startIcon={<CheckCircleOutlineIcon />}
                  >
                    Mark as Complete
                  </LoadingButton>
                )}
              </Grid>
            </>
          )}
          {showFields.client && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => `${option.name} (${option.createdByName})`}
                options={clients}
                value={formik.values.client || null}
                disabled={isUpdateMode}
                onChange={(_, newValue) => formik.setFieldValue('client', newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="client"
                    label="Client"
                    size="small"
                    error={formik.touched.client && Boolean(formik.errors.client)}
                    helperText={formik.touched.client && formik.errors.client}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              options={categories}
              value={formik.values.category || null}
              disabled={isUpdateMode}
              onChange={(_, newValue) => formik.setFieldValue('category', newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="category"
                  label="Category"
                  size="small"
                  error={formik.touched.category && Boolean(formik.errors.category)}
                  helperText={formik.touched.category && formik.errors.category}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="referenceId"
              label="Reference Id"
              fullWidth
              size="small"
              value={formik.values.referenceId}
              onChange={formik.handleChange}
              InputProps={{ readOnly }}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12}>
            <S.MessageInputContainer sx={{ padding: 0, border: 0 }}>
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    [{ size: ['small', false, 'large', 'huge'] }],
                    ['bold', 'italic'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['link', 'clean', 'unlink'],
                  ],
                }}
                readOnly={readOnly}
                placeholder="Description"
                value={formik.values.description}
                onChange={(html) => formik.setFieldValue('description', html)}
              />
            </S.MessageInputContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="services"
              label="Services"
              multiline
              fullWidth
              minRows={3}
              maxRows={3}
              size="small"
              value={formik.values.services}
              onChange={formik.handleChange}
              InputProps={{ readOnly }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="specialRequirement"
              label="Special Requirements"
              multiline
              fullWidth
              minRows={3}
              maxRows={3}
              size="small"
              value={formik.values.specialRequirement}
              onChange={formik.handleChange}
              InputProps={{ readOnly }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="quantity"
              label="Quantity"
              type="number"
              fullWidth
              size="small"
              value={formik.values.quantity?.toString() || ''}
              onChange={formik.handleChange}
              InputProps={{ readOnly }}
            />
          </Grid>
          {showFields.amount && (
            <Grid item xs={12} sm={6}>
              <TextField
                name="amount"
                label="Amount"
                type="number"
                size="small"
                fullWidth
                value={formik.values.amount?.toString() || ''}
                onChange={formik.handleChange}
                InputProps={{
                  readOnly,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        name="currency"
                        variant="standard"
                        disableUnderline
                        size="small"
                        value={formik.values.currency}
                        onChange={formik.handleChange}
                        sx={{
                          '.MuiSelect-select': { pt: 0, pb: 0, svg: { verticalAlign: 'middle' } },
                        }}
                        readOnly={readOnly}
                      >
                        <MenuItem value={Currency.EUR}>
                          <EuroIcon fontSize="small" /> {Currency.EUR}
                        </MenuItem>
                        <MenuItem value={Currency.GBP}>
                          <CurrencyPoundIcon fontSize="small" /> {Currency.GBP}
                        </MenuItem>
                        <MenuItem value={Currency.INR}>
                          <CurrencyRupeeIcon fontSize="small" /> {Currency.INR}
                        </MenuItem>
                        <MenuItem value={Currency.USD}>
                          <AttachMoneyIcon fontSize="small" /> {Currency.USD}
                        </MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {showFields.paymentStatus && (
            <Grid item xs={12} sm={6}>
              <TextField
                select
                name="paymentStatus"
                label="Payment Status"
                fullWidth
                size="small"
                value={formik.values.paymentStatus || ''}
                onChange={formik.handleChange}
                InputProps={{ readOnly }}
              >
                {getPaymentStatusOptions().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {showFields.paymentMethod && (
            <Grid item xs={12} sm={6}>
              <TextField
                select
                name="paymentMethod"
                label="Payment Method"
                fullWidth
                size="small"
                value={formik.values.paymentMethod || ''}
                onChange={formik.handleChange}
                InputProps={{ readOnly }}
                error={formik.touched.paymentMethod && Boolean(formik.errors.paymentMethod)}
                helperText={formik.touched.paymentMethod && formik.errors.paymentMethod}
              >
                {getOrderPaymentMethodOptions().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {showFields.paymentDescription && (
            <Grid item xs={12}>
              <TextField
                name="paymentDescription"
                label="Payment Description"
                multiline
                fullWidth
                minRows={3}
                maxRows={3}
                size="small"
                value={formik.values.paymentDescription}
                onChange={formik.handleChange}
                InputProps={{ readOnly }}
                error={
                  formik.touched.paymentDescription && Boolean(formik.errors.paymentDescription)
                }
                helperText={formik.touched.paymentDescription && formik.errors.paymentDescription}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              name="receivedDate"
              label="Received Date"
              type="date"
              fullWidth
              size="small"
              value={formik.values.receivedDate}
              onChange={formik.handleChange}
              InputProps={{ readOnly }}
              InputLabelProps={{ shrink: true }}
              error={formik.touched.receivedDate && Boolean(formik.errors.receivedDate)}
              helperText={formik.touched.receivedDate && formik.errors.receivedDate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="dueDate"
              label="Due Date"
              type="date"
              fullWidth
              size="small"
              value={formik.values.dueDate}
              onChange={formik.handleChange}
              InputProps={{ readOnly }}
              InputLabelProps={{ shrink: true }}
              error={formik.touched.dueDate && Boolean(formik.errors.dueDate)}
              helperText={formik.touched.dueDate && formik.errors.dueDate}
            />
          </Grid>
        </Grid>
        <Attachments
          attachments={formik.values.attachments || []}
          setAttachments={handleSetAttachments}
          style={{ marginTop: 16 }}
          disabled={readOnly}
        />
        {!readOnly && (
          <LoadingButton
            color="primary"
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            loading={isLoading}
          >
            {isUpdateMode ? 'Save Changes' : 'Create Order'}
          </LoadingButton>
        )}
      </form>
    );
  }

  return isUpdateMode ? (
    renderForm()
  ) : (
    <Container maxWidth="md">
      <PageTitleWithBack title="Create Order" onBackClick={handleBackClick} />
      <SS.BorderedBox>{renderForm()}</SS.BorderedBox>
    </Container>
  );
}

export default OrderForm;
