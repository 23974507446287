import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';

import {
  USER_ROLE_MAP,
  USER_ZONE_MAP,
  EXTERNAL_USER_PAYMENT_METHOD_MAP,
  PAYMENT_STATUS_MAP,
  ORDER_PAYMENT_METHOD_MAP,
  ORDER_STATUS_MAP,
  TASK_STATUS_MAP,
  TASK_TYPE_MAP,
  INVOICE_STATUS_MAP,
  DEFAULT_ORDER_STATUS,
} from '../constants';

export function logout(redirectUrl?: string) {
  localStorage.clear();
  window.location.href = `/signin${redirectUrl ? `?redirectUrl=${redirectUrl}` : ''}`;
}

export function getFormattedRole(role: string) {
  return USER_ROLE_MAP[role] || role;
}

export function getRoleOptions() {
  return Object.entries(USER_ROLE_MAP).map(([key, value]) => ({ label: value, value: key }));
}

export function getZoneOptions() {
  return Object.entries(USER_ZONE_MAP).map(([key, value]) => ({ label: value, value: key }));
}

export function getOrderStatusOptions() {
  return Object.entries(ORDER_STATUS_MAP).map(([key, value]) => ({ label: value, value: key }));
}

export function getTeamStatusOptions() {
  return DEFAULT_ORDER_STATUS.map((status) => ({
    label: startCase(lowerCase(status)),
    value: status,
  }));
}

export function getPaymentStatusOptions() {
  return Object.entries(PAYMENT_STATUS_MAP).map(([key, value]) => ({ label: value, value: key }));
}

export function getTaskStatusOptions() {
  return Object.entries(TASK_STATUS_MAP).map(([key, value]) => ({ label: value, value: key }));
}

export function getTaskTypeOptions() {
  return Object.entries(TASK_TYPE_MAP).map(([key, value]) => ({ label: value, value: key }));
}

export function getInvoiceStatusOptions() {
  return Object.entries(INVOICE_STATUS_MAP).map(([key, value]) => ({ label: value, value: key }));
}

export function getOrderPaymentMethodOptions() {
  return Object.entries(ORDER_PAYMENT_METHOD_MAP).map(([key, value]) => ({
    label: value,
    value: key,
  }));
}

export function getExternalUserPaymentMethodOptions() {
  return Object.entries(EXTERNAL_USER_PAYMENT_METHOD_MAP).map(([key, value]) => ({
    label: value,
    value: key,
  }));
}

export function downloadBlob(prefix: string, data: any) {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  const date = new Date().toISOString().split('T');
  const filename = `${prefix}-${date[0]}-${date[1].split('.')[0].split(':').join('-')}.xlsx`;
  a.href = url;
  a.download = filename;
  a.click();
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 150);
}
