import axios from '../axios';
import { ICategory, IMinimalCategory } from '../types/categories';
import { PageResponse, SearchParams } from '../types/common';

export function getCategories(requestBody: SearchParams) {
  return axios.post<PageResponse<ICategory>>('/category/find', requestBody);
}

export function getCategoryDetails(id: string) {
  return axios.get<ICategory>('/category/detail', { params: { id } });
}

export function createCategory(requestBody: Partial<ICategory>) {
  return axios.post<ICategory>('/category/create', requestBody);
}

export function updateCategory(requestBody: Partial<ICategory>) {
  return axios.post<ICategory>('/category/update', requestBody);
}

export function deleteCategory(requestBody: { _id: string }) {
  return axios.post<ICategory>('/category/delete', requestBody);
}

export function getCategoriesForDropdown() {
  return axios.get<IMinimalCategory[]>('/category/dropDown');
}
