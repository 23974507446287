export enum AuditEntity {
  USER = 'USER',
  TEAM = 'TEAM',
  CATEGORY = 'CATEGORY',
  ORDER = 'ORDER',
  ORDER_TEAM = 'ORDER_TEAM',
  CLIENT = 'CLIENT',
  OUTSOURCE_CLIENT = 'OUTSOURCE_CLIENT',
  OUTSOURCE = 'OUTSOURCE',
  INVOICE = 'INVOICE',
}

export interface IAudit {
  id: string;
  entityId: string;
  entityType: AuditEntity;
  detail: string;
  modifiedBy: string;
  modifiedByName: string;
  modifiedTime: string;
}
