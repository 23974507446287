import { useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';

import ConfirmActionDialog from './ConfirmActionDialog';
import useSnackbar from '../../hooks/useSnackbar';
import LinearProgressWithLabel from '../shared/Loaders/LinearProgressWithLabel';

import { uploadFiles } from '../../requests/common';
import { IAttachment } from '../../types/common';

interface IProps {
  label?: string;
  attachments: IAttachment[];
  setAttachments: (attachments: IAttachment[]) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  disableDownload?: boolean;
}

const AttachmentsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #DADBDC',
  padding: theme.spacing(1),
  borderRadius: 4,
  marginBottom: theme.spacing(1),
  '& > div:first-child': { marginRight: theme.spacing(1) },
  '& > div:nth-child(2)': { flexGrow: 1 },
  '& > div:last-child': { marginLeft: theme.spacing(1) },

  '.attachment-title': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#3A4A5E',
    fontWeight: 500,
  },
}));

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#C4C4C4',
  borderStyle: 'dashed',
  backgroundColor: '#F6F6F6',
  color: '#8999AB',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};
const focusedStyle: React.CSSProperties = { borderColor: '#2196f3' };
const acceptStyle: React.CSSProperties = { borderColor: '#00e676' };
const rejectStyle: React.CSSProperties = { borderColor: '#ff1744' };

const initialUploadData = { uploading: false, percentCompleted: 0 };
const initialDialogData = { id: '', name: '', open: false };

function Attachments({
  label,
  attachments,
  style,
  setAttachments,
  disabled,
  disableDownload,
}: IProps) {
  const { showSnackbar } = useSnackbar();
  const [dialogData, setDialogData] = useState(initialDialogData);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const [uploadInfo, setUploadInfo] = useState(initialUploadData);

  const isUploading = uploadInfo.uploading;
  const uploadPercentCompleted = getPercentCompleted();

  function getPercentCompleted() {
    const percentCompleted = uploadInfo.percentCompleted;
    return percentCompleted === 100 && isUploading ? percentCompleted - 1 : percentCompleted;
  }

  function handleUploadProgress(progressEvent: any) {
    const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setUploadInfo({ uploading: true, percentCompleted: percentage });
  }

  function handleDrop(acceptedFiles: File[]) {
    if (!acceptedFiles.length) return;

    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('files', file);
    });
    showSnackbar({ severity: 'info', message: 'Uploading files...' });
    uploadFiles(formData, handleUploadProgress)
      .then((res) => {
        setUploadInfo(initialUploadData);
        showSnackbar({ severity: 'success', message: 'Uploaded successfully' });
        setAttachments([...res.data.attachments, ...attachments]);
      })
      .catch((error: AxiosError) => {
        setUploadInfo(initialUploadData);
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleDialogOpen(attachment: IAttachment) {
    setDialogData({ id: attachment.id, name: attachment.name, open: true });
  }

  function handleDialogClose() {
    setDialogData(initialDialogData);
  }

  function handleDeleteAttachment() {
    const { id } = dialogData;
    if (!id) {
      showSnackbar({ severity: 'error', message: 'Unable to delete attachment' });
      return;
    }

    setAttachments(attachments.filter((attachment) => attachment.id !== id));
    handleDialogClose();
  }

  function handleDownloadAttachment(url: string) {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', 'true');
    element.click();
  }

  async function download(url: string) {
    return fetch(url).then((resp) => resp.blob());
  }

  function downloadMany(urls: string[]) {
    return Promise.all(urls.map((url) => download(url)));
  }

  async function exportZip(blobs: Blob[]) {
    const zip = new JSZip();
    blobs.forEach((blob, i) => {
      zip.file(attachments[i].name, blob);
    });
    zip.generateAsync({ type: 'blob' }).then((zipFile) => {
      const currentDate = new Date().getTime();
      const fileName = `attachments-${currentDate}.zip`;
      return saveAs(zipFile, fileName);
    });
  }

  function handleDownloadAllAttachments() {
    setIsDownloadingAll(true);
    downloadMany(attachments.map((attachment) => attachment.url))
      .then(exportZip)
      .catch((error) =>
        showSnackbar({ severity: 'error', message: 'An error occurred. Please try again.' })
      )
      .finally(() => setIsDownloadingAll(false));
  }

  const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      disabled: isUploading,
      onDrop: handleDrop,
    });
  const dropzoneStyles = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const downloadAllButton = (
    <div>
      <LoadingButton
        disabled={attachments.length === 0}
        loading={isDownloadingAll}
        variant="contained"
        startIcon={<GetAppIcon />}
        onClick={handleDownloadAllAttachments}
      >
        Download All
      </LoadingButton>
    </div>
  );

  return (
    <>
      <div style={{ ...style }}>
        {label && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box component="p" sx={{ color: '#172B70', fontWeight: 600 }}>
              {label}
            </Box>
            {!disableDownload && attachments.length > 0 && downloadAllButton}
          </Box>
        )}
        {!disabled && (
          <div {...getRootProps({ style: dropzoneStyles })}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <>
                <p>Drag 'n' drop files to upload, or click to select files</p>
              </>
            )}
          </div>
        )}
        {isUploading && (
          <Box sx={{ mt: 2, mb: 1 }}>
            <div>Uploading files</div>
            <LinearProgressWithLabel value={uploadPercentCompleted} />
          </Box>
        )}
        {disabled && attachments.length === 0 && (
          <Box sx={{ fontSize: '0.875rem' }}>No files uploaded</Box>
        )}
        {attachments.length > 0 && (
          <>
            {!label && (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box component="p" sx={{ color: '#172B70', fontWeight: 600 }}>
                  Uploaded files
                </Box>
                {!disableDownload && downloadAllButton}
              </Box>
            )}
            <Box mt={label ? 1.5 : 0}>
              {attachments.map((attachment) => (
                <AttachmentsContainer key={attachment.id}>
                  <Box display="flex">
                    <FileIcon htmlColor="#bdbdbd" />
                  </Box>
                  <div>
                    <div className="attachment-title">
                      <span>{attachment.name}</span>
                    </div>
                  </div>
                  <div>
                    {!disableDownload && (
                      <IconButton
                        edge="end"
                        aria-label="download"
                        size="small"
                        onClick={() => handleDownloadAttachment(attachment.url)}
                      >
                        <GetAppIcon fontSize="small" />
                      </IconButton>
                    )}
                    {!disabled && (
                      <IconButton
                        color="error"
                        edge="end"
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDialogOpen(attachment)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                </AttachmentsContainer>
              ))}
            </Box>
          </>
        )}
      </div>
      <ConfirmActionDialog
        open={dialogData.open}
        isLoading={false}
        title="Delete Attachment"
        description={`Are you sure you want to delete this attachment "${dialogData.name}"?`}
        onClose={handleDialogClose}
        onConfirm={handleDeleteAttachment}
      />
    </>
  );
}

export default Attachments;
