import { GridRowsProp } from '@mui/x-data-grid';

import OrdersTable from './OrdersTable';

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  handlePageChange(page: number): void;
  handlePageSizeChange(pageSize: number): void;
}

function RelatedOrders({
  page,
  pageSize,
  rows,
  totalElements,
  loading,
  handlePageChange,
  handlePageSizeChange,
}: IProps) {
  return (
    <OrdersTable
      type="related-orders"
      page={page}
      pageSize={pageSize}
      rows={rows}
      totalElements={totalElements}
      loading={loading}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
    />
  );
}

export default RelatedOrders;
