import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const Styles = {
  TitleContainer: styled('div')`
    margin: 16px 0px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .loader {
      margin-left: 16px;
    }

    .date-range-picker {
      min-width: 256px;
    }
  `,
  ChartContainer: styled('div')`
    width: 100%;
    height: 344px;
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 24px 8px 8px;
    margin: 8px 0px;
  `,
  TableCell: styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      borderBottom: 'none',
      padding: theme.spacing(2, 0, 0),
      '&:first-child': { color: '#1E2F44', fontWeight: 600 },
      '&:not(:first-child)': { color: '#2C3A6B', fontWeight: 'bold' },
    },
    [`&.${tableCellClasses.head}`]: {
      padding: theme.spacing(2, 0, 0),
      color: '#1E2F44',
      fontWeight: 600,
      width: 40,
    },
  })),
  Card: styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 3),
    borderRadius: 25,
    height: 200,
    '&.revenue': {
      background: 'linear-gradient(0deg, #FFDED5, #FFDED5)',
      overflowY: 'scroll',
      '& > div:nth-child(2)': { fontWeight: 500, color: '#1A1C1F' },
    },
    '&.sales': {
      background: 'linear-gradient(0deg, #FFEACB, #FFEACB)',
      overflowY: 'scroll',
      '& > div:nth-child(2)': { fontWeight: 500, color: '#1A1C1F' },
    },
    '&.clients': {
      background: 'linear-gradient(0deg, #F3EBEE, #F3EBEE)',
      overflowY: 'scroll',
      '& > div:nth-child(2)': { fontWeight: 500, color: '#1A1C1F' },
    },
    '&.status': {
      background: 'linear-gradient(0deg, #E6EFE5, #E6EFE5)',
      overflowY: 'scroll',
      '& > div:nth-child(2)': {
        display: 'flex',
        flexWrap: 'wrap',
        '& > div': {
          flexBasis: '50%',
          marginBottom: theme.spacing(3),
          '& > p': { marginTop: 0, marginBottom: theme.spacing(0.5) },
          '& > p:first-child': { color: '#2C3A6B', fontSize: '1rem', fontWeight: 'bold' },
          '& > p:last-child': { color: '#1E2F44', fontSize: '0.75rem', fontWeight: 600 },
        },
      },
    },
    '.header': { display: 'flex', justifyContent: 'space-between' },
    '.title': { color: '#1A1C1F', fontWeight: 700, fontSize: '1rem', marginTop: 0 },
  })),
};

export default Styles;
