import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';

import ReportsTable from './ReportsTable';
import PageTitleWithBack from '../shared/PageTitleWithBack';
import ReactDatePickerMuiTextField from '../shared/ReactDatePickerMuiTextField';

import useSnackbar from '../../hooks/useSnackbar';

import S from './styles';
import { getReportTitle } from './utils';
import { IClientsReport, ISalesReport } from '../../types/reports';
import { getSalesReportData, getClientsReportData } from '../../requests/reports';
import { Currency } from '../../types/common';
import { IRevenue } from '../../types/dashboard';

const DEFAULT_DATE_RANGE = {
  start: dayjs().startOf('month').toDate(),
  end: dayjs().endOf('day').toDate(),
};

function getInitialDate(start: string | null, end: string | null): { start: Date; end: Date } {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  if (startDate.isValid() && endDate.isValid()) {
    if (startDate.isAfter(endDate)) {
      return { start: endDate.toDate(), end: startDate.toDate() };
    } else {
      return { start: startDate.toDate(), end: endDate.toDate() };
    }
  }

  return DEFAULT_DATE_RANGE;
}

function Reports() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const { reportType } = useParams<{ reportType: string }>();
  const reportTitle = getReportTitle(reportType);

  const [isLoading, setIsLoading] = useState(false);
  const [salesReportData, setSalesReportData] = useState<ISalesReport[]>([]);
  const [clientsReportData, setClientsReportData] = useState<IClientsReport[]>([]);
  const [date, setDate] = useState<{ start: Date | null; end: Date | null }>(
    getInitialDate(searchParams.get('start'), searchParams.get('end'))
  );
  const [{ page, pageSize }, setPage] = useState({ page: 0, pageSize: 10 });

  useEffect(() => {
    if (reportTitle && date.start && date.end) {
      setPage({ page: 0, pageSize });
      switch (reportType) {
        case 'sales': {
          setIsLoading(true);
          getSalesReportData(date.start.toISOString(), date.end.toISOString())
            .then((res) =>
              setSalesReportData(
                res.data.map((s) => ({
                  ...s,
                  ...flattenRevenue(s.totalRevenue),
                }))
              )
            )
            .catch((error: AxiosError) => {
              const errorMessage =
                (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
              showSnackbar({ severity: 'error', message: errorMessage });
            })
            .finally(() => setIsLoading(false));
          break;
        }
        case 'clients': {
          setIsLoading(true);
          getClientsReportData(date.start.toISOString(), date.end.toISOString())
            .then((res) =>
              setClientsReportData(
                res.data.map((c) => ({
                  ...c,
                  id: c.clientId,
                  ...flattenRevenue(c.totalRevenue),
                }))
              )
            )
            .catch((error: AxiosError) => {
              const errorMessage =
                (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
              showSnackbar({ severity: 'error', message: errorMessage });
            })
            .finally(() => setIsLoading(false));
          break;
        }
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportTitle, reportType, date, pageSize]);

  function getReportData() {
    if (reportTitle) {
      switch (reportType) {
        case 'sales':
          return salesReportData;
        case 'clients':
          return clientsReportData;
        default:
          return [];
      }
    }
    return [];
  }

  function flattenRevenue(revenue: IRevenue) {
    return {
      orderCount: revenue?.orderCount || 0,
      [Currency.INR]: revenue?.revenueByCurrency[Currency.INR] || 0,
      [Currency.USD]: revenue?.revenueByCurrency[Currency.USD] || 0,
      [Currency.EUR]: revenue?.revenueByCurrency[Currency.EUR] || 0,
      [Currency.GBP]: revenue?.revenueByCurrency[Currency.GBP] || 0,
    };
  }

  function handleBack() {
    navigate({ pathname: '/', search: searchParams.toString() });
  }

  function handlePageChange(page: number) {
    setPage({ page, pageSize });
  }

  function handlePageSizeChange(pageSize: number) {
    setPage({ page: 0, pageSize });
  }

  function handleDateRangeChange(dates: any) {
    let start = dates[0];
    if (dayjs(start).isValid()) {
      start = dayjs(start).startOf('day').toDate();
    }

    let end = dates[1];
    if (dayjs(end).isValid()) {
      end = dayjs(end).endOf('day').toDate();
    }

    setDate({ start, end });

    if (
      !start ||
      !end ||
      (start.toISOString() === DEFAULT_DATE_RANGE.start.toISOString() &&
        end.toISOString() === DEFAULT_DATE_RANGE.end.toISOString())
    ) {
      setSearchParams({});
    } else {
      setSearchParams({ start: start.toISOString(), end: end.toISOString() });
    }
  }

  function appendDateToQueryParams(route: string) {
    if (date.start && date.end) {
      return route.concat(
        `?filters={"createdTime":"${date.start.toISOString()}~${date.end.toISOString()}"}`
      );
    }

    return route;
  }

  const isValidReportType = Boolean(reportTitle);
  return (
    <>
      <S.TitleContainer>
        <PageTitleWithBack
          title={`Reports - ${isValidReportType ? reportTitle : 'Unknown'}`}
          onBackClick={handleBack}
        />
        <S.DateRangePickerContainer>
          <div>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              onChange={handleDateRangeChange}
              startDate={date.start}
              endDate={date.end}
              customInput={<ReactDatePickerMuiTextField className="date-range-picker" />}
              selectsRange
            />
          </div>
          <div>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              component={Link}
              to={appendDateToQueryParams('/orders')}
            >
              View Orders
            </Button>
          </div>
        </S.DateRangePickerContainer>
      </S.TitleContainer>
      {reportType && isValidReportType ? (
        <ReportsTable
          reportType={reportType}
          loading={isLoading}
          data={getReportData()}
          page={page}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      ) : (
        <div>Unknown report</div>
      )}
    </>
  );
}

export default Reports;
