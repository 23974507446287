import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Styles = {
  Layout: styled(Box)(() => ({
    display: 'flex',
  })),
  DrawerHeader: styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  })),
  Drawer: styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      '.MuiDrawer-paper': {
        background:
          'linear-gradient(270deg, rgba(255, 241, 233, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%)',
      },
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    })
  ),
  Link: styled(Link)(({ theme }) => ({
    color: 'inherit',
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  })),
  Logo: styled('img')`
    width: 120px;
    vertical-align: middle;
  `,
  NotificationsDrawer: styled(MuiDrawer)(({ theme }) => ({
    zIndex: 1300,
    '& .MuiDrawer-paper': {
      width: 400,
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: { width: 296 },
    },
    '.notifications-title': { color: '#172B70', marginRight: theme.spacing(1) },
    '.notifications-empty-container': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    '.no-notifications-message': { color: '#577496', maxWidth: '85%', textAlign: 'center' },
    '.notification-item': {
      border: '1px solid #D8E1F2',
      borderRadius: 4,
      marginBottom: 12,
      padding: 8,
      color: '#172B70',
      textDecoration: 'none',
      display: 'block',
      '&:hover': { backgroundColor: '#E7F3FF' },
      '&.read': { color: '#ACB2B5', '& $notificationTime': { color: '#ACB2B5' } },
    },
    '.notification-description': { fontSize: 14 },
    '.notification-time': { color: '#76849F', marginTop: 2, fontSize: 12 },
    '.loader': { marginLeft: theme.spacing(2), color: '#FF6329 !important' },
  })),
};

export default Styles;
