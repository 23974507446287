import axios from '../axios';
import { IClientsReport, ISalesReport } from '../types/reports';

export function getSalesReportData(start: string, end: string) {
  return axios.get<ISalesReport[]>('/dashboard/admin/report/sales', {
    params: { fromDate: start, toDate: end },
  });
}

export function getClientsReportData(start: string, end: string) {
  return axios.get<IClientsReport[]>('/dashboard/admin/report/clients', {
    params: { fromDate: start, toDate: end },
  });
}
