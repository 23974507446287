import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: { primary: { main: '#FF6329' } },
  typography: { fontFamily: ['"Poppins"', 'sans-serif'].join(', ') },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { textTransform: 'unset' },
        containedPrimary: { color: '#FFFFFF' },
        contained: { boxShadow: 'none' },
      },
    },
  },
});

export default theme;
