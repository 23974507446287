import { GridColDef } from '@mui/x-data-grid';

import SS from '../shared/styles';
import { IClientsReport, ISalesReport } from '../../types/reports';
import { Currency } from '../../types/common';

interface IProps {
  reportType: string;
  loading: boolean;
  page: number;
  pageSize: number;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  data: IClientsReport[] | ISalesReport[];
}

function ReportsTable(props: IProps) {
  const { reportType, loading, data, page, pageSize, onPageChange, onPageSizeChange } = props;

  function getColumns() {
    let columns: GridColDef[] = [];
    const revenueColumns: GridColDef[] = [
      {
        field: 'orderCount',
        headerName: 'No. of Orders',
        flex: 1,
        renderCell: (params) => params.row.orderCount,
      },
      {
        field: 'INR',
        headerName: `${Currency.INR} (₹)`,
        flex: 1,
        renderCell: (params) => params.row[Currency.INR],
      },
      {
        field: 'USD',
        headerName: `${Currency.USD} ($)`,
        flex: 1,
        renderCell: (params) => params.row[Currency.USD],
      },
      {
        field: 'EUR',
        headerName: `${Currency.EUR} (€)`,
        flex: 1,
        renderCell: (params) => params.row[Currency.EUR],
      },
      {
        field: 'GBP',
        headerName: `${Currency.GBP} (£)`,
        flex: 1,
        renderCell: (params) => params.row[Currency.GBP],
      },
    ];

    switch (reportType) {
      case 'sales':
        columns = [
          { field: 'name', headerName: 'Name', flex: 1 },
          { field: 'id', headerName: 'Email', flex: 1 },
          { field: 'clientsCount', headerName: 'No. of Clients', flex: 1 },
          ...revenueColumns,
        ];
        break;
      case 'clients':
        columns = [
          { field: 'clientEmail', headerName: 'Client Email', flex: 1 },
          ...revenueColumns,
        ];
        break;
      default:
        break;
    }
    return columns;
  }

  return (
    <SS.DataGrid
      sx={{ '.MuiDataGrid-row': { cursor: 'default' } }}
      disableSelectionOnClick
      rows={data}
      columns={getColumns()}
      loading={loading}
      pagination
      paginationMode="client"
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      page={page}
      pageSize={pageSize}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    />
  );
}

export default ReportsTable;
