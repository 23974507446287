import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

import AddEditTask from './AddEditTask';
import Attachments from '../shared/Attachments';
import ConfirmActionDialog from '../shared/ConfirmActionDialog';
import S from './styles';

import useAuth from '../../hooks/useAuth';
import useSnackbar from '../../hooks/useSnackbar';
import { IOrderTeam } from '../../types/orders';
import { ITeam } from '../../types/teams';
import { updateOrderTeam } from '../../requests/orders';
import { deleteTask, getTasks } from '../../requests/tasks';
import { ITask } from '../../types/tasks';
import { TASK_STATUS_MAP } from '../../constants';
import { IAttachment } from '../../types/common';
import { UserRole } from '../../types/users';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface IProps {
  orderId: string;
  team: ITeam;
  orderTeam?: IOrderTeam;
  fetchOrder?: () => void;
  fetchOrders?: () => void;
}

const initialAlertDialogData = {
  id: '',
  name: '',
  open: false,
  isLoading: false,
};

const validationSchema = yup.object({
  status: yup.string().required('Status is required'),
  assignedToId: yup.string().required('Assignee is required'),
  assignedToName: yup.string(),
  description: yup.string(),
  attachments: yup.array().of(yup.object()),
  reports: yup.array().of(yup.object()),
});

function OrderTeam({ orderId, team, orderTeam, fetchOrder, fetchOrders }: IProps) {
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isTasksLoading, setIsTasksLoading] = useState(false);
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [dialogData, setDialogData] = useState<{
    open: boolean;
    mode: 'create' | 'update';
    task: ITask | null;
  }>({ open: false, mode: 'create', task: null });
  const [alertDialogData, setAlertDialogData] = useState(initialAlertDialogData);
  const formik = useFormik<Partial<IOrderTeam>>({
    validationSchema,
    initialValues: getInitialValues(orderTeam),
    onSubmit: handleSubmit,
  });
  const isCurrentUserAMemberOfTeam = (team?.members || []).find(
    (member) => member.id === user?._id
  );
  const isCurrentUserOneOfEligibleRole = [
    UserRole.MANAGER,
    UserRole.TEAM_LEAD,
    UserRole.EMPLOYEE,
  ].includes(user?.role as UserRole);
  const isSales = user?.role === UserRole.SALES;
  const canDeleteTask = [UserRole.MANAGER, UserRole.TEAM_LEAD].includes(user?.role as UserRole);
  const readOnly =
    user?.role === UserRole.SUPER_ADMIN
      ? false
      : isSales || (isCurrentUserOneOfEligibleRole && !isCurrentUserAMemberOfTeam);

  useEffect(() => {
    fetchTasks();

    formik.resetForm({ values: getInitialValues(orderTeam) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTeam]);

  function getInitialValues(data?: IOrderTeam) {
    return {
      status: data?.status || '',
      assignedToId: data?.assignedToId || '',
      assignedToName: data?.assignedToName || '',
      description: data?.description || '',
      attachments: data?.attachments || [],
      reports: data?.reports || [],
    };
  }

  function fetchTasks() {
    if (orderTeam) {
      setIsTasksLoading(true);
      getTasks(orderId, orderTeam.id)
        .then((res) => setTasks(res.data))
        .catch((error: AxiosError) => {
          const errorMessage =
            (error?.response?.data as any)?.message || 'An error occurred while fetching tasks.';
          showSnackbar({ severity: 'error', message: errorMessage });
        })
        .finally(() => setIsTasksLoading(false));
    }
  }

  function handleSubmit(data: Partial<IOrderTeam>) {
    setIsLoading(true);
    updateOrderTeam({ order_id: orderId, id: orderTeam?.id, ...data })
      .then(() => {
        showSnackbar({ severity: 'success', message: 'Team updated successfully' });
        fetchOrder?.();
        fetchOrders?.();
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred while updating team.';
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsLoading(false));
  }

  function handleTaskClick(task: ITask) {
    return function () {
      setDialogData({ open: true, mode: 'update', task });
    };
  }

  function handleAddTaskClick() {
    setDialogData({ open: true, mode: 'create', task: null });
  }

  function handleDialogClose(refreshTasks?: boolean) {
    if (refreshTasks) {
      fetchTasks();
    }
    setDialogData({ open: false, mode: 'create', task: null });
  }

  function handleAlertDialogClose() {
    setAlertDialogData(initialAlertDialogData);
  }

  function handleDeleteTaskDialogOpen(task: ITask) {
    return function (event: React.MouseEvent<HTMLElement>) {
      event.stopPropagation();
      setAlertDialogData({
        id: task.id,
        name: task.name,
        open: true,
        isLoading: false,
      });
    };
  }

  function handleDeleteTask() {
    const { id } = alertDialogData;
    if (!id) {
      showSnackbar({ severity: 'error', message: 'Unable to delete task' });
      return;
    }

    setAlertDialogData({ ...alertDialogData, isLoading: true });
    deleteTask(id)
      .then(() => {
        showSnackbar({
          severity: 'success',
          message: 'Task deleted successfully',
        });
        fetchTasks();
        setAlertDialogData(initialAlertDialogData);
      })
      .catch((error: AxiosError) => {
        setAlertDialogData({ ...alertDialogData, isLoading: false });
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleSetAttachments(key: string) {
    return function (attachments: IAttachment[]) {
      formik.setFieldValue(key, attachments);
    };
  }

  if (!orderTeam) {
    return <div>Team details not found.</div>;
  }

  function getAssignee() {
    return team?.members?.find((m) => m.id === formik.values.assignedToId) || null;
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container rowSpacing={2} columnSpacing={1}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              options={team?.members || []}
              value={getAssignee()}
              onChange={(_, newValue) => {
                formik.setFieldValue('assignedToId', newValue?.id);
                formik.setFieldValue('assignedToName', newValue?.name);
              }}
              readOnly={readOnly}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Assignee"
                  error={formik.touched.assignedToId && Boolean(formik.errors.assignedToId)}
                  helperText={formik.touched.assignedToId && formik.errors.assignedToId}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              name="status"
              label="Status"
              fullWidth
              value={formik.values.status}
              onChange={formik.handleChange}
              InputProps={{ readOnly }}
            >
              {team?.orderStatus?.map((status, index) => (
                <MenuItem key={index} value={status}>
                  {startCase(lowerCase(status))}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {!isSales && (
            <Grid item xs={12}>
              <S.MessageInputContainer sx={{ padding: 0, border: 0 }}>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ size: ['small', false, 'large', 'huge'] }],
                      ['bold', 'italic'],
                      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                      ['link', 'clean', 'unlink'],
                    ],
                  }}
                  readOnly={readOnly}
                  placeholder="Description"
                  value={formik.values.description}
                  onChange={(html) => formik.setFieldValue('description', html)}
                />
              </S.MessageInputContainer>
            </Grid>
          )}
        </Grid>
        {orderTeam && (
          <Attachments
            label="Attachments"
            attachments={formik.values.attachments || []}
            setAttachments={handleSetAttachments('attachments')}
            style={{ marginTop: 16 }}
            disabled={readOnly}
            disableDownload={isSales}
          />
        )}
        {orderTeam && (
          <Attachments
            label="Reports"
            attachments={formik.values.reports || []}
            setAttachments={handleSetAttachments('reports')}
            style={{ marginTop: 16 }}
            disabled={readOnly}
            disableDownload={isSales}
          />
        )}
        {!readOnly && (
          <LoadingButton
            color="primary"
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            loading={isLoading}
          >
            Save Changes
          </LoadingButton>
        )}
      </form>
      <div>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <h4 style={{ color: '#172B70' }}>Tasks</h4>
            {isTasksLoading && <CircularProgress color="primary" size={24} sx={{ ml: 2 }} />}
          </Box>
          <div>
            <IconButton
              aria-label="add"
              color="primary"
              onClick={handleAddTaskClick}
              disabled={readOnly}
            >
              <AddIcon />
            </IconButton>
          </div>
        </Box>
        {tasks.length ? (
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Assignee</TableCell>
                  {canDeleteTask && <TableCell>Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    onClick={handleTaskClick(row)}
                  >
                    <TableCell component="th" scope="row">
                      {row.taskId || ''}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{TASK_STATUS_MAP[row.status] || row.status}</TableCell>
                    <TableCell>{row.assignedToName || 'Unassigned'}</TableCell>
                    {canDeleteTask && (
                      <TableCell>
                        <IconButton
                          color="error"
                          edge="end"
                          aria-label="delete"
                          size="small"
                          onClick={handleDeleteTaskDialogOpen(row)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div>No tasks found.</div>
        )}
      </div>
      <AddEditTask
        open={dialogData.open}
        task={dialogData.task}
        mode={dialogData.mode}
        orderId={orderId}
        team={team}
        orderTeam={orderTeam}
        readOnly={dialogData.mode === 'update' && readOnly}
        handleClose={handleDialogClose}
      />
      <ConfirmActionDialog
        open={alertDialogData.open}
        isLoading={alertDialogData.isLoading}
        title="Delete Task"
        description={`Are you sure you want to delete this task "${alertDialogData.name}"?`}
        onClose={handleAlertDialogClose}
        onConfirm={handleDeleteTask}
      />
    </>
  );
}

export default OrderTeam;
