import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const Styles = {
  Link: styled(Link)`
    color: #172b70;
    font-size: 14px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  `,
  Title: styled(Typography)(({ theme }) => ({
    color: '#172b70',
    marginBottom: theme.spacing(2),
  })),
  SignInContainer: styled('div')`
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 100vh;

    .sub-title {
      color: #172b70;
      margin-bottom: 8px;
    }

    .sign-in-button {
      padding: 13px 60px;
      margin: 12px 0px 24px;
    }
  `,
  ResetPasswordContainer: styled('div')`
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 100vh;

    .description {
      color: #577496;
      font-size: 14px;
    }

    .submit-button,
    .proceed-to-login-button {
      margin-top: 16px;
    }
  `,
};

export default Styles;
