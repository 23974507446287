import { Routes, Route } from 'react-router-dom';

import PrivateRoutes from './PrivateRoutes';
import SignIn from '../components/Auth/SignIn';
import ForgotPassword from '../components/Auth/ForgotPassword';
import ResetPassword from '../components/Auth/ResetPassword';

function PublicRoutes() {
  return (
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/password/reset" element={<ForgotPassword />} />
      <Route path="/password/reset/:token" element={<ResetPassword />} />
      <Route path="*" element={<PrivateRoutes />} />
    </Routes>
  );
}

export default PublicRoutes;
