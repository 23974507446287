import { AxiosError } from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { GridRowParams, GridRowsProp } from '@mui/x-data-grid';

import Filters from './Filters';
import TeamsTable from './TeamsTable';
import TeamDetails from './TeamDetails';
import ConfirmActionDialog from '../shared/ConfirmActionDialog';

import SS from '../shared/styles';
import useSnackbar from '../../hooks/useSnackbar';
import { IFilterState } from '../../types/teams';
import { IFilter, SearchParams } from '../../types/common';
import { deleteTeam, getTeams } from '../../requests/teams';

interface ITeamsState {
  content: GridRowsProp;
  totalElements: number;
}

const initialFilters: IFilterState = {
  name: '',
  'lead.name': '',
  'manager.name': '',
};

const initialDialogData = {
  id: '',
  name: '',
  open: false,
  isLoading: false,
  callback: () => {},
};

function Teams() {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const filtersVisibility = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogData, setDialogData] = useState(initialDialogData);
  const [filters, setFilters] = useState<IFilterState>(initialFilters);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [],
  });
  const [teams, setTeams] = useState<ITeamsState>({ content: [], totalElements: 0 });

  useEffect(() => {
    fetchTeams();
    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  function fetchTeams() {
    setLoading(true);
    getTeams({ page, pageSize, ...(filterFields?.length && { filterFields }) })
      .then((res) => {
        setLoading(false);
        setTeams({ content: res.data.content, totalElements: res.data.totalElements });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }

  function handleApplyFilters() {
    const newFilters: IFilter[] = [];
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterValue) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'like',
          value: filterValue,
        });
      }
    });
    setTeams({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }

  function handleClearFilters() {
    setFilters(initialFilters);
    setTeams({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: [] });
  }

  function handleDialogOpen(id: string, name: string, callback: () => void) {
    setDialogData({ id, name, callback, open: true, isLoading: false });
  }

  function handleDialogClose() {
    setDialogData(initialDialogData);
  }

  function handleDeleteTeam() {
    const { id, callback } = dialogData;
    if (!id) {
      showSnackbar({ severity: 'error', message: 'Unable to delete team' });
      return;
    }

    setDialogData({ ...dialogData, isLoading: true });
    deleteTeam({ _id: id })
      .then(() => {
        showSnackbar({
          severity: 'success',
          message: 'Team deleted successfully',
        });
        callback();
        setDialogData(initialDialogData);
      })
      .catch((error: AxiosError) => {
        setDialogData({ ...dialogData, isLoading: false });
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleRowClick(params: GridRowParams) {
    navigate(`/teams/${window.btoa(params.row._id)}`);
  }

  function getMode() {
    return params.id === 'create' ? 'create' : 'update';
  }

  function getId() {
    if (getMode() === 'create') {
      return '';
    }

    const id = params.id || '';
    try {
      return window.atob(id);
    } catch (error) {
      return id;
    }
  }

  return (
    <>
      {!params.id ? (
        <div>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
          >
            <SS.PageTitle variant="h6">Teams</SS.PageTitle>
            <Button component={Link} to="/teams/create" color="primary" startIcon={<AddIcon />}>
              Create
            </Button>
          </Box>
          <Filters
            visibility={filtersVisibility}
            filters={[filters, setFilters]}
            onApplyFilters={handleApplyFilters}
            onClearFilters={handleClearFilters}
          />
          <TeamsTable
            page={page}
            pageSize={pageSize}
            rows={teams.content}
            totalElements={teams.totalElements}
            loading={loading}
            fetchTeams={fetchTeams}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowClick={handleRowClick}
            onDeleteClick={handleDialogOpen}
          />
        </div>
      ) : (
        <TeamDetails
          mode={getMode()}
          id={getId()}
          fetchTeams={fetchTeams}
          onDeleteClick={handleDialogOpen}
        />
      )}
      <ConfirmActionDialog
        open={dialogData.open}
        isLoading={dialogData.isLoading}
        title="Delete Team"
        description={`Are you sure you want to delete this team "${dialogData.name}"?`}
        onClose={handleDialogClose}
        onConfirm={handleDeleteTeam}
      />
    </>
  );
}

export default Teams;
