import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import CircularProgress from '@mui/material/CircularProgress';
import {
  BarChart,
  Bar,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import useAuth from '../../hooks/useAuth';
import useSnackbar from '../../hooks/useSnackbar';
import ReactDatePickerMuiTextField from '../shared/ReactDatePickerMuiTextField';
import S from './styles';
import SS from '../shared/styles';

import {
  getDashboardData,
  getDashboardDataForEmployee,
  getDashboardDataForTeamLead,
} from '../../requests/dashboard';
import {
  IDashboardData,
  IEmployeeDashboardData,
  IOrderCount,
  ITeamLeadDashboardData,
} from '../../types/dashboard';
import { CURRENCIES, INVOICE_STATUS_MAP, ORDER_STATUS_MAP, TASK_STATUS_MAP } from '../../constants';
import { UserRole } from '../../types/users';

const STROKE_COLOR = '#E5E5E5';
const LABEL_COLOR = '#9C9C9C';

const DEFAULT_DATE_RANGE = {
  start: dayjs().startOf('month').toDate(),
  end: dayjs().endOf('day').toDate(),
};

function getInitialDate(start: string | null, end: string | null): { start: Date; end: Date } {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  if (startDate.isValid() && endDate.isValid()) {
    if (startDate.isAfter(endDate)) {
      return { start: endDate.toDate(), end: startDate.toDate() };
    } else {
      return { start: startDate.toDate(), end: endDate.toDate() };
    }
  }

  return DEFAULT_DATE_RANGE;
}

function Dashboard() {
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState<{ start: Date | null; end: Date | null }>(
    getInitialDate(searchParams.get('start'), searchParams.get('end'))
  );
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState<IDashboardData>({} as IDashboardData);
  const [teamLeadDashboardData, setTeamLeadDashboardData] = useState<ITeamLeadDashboardData>(
    {} as ITeamLeadDashboardData
  );
  const [employeeDashboardData, setEmployeeDashboardData] = useState<IEmployeeDashboardData>(
    {} as IEmployeeDashboardData
  );
  const isSuperAdmin = user?.role === UserRole.SUPER_ADMIN;
  const isSales = user?.role === UserRole.SALES;
  const isAdminOrSales = [UserRole.SUPER_ADMIN, UserRole.SALES].includes(user?.role as UserRole);
  const isTeamLeadOrManager = [UserRole.TEAM_LEAD, UserRole.MANAGER].includes(
    user?.role as UserRole
  );
  const isEmployee = user?.role === UserRole.EMPLOYEE;

  useEffect(() => {
    fetchDashboardData();
    // eslint-disable-next-line
  }, [date]);

  function fetchDashboardData() {
    if (date.start && date.end && user) {
      setIsLoading(true);
      switch (user.role) {
        case UserRole.SUPER_ADMIN:
        case UserRole.SALES: {
          const role: 'admin' | 'sales' = user.role === UserRole.SUPER_ADMIN ? 'admin' : 'sales';
          getDashboardData(role, date.start.toISOString(), date.end.toISOString())
            .then((res) => setDashboardData(res.data))
            .catch((error: AxiosError) => {
              const errorMessage =
                (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
              showSnackbar({ severity: 'error', message: errorMessage });
            })
            .finally(() => setIsLoading(false));
          break;
        }
        case UserRole.TEAM_LEAD:
        case UserRole.MANAGER: {
          getDashboardDataForTeamLead(date.start.toISOString(), date.end.toISOString())
            .then((res) => setTeamLeadDashboardData(res.data))
            .catch((error: AxiosError) => {
              const errorMessage =
                (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
              showSnackbar({ severity: 'error', message: errorMessage });
            })
            .finally(() => setIsLoading(false));
          break;
        }
        case UserRole.EMPLOYEE: {
          getDashboardDataForEmployee(date.start.toISOString(), date.end.toISOString())
            .then((res) => setEmployeeDashboardData(res.data))
            .catch((error: AxiosError) => {
              const errorMessage =
                (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
              showSnackbar({ severity: 'error', message: errorMessage });
            })
            .finally(() => setIsLoading(false));
          break;
        }
        default:
          break;
      }
    }
  }

  function handleDateRangeChange(dates: any) {
    let start = dates[0];
    if (dayjs(start).isValid()) {
      start = dayjs(start).startOf('day').toDate();
    }

    let end = dates[1];
    if (dayjs(end).isValid()) {
      end = dayjs(end).endOf('day').toDate();
    }

    setDate({ start, end });

    if (
      !start ||
      !end ||
      (start.toISOString() === DEFAULT_DATE_RANGE.start.toISOString() &&
        end.toISOString() === DEFAULT_DATE_RANGE.end.toISOString())
    ) {
      setSearchParams({});
    } else {
      setSearchParams({ start: start.toISOString(), end: end.toISOString() });
    }
  }

  function getChartData() {
    const { revenueByDate } = dashboardData;
    if (revenueByDate && date.start && date.end) {
      let currentDate = dayjs(date.start);
      const endDate = dayjs(date.end);
      let dates = [];
      do {
        dates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'day');
      } while (currentDate.diff(endDate) <= 0);
      return dates.map((date) => ({
        name: dayjs(date).format('D MMM YY'),
        INR: revenueByDate[date]?.revenueByCurrency.INR || 0,
        USD: revenueByDate[date]?.revenueByCurrency.USD || 0,
        EUR: revenueByDate[date]?.revenueByCurrency.EUR || 0,
        GBP: revenueByDate[date]?.revenueByCurrency.GBP || 0,
      }));
    }
    return [{}];
  }

  function getOrderStatusData() {
    let orderCount: IOrderCount;
    if (user?.role === UserRole.SUPER_ADMIN || user?.role === UserRole.SALES) {
      orderCount = dashboardData.orderCount;
    } else if (user?.role === UserRole.TEAM_LEAD || user?.role === UserRole.MANAGER) {
      orderCount = teamLeadDashboardData.orderCount;
    } else {
      orderCount = employeeDashboardData.orderCount;
    }

    if (!orderCount) return [];
    const orderStatuses = [{ label: 'Total Orders', value: orderCount.totalCount || 0 }];
    Object.entries(orderCount.statusCountMap).forEach(([label, value]) => {
      orderStatuses.push({ label: ORDER_STATUS_MAP[label] || label, value });
    });
    return orderStatuses;
  }

  function getInvoiceStatusData() {
    const { invoiceCount } = teamLeadDashboardData;
    if (!invoiceCount) return [];
    const invoiceStatuses = [{ label: 'Total Invoices', value: invoiceCount.totalCount || 0 }];
    Object.entries(invoiceCount.invoiceStatusLongMap).forEach(([label, value]) => {
      invoiceStatuses.push({ label: INVOICE_STATUS_MAP[label] || label, value });
    });
    return invoiceStatuses;
  }

  function getTaskStatusData() {
    const { taskCount } = employeeDashboardData;
    if (!taskCount) return [];
    const taskStatuses = [{ label: 'Total Tasks', value: taskCount.totalCount || 0 }];
    Object.entries(taskCount.statusCountMap).forEach(([label, value]) => {
      taskStatuses.push({ label: TASK_STATUS_MAP[label] || label, value });
    });
    return taskStatuses;
  }

  function appendDateToQueryParams(route: string) {
    if (
      date.start === null ||
      date.end === null ||
      (date.start.toISOString() === DEFAULT_DATE_RANGE.start.toISOString() &&
        date.end.toISOString() === DEFAULT_DATE_RANGE.end.toISOString())
    ) {
      return route;
    }
    return route.concat(`?start=${date.start.toISOString()}&end=${date.end.toISOString()}`);
  }

  return (
    <>
      <S.TitleContainer>
        <Box display="flex" alignItems="center">
          <SS.PageTitle variant="h6">Dashboard</SS.PageTitle>
          {isLoading && <CircularProgress color="primary" size={24} className="loader" />}
        </Box>
        <div>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            onChange={handleDateRangeChange}
            startDate={date.start}
            endDate={date.end}
            maxDate={date.start ? dayjs(date.start).add(3, 'months').toDate() : null}
            customInput={<ReactDatePickerMuiTextField className="date-range-picker" />}
            selectsRange
          />
        </div>
      </S.TitleContainer>
      {isAdminOrSales && (
        <S.ChartContainer>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={getChartData()}
              margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              maxBarSize={55}
            >
              <CartesianGrid horizontal={false} stroke={STROKE_COLOR} />
              <XAxis dataKey="name" stroke={STROKE_COLOR} tick={{ fill: LABEL_COLOR }} />
              <YAxis stroke={STROKE_COLOR} tick={{ fill: LABEL_COLOR }} />
              <Tooltip />
              <Legend />
              <Bar dataKey="INR" fill="#3f51b5" />
              <Bar dataKey="USD" fill="#673ab7" />
              <Bar dataKey="EUR" fill="#4caf50" />
              <Bar dataKey="GBP" fill="#795548" />
            </BarChart>
          </ResponsiveContainer>
        </S.ChartContainer>
      )}
      <Grid container spacing={2} mt={1}>
        {isAdminOrSales && (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <Card type="revenue" title="Revenue">
                <Table>
                  <TableBody>
                    {Object.entries(dashboardData.totalRevenue?.revenueByCurrency || {}).map(
                      ([currency, value]) => (
                        <TableRow key={currency}>
                          <S.TableCell component="th" scope="row">
                            {currency}
                          </S.TableCell>
                          <S.TableCell align="right">{value}</S.TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
            {!isSales && (
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  type="sales"
                  title="Revenue by Sales"
                  {...(isSuperAdmin && { to: appendDateToQueryParams('/reports/sales') })}
                >
                  <div>Top 5 Agents</div>
                  <div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <S.TableCell>Name</S.TableCell>
                          {CURRENCIES.map((currency) => (
                            <S.TableCell key={currency} align="right">
                              {currency}
                            </S.TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dashboardData.revenueBySales?.map((data) => (
                          <TableRow key={data.id}>
                            <S.TableCell component="th" scope="row">
                              {data.name}
                            </S.TableCell>
                            {CURRENCIES.map((currency) => (
                              <S.TableCell key={currency} align="right">
                                {data.revenue.revenueByCurrency[currency] || 0}
                              </S.TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </Card>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                type="clients"
                title="Revenue by Clients"
                {...(isSuperAdmin && { to: appendDateToQueryParams('/reports/clients') })}
              >
                <div>Top 5 Clients</div>
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <S.TableCell>Name</S.TableCell>
                        {CURRENCIES.map((currency) => (
                          <S.TableCell key={currency} align="right">
                            {currency}
                          </S.TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dashboardData.revenueByClients?.map((data) => (
                        <TableRow key={data.id}>
                          <S.TableCell component="th" scope="row">
                            {data.name}
                          </S.TableCell>
                          {CURRENCIES.map((currency) => (
                            <S.TableCell key={currency} align="right">
                              {data.revenue.revenueByCurrency[currency] || 0}
                            </S.TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Card>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <Card type="status" title="Order Status">
            <div>
              {getOrderStatusData().map((data) => (
                <div key={data.label}>
                  <p>{data.value || 0}</p>
                  <p>{data.label}</p>
                </div>
              ))}
            </div>
          </Card>
        </Grid>
        {isTeamLeadOrManager && (
          <Grid item xs={12} sm={6} md={4}>
            <Card type="status" title="Invoice Status">
              <div>
                {getInvoiceStatusData().map((data) => (
                  <div key={data.label}>
                    <p>{data.value || 0}</p>
                    <p>{data.label}</p>
                  </div>
                ))}
              </div>
            </Card>
          </Grid>
        )}
        {isEmployee && (
          <Grid item xs={12} sm={6} md={4}>
            <Card type="status" title="Task Status">
              <div>
                {getTaskStatusData().map((data) => (
                  <div key={data.label}>
                    <p>{data.value || 0}</p>
                    <p>{data.label}</p>
                  </div>
                ))}
              </div>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
}

function Card({
  type,
  title,
  children,
  to,
}: {
  type: string;
  title: React.ReactNode;
  children: React.ReactNode;
  to?: string;
}) {
  return (
    <S.Card className={type}>
      <div className="header">
        <p className="title" {...(type === 'revenue' && { style: { marginBottom: 0 } })}>
          {title}
        </p>
        {to && (
          <div>
            <Button
              component={Link}
              style={{ lineHeight: 1.3 }}
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              to={to}
            >
              View More
            </Button>
          </div>
        )}
      </div>
      {children}
    </S.Card>
  );
}

export default Dashboard;
