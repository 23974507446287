import clsx from 'clsx';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';

import S from './styles';
import Header from './Header';
import Tooltip from '../shared/Tooltip';
import useAuth from '../../hooks/useAuth';
import useSnackbar from '../../hooks/useSnackbar';

import { ADMIN } from './NavItems';
import BellIcon from '../../assets/img/notification-bell.svg';
import { INotification } from '../../types/common';
import {
  getNotifications,
  getUnreadNotificationCount,
  updateNotificationAsRead,
} from '../../requests/common';

const initialPagination = {
  page: -1,
  pageSize: 10,
};

function Layout() {
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [open, setOpen] = useState(true);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState<{ data: INotification[]; totalPages: number }>(
    { data: [], totalPages: 0 }
  );
  const [pagination, setPagination] = useState(initialPagination);
  const hasNextPage = pagination.page < notifications.totalPages - 1;

  useEffect(() => {
    if (!matches) {
      setOpen(false);
    }
  }, [matches]);

  useEffect(() => {
    fetchUnreadNotificationCount();
    const unreadNotificationIntervalId = setInterval(fetchUnreadNotificationCount, 5000);

    return () => {
      clearInterval(unreadNotificationIntervalId);
    };
  }, []);

  useEffect(() => {
    if (openNotifications) {
      fetchNotifications();
    } else {
      resetNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openNotifications]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleNotificationsDrawerToggle = () => {
    setOpenNotifications(!openNotifications);
  };

  const handleNotificationsDrawerClose = () => {
    setOpenNotifications(false);
  };

  function fetchNotifications(refresh = false) {
    setIsNotificationsLoading(true);
    const page = (refresh ? initialPagination.page : pagination.page) + 1;
    getNotifications(page, pagination.pageSize)
      .then((res) => {
        setPagination({ page, pageSize: pagination.pageSize });
        setIsNotificationsLoading(false);
        setNotifications({
          data: refresh ? res.data.content : notifications.data.concat(res.data.content),
          totalPages: res.data.totalPages,
        });
      })
      .catch((error: AxiosError) => {
        setIsNotificationsLoading(false);
        showSnackbar({
          severity: 'error',
          message: 'An error occurred while fetching notifications',
        });
      });
  }

  function fetchUnreadNotificationCount() {
    getUnreadNotificationCount()
      .then((res) => setUnreadNotificationCount(res.data || 0))
      .catch((error: AxiosError) => {
        console.log(error);
      });
  }

  function resetNotifications() {
    setPagination(initialPagination);
    setNotifications({ data: [], totalPages: 0 });
  }

  function refreshNotifications() {
    fetchNotifications(true);
  }

  function handleNotificationClick(notification: INotification) {
    setOpenNotifications(false);
    if (!notification.read) {
      updateNotificationAsRead(notification._id);
    }
  }

  const getUrl = (notification: INotification) => {
    switch (notification.type) {
      case 'ORDER': {
        return `/orders/${window.btoa(notification.typeSystemId)}`;
      }
      default:
        return '';
    }
  };

  function renderNavItems() {
    const items = ADMIN;

    return items
      .map((item) => ({
        ...item,
        data: item.data.filter((navItem) => {
          if (!navItem?.roles?.length) return true;
          if (!user) return false;
          return navItem.roles.includes(user.role);
        }),
      }))
      .map((list, i) => (
        <List
          {...(list.title && {
            subheader: <ListSubheader component="div">{list.title}</ListSubheader>,
          })}
          key={`${list.title}-${i}`}
        >
          {list.data.map((item, index) => {
            return (
              <Tooltip
                key={`${item.title}-${index}`}
                title={item.title}
                placement="right"
                arrow
                show={!open}
              >
                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                  component={S.Link}
                  to={item.path}
                  selected={
                    item.isActive
                      ? item.isActive(location.pathname)
                      : location.pathname === item.path
                  }
                >
                  <ListItemButton
                    sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}
                  >
                    <ListItemIcon
                      sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            );
          })}
        </List>
      ));
  }

  return (
    <S.Layout>
      <Header
        onBellIconClick={handleNotificationsDrawerToggle}
        unreadNotificationCount={unreadNotificationCount}
      />
      <S.Drawer variant="permanent" open={open}>
        <S.DrawerHeader />
        <List>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}
              onClick={handleDrawerToggle}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                {open ? <CloseIcon /> : <MenuIcon />}
              </ListItemIcon>
              <ListItemText primary="Close Menu" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        {renderNavItems()}
      </S.Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, minHeight: '100vh', backgroundColor: '#FBFBFD' }}
      >
        <S.DrawerHeader />
        <Outlet />
      </Box>
      <S.NotificationsDrawer
        anchor="right"
        open={openNotifications}
        onClose={handleNotificationsDrawerClose}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <h3 className="notifications-title">Notifications</h3>
            {isNotificationsLoading ? (
              <CircularProgress color="primary" size={24} className="loader" />
            ) : (
              <div>
                <IconButton onClick={refreshNotifications}>
                  <RefreshIcon />
                </IconButton>
              </div>
            )}
          </Box>
          <div>
            <IconButton onClick={handleNotificationsDrawerClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
        {notifications.data.length === 0 ? (
          <div className="notifications-empty-container">
            <div>
              <img src={BellIcon} alt="" />
            </div>
            <p className="no-notifications-message">
              No notifications available. Notifications about your activity will show up here.
            </p>
          </div>
        ) : (
          <>
            {notifications.data.map((notification) => (
              <Link
                to={getUrl(notification)}
                key={notification._id}
                className={clsx('notification-item', { read: notification.read })}
                onClick={() => handleNotificationClick(notification)}
              >
                <div className="notification-description">{notification.description}</div>
                <div
                  className="notification-time"
                  title={dayjs(notification.createdTime).format('ddd, MMM D, YYYY h:mm A')}
                >
                  {dayjs(notification.createdTime).fromNow()}
                </div>
              </Link>
            ))}
            {hasNextPage && (
              <LoadingButton loading={isNotificationsLoading} onClick={() => fetchNotifications()}>
                Show More
              </LoadingButton>
            )}
          </>
        )}
      </S.NotificationsDrawer>
    </S.Layout>
  );
}

export default Layout;
