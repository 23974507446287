import { forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import DateRangeIcon from '@mui/icons-material/DateRange';

const ReactDatePickerMuiTextField = forwardRef((props: any, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      variant="outlined"
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <DateRangeIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  );
});

export default ReactDatePickerMuiTextField;
