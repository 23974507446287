import { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Badge from '@mui/material/Badge';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import FilterListIcon from '@mui/icons-material/FilterList';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import useAuth from '../../hooks/useAuth';
import { getOrderStatusOptions, getTeamStatusOptions } from '../../utils/utils';
import { IFilterState } from '../../types/orders';
import { IMinimalTeam } from '../../types/teams';
import { UserRole } from '../../types/users';
import S from './styles';

interface IProps {
  visibility: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  teams: IMinimalTeam[];
  filters: [IFilterState, React.Dispatch<React.SetStateAction<IFilterState>>];
  isExporting: boolean;
  activeFilterCount: number;
  onApplyFilters(): void;
  onClearFilters(): void;
  onExportOrders(): void;
}

interface IDateFilterProps {
  filters: IFilterState;
  setFilters: React.Dispatch<React.SetStateAction<IFilterState>>;
}

const dateFields = [
  { label: 'Created', value: 'createdTime' },
  { label: 'Due', value: 'dueDate' },
  { label: 'Received', value: 'receivedDate' },
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function Filters(props: IProps) {
  const { user } = useAuth();
  const [visible, toggleVisibility] = props.visibility;
  const [filters, setFilters] = props.filters;
  const showExportButton = user?.role === UserRole.SUPER_ADMIN;

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const key = event.target.name;
    const value = event.target.value;
    setFilters({
      ...filters,
      ...(key === 'orderTeams.name' && !value && { 'orderTeams.status': [] }),
      [key]: value,
    });
  }

  function getTeamOptions() {
    return props.teams.map((team) => team.name);
  }

  return (
    <Box sx={{ mb: 1 }}>
      <Box display="flex" sx={{ '& > div:not(:last-child)': { mr: 1 } }}>
        <div>
          <Badge
            sx={{ '.MuiBadge-badge': { color: '#ffffff' } }}
            badgeContent={props.activeFilterCount}
            color="primary"
          >
            <Button
              color="primary"
              startIcon={<FilterListIcon />}
              onClick={() => toggleVisibility(!visible)}
            >
              {`${visible ? 'Hide' : 'Show'} Filters`}
            </Button>
          </Badge>
        </div>
        {showExportButton && (
          <div>
            <LoadingButton
              color="primary"
              loadingPosition="start"
              startIcon={<FileDownloadIcon />}
              onClick={props.onExportOrders}
              loading={props.isExporting}
            >
              Export
            </LoadingButton>
          </div>
        )}
      </Box>
      <Collapse in={visible}>
        <Card elevation={0} sx={{ backgroundColor: 'transparent' }}>
          <CardContent sx={{ padding: 0 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > div:not(:last-child)': { mr: 1 } }}>
              <TextField
                sx={{ mt: 1 }}
                variant="outlined"
                size="small"
                label="Order Id"
                name="orderId"
                value={filters.orderId}
                onChange={handleChange}
              />
              <TextField
                sx={{ mt: 1 }}
                variant="outlined"
                size="small"
                label="Description"
                name="description"
                value={filters.description}
                onChange={handleChange}
              />
              <TextField
                sx={{ mt: 1 }}
                variant="outlined"
                size="small"
                label="Client"
                name="client.name"
                value={filters['client.name']}
                onChange={handleChange}
              />
              <Autocomplete
                multiple
                size="small"
                sx={{ mt: 1, width: 230 }}
                limitTags={2}
                options={getOrderStatusOptions()}
                value={filters.status}
                getOptionLabel={(option) => option.label}
                disableCloseOnSelect
                onChange={(_, newValue) => setFilters({ ...filters, status: newValue })}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      color="primary"
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Status" />
                )}
              />
              <TextField
                select
                sx={{ mt: 1, width: 230 }}
                variant="outlined"
                name="orderTeams.name"
                label="Team"
                size="small"
                value={filters['orderTeams.name']}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <i>None</i>
                </MenuItem>
                {getTeamOptions().map((team, i) => (
                  <MenuItem key={i} value={team}>
                    {team}
                  </MenuItem>
                ))}
              </TextField>
              <Autocomplete
                multiple
                size="small"
                sx={{ mt: 1, width: 230 }}
                limitTags={2}
                options={getTeamStatusOptions()}
                value={filters['orderTeams.status']}
                getOptionLabel={(option) => option.label}
                disableCloseOnSelect
                onChange={(_, newValue) =>
                  setFilters({ ...filters, 'orderTeams.status': newValue })
                }
                isOptionEqualToValue={(option, value) => option.value === value.value}
                disabled={!filters['orderTeams.name']}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      color="primary"
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Team Status" />
                )}
              />
              <DateFilter filters={filters} setFilters={setFilters} />
            </Box>
          </CardContent>
          <CardActions sx={{ pl: 0, pr: 0, justifyContent: 'end' }}>
            <Button variant="contained" color="primary" size="small" onClick={props.onApplyFilters}>
              Apply
            </Button>
            <Button variant="outlined" size="small" onClick={props.onClearFilters}>
              Clear
            </Button>
          </CardActions>
        </Card>
      </Collapse>
    </Box>
  );
}

function DateFilter(props: IDateFilterProps) {
  const { filters, setFilters } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChange(key: string, value: string) {
    const date = { ...filters.date, [key]: value };
    setFilters({ ...filters, date });
  }

  function getLabel() {
    const { key, from, to } = filters.date;
    if (key && from && to) {
      const field = dateFields.find((f) => f.value === key);
      if (!field) return 'Any Date';
      return `${field.label} Date`;
    }
    return 'Any Date';
  }

  const open = Boolean(anchorEl);
  const id = open ? 'date-filter-popover' : undefined;

  return (
    <S.DateFilter>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        size="large"
        className="filter-button"
        fullWidth
      >
        {getLabel()}
      </Button>
      <S.DateFilterPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ paper: 'popover' }}
      >
        <div className="container">
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="date-field-label">Field</InputLabel>
            <Select
              label="Field"
              labelId="date-field-label"
              value={filters.date.key}
              onChange={(event) => handleChange('key', event.target.value as string)}
              MenuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dateFields.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="date-picker-container">
            <div>
              <TextField
                variant="outlined"
                size="small"
                label="From"
                type="date"
                value={filters.date.from}
                onChange={(event) => handleChange('from', event.target.value)}
                InputProps={{ classes: { root: 'date-picker' } }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div>
              <TextField
                variant="outlined"
                size="small"
                label="To"
                type="date"
                value={filters.date.to}
                onChange={(event) => handleChange('to', event.target.value)}
                InputProps={{ classes: { root: 'date-picker' } }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
        </div>
      </S.DateFilterPopover>
    </S.DateFilter>
  );
}

export default Filters;
