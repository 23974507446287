import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Routes from './routes/Routes';

import Snackbar from './components/shared/Snackbar';

import { ProvideAuth } from './hooks/useAuth';
import { ProvideSnackbar } from './hooks/useSnackbar';

import 'react-datepicker/dist/react-datepicker.css';

dayjs.extend(relativeTime);

function App() {
  return (
    <ProvideAuth>
      <ProvideSnackbar>
        <Snackbar />
        <Routes />
      </ProvideSnackbar>
    </ProvideAuth>
  );
}

export default App;
