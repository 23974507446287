import { AxiosError } from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { GridRowParams, GridRowsProp } from '@mui/x-data-grid';

import Filters from './Filters';
import UsersTable from './UsersTable';
import UserDetails from './UserDetails';

import SS from '../shared/styles';
import useSnackbar from '../../hooks/useSnackbar';
import { IUsersFilterState } from '../../types/users';
import { IFilter, SearchParams } from '../../types/common';
import { getUsers } from '../../requests/users';

interface IUsersState {
  content: GridRowsProp;
  totalElements: number;
}

const initialFilters: IUsersFilterState = {
  firstName: '',
  lastName: '',
  email: '',
  empId: '',
};

function Users() {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const filtersVisibility = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IUsersFilterState>(initialFilters);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [],
  });
  const [users, setUsers] = useState<IUsersState>({ content: [], totalElements: 0 });

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  function fetchUsers() {
    setLoading(true);
    getUsers({ page, pageSize, ...(filterFields?.length && { filterFields }) })
      .then((res) => {
        setLoading(false);
        setUsers({ content: res.data.content, totalElements: res.data.totalElements });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }

  function handleApplyFilters() {
    const newFilters: IFilter[] = [];
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterValue) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'like',
          value: filterValue,
        });
      }
    });
    setUsers({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }

  function handleClearFilters() {
    setFilters(initialFilters);
    setUsers({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: [] });
  }

  function handleRowClick(params: GridRowParams) {
    navigate(`/users/${window.btoa(params.row._id)}`);
  }

  function getMode() {
    return params.id === 'create' ? 'create' : 'update';
  }

  function getId() {
    if (getMode() === 'create') {
      return '';
    }

    const id = params.id || '';
    try {
      return window.atob(id);
    } catch (error) {
      return id;
    }
  }

  return !params.id ? (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <SS.PageTitle variant="h6">Users</SS.PageTitle>
        <Button component={Link} to="/users/create" color="primary" startIcon={<AddIcon />}>
          Create
        </Button>
      </Box>
      <Filters
        visibility={filtersVisibility}
        filters={[filters, setFilters]}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
      />
      <UsersTable
        page={page}
        pageSize={pageSize}
        rows={users.content}
        totalElements={users.totalElements}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onRowClick={handleRowClick}
      />
    </div>
  ) : (
    <UserDetails mode={getMode()} id={getId()} fetchUsers={fetchUsers} />
  );
}

export default Users;
