import { AxiosError } from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { GridRowParams, GridRowsProp } from '@mui/x-data-grid';

import Filters from './Filters';
import ExternalUsersTable from './ExternalUsersTable';
import ExternalUserDetails from './ExternalUserDetails';
import ConfirmActionDialog from '../shared/ConfirmActionDialog';

import SS from '../shared/styles';
import useSnackbar from '../../hooks/useSnackbar';
import { IExternalUsersFilterState } from '../../types/users';
import { IFilter, SearchParams } from '../../types/common';
import { getExternalUsers, deleteExternalUser } from '../../requests/users';

interface IExternalUsersState {
  content: GridRowsProp;
  totalElements: number;
}

const initialFilters: IExternalUsersFilterState = {
  name: '',
  email: '',
};

const initialDialogData = {
  id: '',
  name: '',
  open: false,
  isLoading: false,
  callback: () => {},
};

function ExternalUsers() {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const filtersVisibility = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogData, setDialogData] = useState(initialDialogData);
  const [filters, setFilters] = useState<IExternalUsersFilterState>(initialFilters);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [],
  });
  const [externalUsers, setExternalUsers] = useState<IExternalUsersState>({
    content: [],
    totalElements: 0,
  });

  useEffect(() => {
    fetchExternalUsers();
    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  function fetchExternalUsers() {
    setLoading(true);
    getExternalUsers({ page, pageSize, ...(filterFields?.length && { filterFields }) })
      .then((res) => {
        setLoading(false);
        setExternalUsers({ content: res.data.content, totalElements: res.data.totalElements });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }

  function handleApplyFilters() {
    const newFilters: IFilter[] = [];
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterValue) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'like',
          value: filterValue,
        });
      }
    });
    setExternalUsers({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }

  function handleClearFilters() {
    setFilters(initialFilters);
    setExternalUsers({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: [] });
  }

  function handleDialogOpen(id: string, name: string, callback: () => void) {
    setDialogData({ id, name, callback, open: true, isLoading: false });
  }

  function handleDialogClose() {
    setDialogData(initialDialogData);
  }

  function handleDeleteExternalUser() {
    const { id, callback } = dialogData;
    if (!id) {
      showSnackbar({ severity: 'error', message: 'Unable to delete external user' });
      return;
    }

    setDialogData({ ...dialogData, isLoading: true });
    deleteExternalUser({ _id: id })
      .then(() => {
        showSnackbar({
          severity: 'success',
          message: 'External user deleted successfully',
        });
        callback();
        setDialogData(initialDialogData);
      })
      .catch((error: AxiosError) => {
        setDialogData({ ...dialogData, isLoading: false });
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleRowClick(params: GridRowParams) {
    navigate(`/external-users/${window.btoa(params.row._id)}`);
  }

  function getMode() {
    return params.id === 'create' ? 'create' : 'update';
  }

  function getId() {
    if (getMode() === 'create') {
      return '';
    }

    const id = params.id || '';
    try {
      return window.atob(id);
    } catch (error) {
      return id;
    }
  }

  return (
    <>
      {!params.id ? (
        <div>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
          >
            <SS.PageTitle variant="h6">External Users</SS.PageTitle>
            <Button
              component={Link}
              to="/external-users/create"
              color="primary"
              startIcon={<AddIcon />}
            >
              Create
            </Button>
          </Box>
          <Filters
            visibility={filtersVisibility}
            filters={[filters, setFilters]}
            onApplyFilters={handleApplyFilters}
            onClearFilters={handleClearFilters}
          />
          <ExternalUsersTable
            page={page}
            pageSize={pageSize}
            rows={externalUsers.content}
            totalElements={externalUsers.totalElements}
            loading={loading}
            fetchExternalUsers={fetchExternalUsers}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowClick={handleRowClick}
            onDeleteClick={handleDialogOpen}
          />
        </div>
      ) : (
        <ExternalUserDetails
          mode={getMode()}
          id={getId()}
          fetchExternalUsers={fetchExternalUsers}
          onDeleteClick={handleDialogOpen}
        />
      )}
      <ConfirmActionDialog
        open={dialogData.open}
        isLoading={dialogData.isLoading}
        title="Delete External User"
        description={`Are you sure you want to delete this external user "${dialogData.name}"?`}
        onClose={handleDialogClose}
        onConfirm={handleDeleteExternalUser}
      />
    </>
  );
}

export default ExternalUsers;
