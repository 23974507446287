import * as yup from 'yup';
import { useFormik } from 'formik';
import { AxiosError } from 'axios';
import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';

import SS from '../shared/styles';
import PageTitleWithBack from '../shared/PageTitleWithBack';
import ConfirmActionDialog from '../shared/ConfirmActionDialog';

import { REGEX } from '../../constants';
import { IUser } from '../../types/users';
import useSnackbar from '../../hooks/useSnackbar';
import { createUser, deleteUser, getUserDetails, updateUser } from '../../requests/users';
import { getRoleOptions, getZoneOptions } from '../../utils/utils';

interface IProps {
  mode: 'create' | 'update';
  id: string;
  fetchUsers(): void;
}

function getValidationSchema(mode: 'create' | 'update') {
  return yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Enter a valid email').required('Email is required'),
    empId: yup.string().required('Employee Id is required'),
    role: yup.string().required('Role is required'),
    zone: yup.string().required('Zone is required'),
    phoneNo: yup.string(),
    password:
      mode === 'create'
        ? yup.string().matches(REGEX.PASSWORD, { message: ' ' }).required('Password is required')
        : yup.string().matches(REGEX.PASSWORD),
  });
}

function UserDetails({ mode, id, fetchUsers }: IProps) {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [userDetails, setUserDetails] = useState<IUser | null>(null);
  const [dialogData, setDialogData] = useState({ open: false, isLoading: false });
  const formik = useFormik<Partial<IUser>>({
    validationSchema: getValidationSchema(mode),
    initialValues: getInitialValues(null),
    onSubmit: handleSubmit,
  });
  const isCreateMode = mode === 'create';

  useEffect(() => {
    if (!isCreateMode && id) {
      fetchUserDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, id]);

  function getInitialValues(user: IUser | null): Partial<IUser> {
    return {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      empId: user?.empId || '',
      role: user?.role || ('' as any),
      zone: user?.zone || ('' as any),
      phoneNo: user?.phoneNo || '',
      password: '',
    };
  }

  function fetchUserDetails(id: string) {
    setIsLoading(true);
    getUserDetails({ id })
      .then((res) => {
        setIsLoading(false);
        setUserDetails(res.data);
        formik.resetForm({ values: getInitialValues(res.data) });
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleSubmit({ password, ...data }: Partial<IUser>) {
    if (!isCreateMode && !userDetails?.status) {
      return;
    }

    setIsUpdating(true);
    const request = isCreateMode ? createUser : updateUser;
    const requestBody: Partial<IUser> = { ...data, ...(password && { password }) };
    request(requestBody)
      .then((res) => {
        setIsUpdating(false);
        setUserDetails(res.data);
        showSnackbar({ severity: 'success', message: `User ${mode}d successfully` });
        if (isCreateMode) {
          navigate(`/users/${window.btoa(res.data._id)}`, { replace: true });
        }
        fetchUsers();
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        setIsUpdating(false);
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleDialogClose() {
    setDialogData({ open: false, isLoading: false });
  }

  function handleDialogOpen() {
    setDialogData({ open: true, isLoading: false });
  }

  function handleDeleteUser() {
    if (!userDetails) {
      showSnackbar({ severity: 'error', message: 'Unable to delete user' });
      return;
    }

    setDialogData({ ...dialogData, isLoading: true });
    deleteUser({ email: userDetails.email })
      .then((res) => {
        setDialogData({ open: false, isLoading: false });
        showSnackbar({
          severity: 'success',
          message: 'User deleted successfully',
        });
        fetchUsers();
        navigate('/users', { replace: true });
      })
      .catch((error: AxiosError) => {
        setDialogData({ ...dialogData, isLoading: false });
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleBackClick() {
    navigate('/users', { replace: true });
  }

  return (
    <Container maxWidth="md">
      <PageTitleWithBack title={`${capitalize(mode)} User`} onBackClick={handleBackClick} />
      <SS.BorderedBox>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  label="First Name"
                  fullWidth
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Email"
                  fullWidth
                  InputProps={{ readOnly: !isCreateMode }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="empId"
                  label="Employee Id"
                  fullWidth
                  value={formik.values.empId}
                  onChange={formik.handleChange}
                  error={formik.touched.empId && Boolean(formik.errors.empId)}
                  helperText={formik.touched.empId && formik.errors.empId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name="role"
                  label="Role"
                  fullWidth
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  helperText={formik.touched.role && formik.errors.role}
                >
                  {getRoleOptions().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name="zone"
                  label="Zone"
                  fullWidth
                  value={formik.values.zone}
                  onChange={formik.handleChange}
                  error={formik.touched.zone && Boolean(formik.errors.zone)}
                  helperText={formik.touched.zone && formik.errors.zone}
                >
                  {getZoneOptions().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="phoneNo"
                  label="Phone Number"
                  fullWidth
                  value={formik.values.phoneNo}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={2} columnSpacing={1} mt={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  name="password"
                  autoComplete="off"
                  label={isCreateMode ? 'Password' : 'New Password'}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={
                    formik.touched.password && Boolean(formik.errors.password?.trim())
                      ? formik.errors.password
                      : 'Use 8 to 32 characters, one digit, one symbol (!@#$%^&amp;*), one lowercase and uppercase letter'
                  }
                />
              </Grid>
            </Grid>
            {(isCreateMode || userDetails?.status) && (
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                loading={isUpdating}
              >
                {isCreateMode ? 'Create User' : 'Save Changes'}
              </LoadingButton>
            )}
          </form>
        )}
        {!isCreateMode && userDetails && (
          <Box sx={{ mt: 4 }}>
            <SS.DeleteTitle variant="h6">Delete Account</SS.DeleteTitle>
            <Button
              color="error"
              variant="contained"
              onClick={handleDialogOpen}
              startIcon={<DeleteIcon />}
            >
              Delete Account
            </Button>
          </Box>
        )}
      </SS.BorderedBox>
      <ConfirmActionDialog
        open={dialogData.open}
        isLoading={dialogData.isLoading}
        title="Delete Account"
        description={`Are you sure you want to delete this account "${userDetails?.email}"?`}
        onClose={handleDialogClose}
        onConfirm={handleDeleteUser}
      />
    </Container>
  );
}

export default UserDetails;
