import { useState } from 'react';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import S from './styles';
import { REGEX } from '../../constants';
import { resetPassword } from '../../requests/auth';
import useSnackbar from '../../hooks/useSnackbar';

const validationSchema = yup.object({
  password: yup.string().matches(REGEX.PASSWORD, { message: ' ' }).required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], "Passwords don't match")
    .required('Confirm password is required'),
});

function ResetPassword() {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams<{ token: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isResetSuccess, setIsResetSuccess] = useState(false);
  const formik = useFormik({
    initialValues: { password: '', confirmPassword: '' },
    validationSchema: validationSchema,
    onSubmit: async ({ confirmPassword }) => {
      setIsLoading(true);
      try {
        await resetPassword({ password: confirmPassword, token: params.token as string });
        setIsLoading(false);
        setIsResetSuccess(true);
      } catch (error) {
        const errorMessage =
          ((error as AxiosError)?.response?.data as any)?.message ||
          'An error occurred. Please try again.';
        setIsLoading(false);
        showSnackbar({ severity: 'error', message: errorMessage });
      }
    },
  });

  function handleNavigateToLogin() {
    navigate('/signin', { replace: true });
  }

  return (
    <S.ResetPasswordContainer>
      <Container maxWidth="sm">
        {!isResetSuccess ? (
          <>
            <S.Title variant="h5">Reset Password</S.Title>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                variant="outlined"
                name="password"
                autoComplete="off"
                label="New Password"
                sx={{ mt: 1, mb: 1 }}
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText="Use 8 to 32 characters, one digit, one symbol (!@#$%^&amp;*), one lowercase and uppercase letter"
              />
              <TextField
                variant="outlined"
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                sx={{ mt: 1, mb: 1 }}
                fullWidth
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              />
              <LoadingButton
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                loading={isLoading}
                className="submit-button"
              >
                Submit
              </LoadingButton>
            </form>
          </>
        ) : (
          <>
            <S.Title variant="h5">Password reset successful</S.Title>
            <p className="description">You can now login to your account with your new password.</p>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={handleNavigateToLogin}
              className="proceed-to-login-button"
            >
              Proceed to Login
            </Button>
          </>
        )}
      </Container>
    </S.ResetPasswordContainer>
  );
}

export default ResetPassword;
