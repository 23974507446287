import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import CategoryIcon from '@mui/icons-material/Category';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ReceiptIcon from '@mui/icons-material/Receipt';

import { UserRole } from '../../types/users';
import { ROUTE_ROLE_MAP } from '../../constants';

interface INavItems {
  title: string;
  data: {
    path: string;
    title: string;
    isActive?(pathname: string): boolean;
    icon: JSX.Element;
    roles?: UserRole[];
  }[];
}

export const ADMIN: INavItems[] = [
  {
    title: '',
    data: [
      {
        path: '/',
        title: 'Dashboard',
        icon: <DashboardIcon />,
      },
      {
        path: '/users',
        isActive(pathname: string) {
          return pathname.includes('users') && !pathname.includes('external-users');
        },
        title: 'Users',
        icon: <PeopleIcon />,
        roles: ROUTE_ROLE_MAP.users,
      },
      {
        path: '/external-users',
        isActive(pathname: string) {
          return pathname.includes('external-users');
        },
        title: 'External Users',
        icon: <LogoutIcon />,
        roles: ROUTE_ROLE_MAP['external-users'],
      },
      {
        path: '/clients',
        isActive(pathname: string) {
          return pathname.includes('clients');
        },
        title: 'Clients',
        icon: <PersonIcon />,
        roles: ROUTE_ROLE_MAP.clients,
      },
      {
        path: '/teams',
        isActive(pathname: string) {
          return pathname.includes('teams');
        },
        title: 'Teams',
        icon: <GroupsIcon />,
        roles: ROUTE_ROLE_MAP.teams,
      },
      {
        path: '/categories',
        isActive(pathname: string) {
          return pathname.includes('categories');
        },
        title: 'Categories',
        icon: <CategoryIcon />,
        roles: ROUTE_ROLE_MAP.categories,
      },
      {
        path: '/orders',
        isActive(pathname: string) {
          return pathname.includes('orders');
        },
        title: 'Orders',
        icon: <AssignmentTurnedInIcon />,
      },
      {
        path: '/invoices',
        isActive(pathname: string) {
          return pathname.includes('invoices');
        },
        title: 'Invoices',
        icon: <ReceiptIcon />,
        roles: ROUTE_ROLE_MAP.invoices,
      },
    ],
  },
];
