import axios from '../axios';
import { PageResponse, SearchParams } from '../types/common';

import { IMinimalTeam, ITeam } from '../types/teams';

export function getTeamDetails(id: string) {
  return axios.get<ITeam>('/team/detail', { params: { id } });
}

export function getTeams(requestBody: SearchParams) {
  return axios.post<PageResponse<ITeam>>('/team/find', requestBody);
}

export function getTeamsForDropdown() {
  return axios.get<IMinimalTeam[]>('/team/dropDown');
}

export function createTeam(requestBody: Partial<ITeam>) {
  return axios.post<ITeam>('/team/create', requestBody);
}

export function updateTeam(requestBody: Partial<ITeam>) {
  return axios.post<ITeam>('/team/update', requestBody);
}

export function deleteTeam(requestBody: { _id: string }) {
  return axios.post<ITeam>('/team/delete', requestBody);
}

export function getOrderTeams(orderId: string) {
  return axios.get<IMinimalTeam[]>('/order/team/dropdown', { params: { orderId } });
}
