import { IAttachment } from './common';

export enum TaskStatus {
  OPEN = 'OPEN',
  REVIEW = 'REVIEW',
  RE_OPEN = 'RE_OPEN',
  CLOSED = 'CLOSED',
}

export enum TaskType {
  IN_HOUSE = 'IN_HOUSE',
  OUTSOURCE = 'OUTSOURCE',
}

export interface ITask {
  id: string;
  orderId: string;
  orderTeamId: string;
  taskId: string;
  assignedToId: string;
  assignedToName: string;
  name: string;
  description: string;
  status: TaskStatus;
  type: TaskType;
  attachments: IAttachment[];
}
