import { AxiosError } from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { GridRowParams, GridRowsProp } from '@mui/x-data-grid';

import Filters from './Filters';
import ClientsTable from './ClientsTable';
import ClientDetails from './ClientDetails';
import ImportClients from './ImportClients';
import ConfirmActionDialog from '../shared/ConfirmActionDialog';

import SS from '../shared/styles';
import useSnackbar from '../../hooks/useSnackbar';
import { IFilter, SearchParams } from '../../types/common';
import { IFilterState } from '../../types/clients';
import { deleteClient, getClients } from '../../requests/clients';

interface IClientsState {
  content: GridRowsProp;
  totalElements: number;
}

const initialFilters: IFilterState = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
};

const initialDialogData = {
  id: '',
  name: '',
  open: false,
  isLoading: false,
  callback: () => {},
};

function Clients() {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const filtersVisibility = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogData, setDialogData] = useState(initialDialogData);
  const [filters, setFilters] = useState<IFilterState>(initialFilters);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [],
  });
  const [clients, setClients] = useState<IClientsState>({ content: [], totalElements: 0 });
  const [showImportClientsDialog, setShowImportClientsDialog] = useState(false);

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  function fetchClients() {
    setLoading(true);
    getClients({ page, pageSize, ...(filterFields?.length && { filterFields }) })
      .then((res) => {
        setLoading(false);
        setClients({ content: res.data.content, totalElements: res.data.totalElements });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }

  function handleApplyFilters() {
    const newFilters: IFilter[] = [];
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterValue) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'like',
          value: filterValue,
        });
      }
    });
    setClients({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }

  function handleClearFilters() {
    setFilters(initialFilters);
    setClients({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: [] });
  }

  function handleDialogOpen(id: string, name: string, callback: () => void) {
    setDialogData({ id, name, callback, open: true, isLoading: false });
  }

  function handleDialogClose() {
    setDialogData(initialDialogData);
  }

  function handleDeleteClient() {
    const { id, callback } = dialogData;
    if (!id) {
      showSnackbar({ severity: 'error', message: 'Unable to delete client' });
      return;
    }

    setDialogData({ ...dialogData, isLoading: true });
    deleteClient({ _id: id })
      .then(() => {
        showSnackbar({
          severity: 'success',
          message: 'Client deleted successfully',
        });
        callback();
        setDialogData(initialDialogData);
      })
      .catch((error: AxiosError) => {
        setDialogData({ ...dialogData, isLoading: false });
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleRowClick(params: GridRowParams) {
    navigate(`/clients/${window.btoa(params.row._id)}`);
  }

  function handleImportClientDialogOpen() {
    setShowImportClientsDialog(true);
  }

  function handleImportClientDialogClose() {
    setShowImportClientsDialog(false);
  }

  function getMode() {
    return params.id === 'create' ? 'create' : 'update';
  }

  function getId() {
    if (getMode() === 'create') {
      return '';
    }

    const id = params.id || '';
    try {
      return window.atob(id);
    } catch (error) {
      return id;
    }
  }

  return (
    <>
      {!params.id ? (
        <div>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
          >
            <SS.PageTitle variant="h6">Clients</SS.PageTitle>
            <Box display="flex">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleImportClientDialogOpen}
                sx={{ mr: 1 }}
              >
                Import
              </Button>
              <Button
                variant="outlined"
                component={Link}
                to="/clients/create"
                color="primary"
                startIcon={<AddIcon />}
              >
                Create
              </Button>
            </Box>
          </Box>
          <Filters
            visibility={filtersVisibility}
            filters={[filters, setFilters]}
            onApplyFilters={handleApplyFilters}
            onClearFilters={handleClearFilters}
          />
          <ClientsTable
            page={page}
            pageSize={pageSize}
            rows={clients.content}
            totalElements={clients.totalElements}
            loading={loading}
            fetchClients={fetchClients}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowClick={handleRowClick}
            onDeleteClick={handleDialogOpen}
          />
        </div>
      ) : (
        <ClientDetails
          mode={getMode()}
          id={getId()}
          fetchClients={fetchClients}
          onDeleteClick={handleDialogOpen}
        />
      )}
      <ConfirmActionDialog
        open={dialogData.open}
        isLoading={dialogData.isLoading}
        title="Delete Client"
        description={`Are you sure you want to delete this client "${dialogData.name}"?`}
        onClose={handleDialogClose}
        onConfirm={handleDeleteClient}
      />
      <ImportClients
        open={showImportClientsDialog}
        handleDialogClose={handleImportClientDialogClose}
        fetchClients={fetchClients}
      />
    </>
  );
}

export default Clients;
