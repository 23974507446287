import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import S from './styles';

interface IProps {
  title: string;
  disableGutters?: boolean;
  onBackClick(): void;
}

function PageTitleWithBack({ title, disableGutters = true, onBackClick }: IProps) {
  return (
    <ListItem disableGutters={disableGutters}>
      <ListItemIcon>
        <IconButton onClick={onBackClick} size="small">
          <ArrowBackIcon htmlColor="#577496" />
        </IconButton>
      </ListItemIcon>
      <ListItemText disableTypography>
        <S.PageTitle variant="h6">{title}</S.PageTitle>
      </ListItemText>
    </ListItem>
  );
}

export default PageTitleWithBack;
