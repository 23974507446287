import axios from '../axios';
import { IClient, IMinimalClient } from '../types/clients';
import { PageResponse, SearchParams } from '../types/common';

export function getClients(requestBody: SearchParams) {
  return axios.post<PageResponse<IClient>>('/client/find', requestBody);
}

export function getClientDetails(id: string) {
  return axios.get<IClient>('/client/detail', { params: { id } });
}

export function createClient(requestBody: Partial<IClient>) {
  return axios.post<IClient>('/client/create', requestBody);
}

export function updateClient(requestBody: Partial<IClient>) {
  return axios.post<IClient>('/client/update', requestBody);
}

export function deleteClient(requestBody: { _id: string }) {
  return axios.post<IClient>('/client/delete', requestBody);
}

export function getClientsForDropdown() {
  return axios.get<IMinimalClient[]>('/client/dropDown');
}

export function importClients(data: FormData) {
  return axios.post('/client/upload', data, { responseType: 'blob' });
}
