import { AxiosError } from 'axios';
import { Client } from '@stomp/stompjs';
import { useEffect, useState } from 'react';
import { Navigate, Routes, Route, useLocation, useNavigate, Outlet } from 'react-router-dom';

import Layout from '../components/Layout/Layout';
import Teams from '../components/Teams/Teams';
import Users from '../components/Users/Users';
import Orders from '../components/Orders/Orders';
import Clients from '../components/Clients/Clients';
import Profile from '../components/Profile/Profile';
import Invoices from '../components/Invoices/Invoices';
import Dashboard from '../components/Dashboard/Dashboard';
import Categories from '../components/Categories/Categories';
import ExternalUsers from '../components/ExternalUsers/ExternalUsers';
import Reports from '../components/Reports/Reports';
import StartUpLoader from '../components/shared/StartUpLoader';

import useAuth from '../hooks/useAuth';
import useSnackbar from '../hooks/useSnackbar';

import localStorage from '../utils/local-storage';
import { getUserDetails } from '../requests/users';
import { ROUTE_ROLE_MAP } from '../constants';
import { UserRole } from '../types/users';
import { INotification } from '../types/common';

function PrivateRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const { showNotification } = useSnackbar();
  const { user, isAuthenticated, setAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();
  let stompClient: Client;

  function connectWs() {
    try {
      const accessToken = localStorage.getAccessToken();
      stompClient = new Client({
        brokerURL: `${process.env.REACT_APP_WS_URL}/our-websocket`,
        connectHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
      });

      stompClient.onConnect = function (frame) {
        stompClient.subscribe(`/user/notify/message`, (message) => {
          try {
            showNotification(JSON.parse(message.body) as INotification);
          } catch (error) {}
        });
      };

      stompClient.activate();
    } catch (error) {
      console.log(error, 'Error while creating ws client');
    }
  }

  function fetchUserDetails() {
    setIsLoading(true);
    getUserDetails()
      .then((res) => {
        setIsLoading(false);
        setAuth({ isAuthenticated: true, user: res.data });
        setTimeout(() => connectWs(), 1000);
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        setError(error.response?.data || 'Request Failure');
      });
  }

  useEffect(() => {
    if (!localStorage.hasAccessToken()) {
      const pathname = location.pathname;
      const isRootPath = pathname === '/';
      return navigate(`/signin${!isRootPath ? `?redirectUrl=${pathname}` : ''}`, { replace: true });
    }
    if (
      !['/signin', '/signup', '/password/reset'].includes(location.pathname) &&
      !isAuthenticated
    ) {
      if (!isLoading) {
        fetchUserDetails();
      }
    }

    return () => {
      if (stompClient) {
        stompClient.deactivate();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthenticated]);

  if (isLoading) {
    return <StartUpLoader />;
  } else if (error) {
    return <div>An error occurred. Please reload the page to try again.</div>;
  }

  if (!isAuthenticated) {
    return null;
  }
  if (!user) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="profile" element={<Profile />} />
        <Route path="users" element={<Authorization roles={ROUTE_ROLE_MAP.users} />}>
          <Route index element={<Users />} />
          <Route path=":id" element={<Users />} />
        </Route>
        <Route
          path="external-users"
          element={<Authorization roles={ROUTE_ROLE_MAP['external-users']} />}
        >
          <Route index element={<ExternalUsers />} />
          <Route path=":id" element={<ExternalUsers />} />
        </Route>
        <Route path="clients" element={<Authorization roles={ROUTE_ROLE_MAP.clients} />}>
          <Route index element={<Clients />} />
          <Route path=":id" element={<Clients />} />
        </Route>
        <Route path="teams" element={<Authorization roles={ROUTE_ROLE_MAP.teams} />}>
          <Route index element={<Teams />} />
          <Route path=":id" element={<Teams />} />
        </Route>
        <Route path="categories" element={<Authorization roles={ROUTE_ROLE_MAP.categories} />}>
          <Route index element={<Categories />} />
          <Route path=":id" element={<Categories />} />
        </Route>
        <Route path="orders">
          <Route index element={<Orders />} />
          <Route path=":id" element={<Orders />} />
        </Route>
        <Route path="invoices" element={<Authorization roles={ROUTE_ROLE_MAP.invoices} />}>
          <Route index element={<Invoices />} />
          <Route path=":id" element={<Invoices />} />
        </Route>
        <Route path="reports" element={<Authorization roles={ROUTE_ROLE_MAP.reports} />}>
          <Route path=":reportType" element={<Reports />} />
        </Route>
      </Route>
    </Routes>
  );
}

function Authorization(props: { roles: UserRole[] }) {
  const { user } = useAuth();

  if (!props.roles.includes(user?.role as UserRole)) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}

export default PrivateRoutes;
