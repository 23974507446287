import { AxiosError } from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { GridRowParams, GridRowsProp } from '@mui/x-data-grid';

import Filters from './Filters';
import InvoicesTable from './InvoicesTable';
import InvoiceDetails from './InvoiceDetails';

import SS from '../shared/styles';
import useSnackbar from '../../hooks/useSnackbar';
import { IFilterState } from '../../types/invoices';
import { IFilter, SearchParams } from '../../types/common';
import { getInvoices } from '../../requests/invoices';

interface IClientsState {
  content: GridRowsProp;
  totalElements: number;
}

const initialFilters: IFilterState = {
  invoiceId: '',
  orderId: '',
  teamName: '',
  status: '',
};

function Invoices() {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const filtersVisibility = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IFilterState>(initialFilters);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [],
  });
  const [invoices, setInvoices] = useState<IClientsState>({ content: [], totalElements: 0 });

  useEffect(() => {
    fetchInvoices();
    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  function fetchInvoices() {
    setLoading(true);
    getInvoices({ page, pageSize, ...(filterFields?.length && { filterFields }) })
      .then((res) => {
        setLoading(false);
        setInvoices({ content: res.data.content, totalElements: res.data.totalElements });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }

  function handleApplyFilters() {
    const newFilters: IFilter[] = [];
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterValue) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'like',
          value: filterValue,
        });
      }
    });
    setInvoices({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }

  function handleClearFilters() {
    setFilters(initialFilters);
    setInvoices({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: [] });
  }

  function handleRowClick(params: GridRowParams) {
    navigate(`/invoices/${window.btoa(params.row._id)}`);
  }

  function getMode() {
    return params.id === 'create' ? 'create' : 'update';
  }

  function getId() {
    if (getMode() === 'create') {
      return '';
    }

    const id = params.id || '';
    try {
      return window.atob(id);
    } catch (error) {
      return id;
    }
  }

  return (
    <>
      {!params.id ? (
        <div>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
          >
            <SS.PageTitle variant="h6">Invoices</SS.PageTitle>
            <Button component={Link} to="/invoices/create" color="primary" startIcon={<AddIcon />}>
              Create
            </Button>
          </Box>
          <Filters
            visibility={filtersVisibility}
            filters={[filters, setFilters]}
            onApplyFilters={handleApplyFilters}
            onClearFilters={handleClearFilters}
          />
          <InvoicesTable
            page={page}
            pageSize={pageSize}
            rows={invoices.content}
            totalElements={invoices.totalElements}
            loading={loading}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowClick={handleRowClick}
          />
        </div>
      ) : (
        <InvoiceDetails mode={getMode()} id={getId()} fetchInvoices={fetchInvoices} />
      )}
    </>
  );
}

export default Invoices;
