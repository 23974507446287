import { CancelToken } from 'axios';

import axios from '../axios';
import { IFilter, PageResponse, SearchParams } from '../types/common';
import { ICreateOrder, IOrder, IOrderList, IOrderTeam } from '../types/orders';
import { ITeam } from '../types/teams';

export function getOrders(requestBody: SearchParams, cancelToken?: CancelToken) {
  return axios.post<PageResponse<IOrderList>>('/order/find', requestBody, { cancelToken });
}

export function createOrder(requestBody: Partial<ICreateOrder>) {
  return axios.post<ICreateOrder>('/order/create', requestBody);
}

export function updateOrder(requestBody: Partial<IOrder>) {
  return axios.post<IOrder>('/order/metadata', requestBody);
}

export function getOrder(id: string) {
  return axios.get<IOrder>('/order/detail', { params: { id } });
}

export function markOrderAsComplete(id: string) {
  return axios.put<boolean>('/order/completed', undefined, { params: { orderId: id } });
}

export function getRelatedOrders(requestBody: SearchParams, orderId: string) {
  return axios.post<PageResponse<IOrderList>>('/order/related', requestBody, {
    params: { orderId },
  });
}

export function getOrderTeams(orderId: string) {
  return axios.get<ITeam[]>('/order/team/metadata', { params: { orderId } });
}

export function updateOrderTeam(requestBody: Partial<IOrderTeam>) {
  return axios.post<IOrderTeam>('/order/team', requestBody);
}

export function exportOrders(requestBody: { filterFields?: IFilter[] }) {
  return axios.post('/order/export', requestBody, { responseType: 'blob' });
}

export function deleteOrder(orderId: string) {
  return axios.delete('/order/delete', { params: { orderId } });
}
