import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import FilterListIcon from '@mui/icons-material/FilterList';

import { IFilterState } from '../../types/invoices';
import { getInvoiceStatusOptions } from '../../utils/utils';

interface IProps {
  visibility: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  filters: [IFilterState, React.Dispatch<React.SetStateAction<IFilterState>>];
  onApplyFilters(): void;
  onClearFilters(): void;
}

function Filters(props: IProps) {
  const [visible, toggleVisibility] = props.visibility;
  const [filters, setFilters] = props.filters;

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  }

  return (
    <Box sx={{ mb: 1 }}>
      <Button
        color="primary"
        startIcon={<FilterListIcon />}
        onClick={() => toggleVisibility(!visible)}
      >
        {`${visible ? 'Hide' : 'Show'} Filters`}
      </Button>
      <Collapse in={visible}>
        <Card elevation={0} sx={{ backgroundColor: 'transparent' }}>
          <CardContent sx={{ padding: 0 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > div:not(:last-child)': { mr: 1 } }}>
              <TextField
                sx={{ mt: 1 }}
                variant="outlined"
                size="small"
                label="Invoice Id"
                name="invoiceId"
                value={filters.invoiceId}
                onChange={handleChange}
              />
              <TextField
                sx={{ mt: 1 }}
                variant="outlined"
                size="small"
                label="Order Id"
                name="orderId"
                value={filters.orderId}
                onChange={handleChange}
              />
              <TextField
                sx={{ mt: 1 }}
                variant="outlined"
                size="small"
                label="Team Name"
                name="teamName"
                value={filters.teamName}
                onChange={handleChange}
              />
              <TextField
                select
                sx={{ mt: 1, width: 230 }}
                variant="outlined"
                name="status"
                label="Status"
                size="small"
                value={filters.status}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <i>None</i>
                </MenuItem>
                {getInvoiceStatusOptions().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </CardContent>
          <CardActions sx={{ pl: 0, pr: 0, justifyContent: 'end' }}>
            <Button variant="contained" color="primary" size="small" onClick={props.onApplyFilters}>
              Apply
            </Button>
            <Button variant="outlined" size="small" onClick={props.onClearFilters}>
              Clear
            </Button>
          </CardActions>
        </Card>
      </Collapse>
    </Box>
  );
}

export default Filters;
