import { GridColDef, GridRowParams, GridRowsProp } from '@mui/x-data-grid';

import SS from '../shared/styles';
import { getFormattedRole } from '../../utils/utils';

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  onRowClick(params: GridRowParams): void;
}

const columns: GridColDef[] = [
  { field: 'empId', headerName: 'Employee Id', flex: 1, sortable: false },
  { field: 'firstName', headerName: 'First Name', flex: 1, sortable: false },
  { field: 'lastName', headerName: 'Last Name', flex: 1, sortable: false },
  { field: 'email', headerName: 'Email', flex: 1, sortable: false },
  {
    field: 'role',
    headerName: 'Role',
    flex: 1,
    sortable: false,
    renderCell: (params) => getFormattedRole(params.row.role),
  },
];

function UsersTable(props: IProps) {
  const {
    page,
    pageSize,
    rows,
    totalElements,
    loading,
    onPageChange,
    onPageSizeChange,
    onRowClick,
  } = props;
  return (
    <SS.DataGrid
      rows={rows}
      columns={columns}
      getRowId={(row) => row._id}
      disableColumnFilter
      disableColumnMenu
      disableSelectionOnClick
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      rowCount={totalElements}
      page={page}
      pageSize={pageSize}
      paginationMode="server"
      loading={loading}
      pagination
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onRowClick={onRowClick}
    />
  );
}

export default UsersTable;
