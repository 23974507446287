import * as yup from 'yup';
import isNil from 'lodash/isNil';
import { useFormik } from 'formik';
import { AxiosError } from 'axios';
import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import SS from '../shared/styles';
import PageTitleWithBack from '../shared/PageTitleWithBack';

import useSnackbar from '../../hooks/useSnackbar';
import { IExternalUser } from '../../types/users';
import {
  createExternalUser,
  getExternalUserDetails,
  updateExternalUser,
} from '../../requests/users';
import { getExternalUserPaymentMethodOptions } from '../../utils/utils';
import { REGEX } from '../../constants';
import { PaymentMethod } from '../../types/common';

interface IProps {
  mode: 'create' | 'update';
  id: string;
  fetchExternalUsers(): void;
  onDeleteClick(id: string, name: string, callback: () => void): void;
}

function getValidationSchema() {
  return yup.object({
    name: yup.string().required('Full name is required'),
    email: yup.string().email('Enter a valid email').required('Email is required'),
    panNo: yup.string().matches(REGEX.PAN, 'Invalid PAN Number').required('PAN number is required'),
    numberOfWordsCapable: yup.string().required('Number of words are required'),
    paymentMethod: yup.string().required('Payment method is required'),
    skypeId: yup.string(),
    phoneNo: yup.string(),
    address: yup.object({
      address: yup.string(),
      city: yup.string(),
      country: yup.string(),
      state: yup.string(),
      zipCode: yup.string(),
    }),
  });
}

function ExternalUserDetails({ mode, id, fetchExternalUsers, onDeleteClick }: IProps) {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [externalUserDetails, setExternalUserDetails] = useState<IExternalUser | null>(null);
  const formik = useFormik<Partial<IExternalUser>>({
    validationSchema: getValidationSchema(),
    initialValues: getInitialValues(null),
    onSubmit: handleSubmit,
  });
  const isCreateMode = mode === 'create';

  useEffect(() => {
    if (!isCreateMode && id) {
      fetchExternalUserDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, id]);

  function getInitialValues(user: IExternalUser | null): Partial<IExternalUser> {
    return {
      name: user?.name || '',
      email: user?.email || '',
      panNo: user?.panNo || '',
      numberOfWordsCapable: isNil(user?.numberOfWordsCapable)
        ? ''
        : `${user?.numberOfWordsCapable}`,
      paymentMethod: user?.paymentMethod || ('' as PaymentMethod),
      skypeId: user?.skypeId || '',
      phoneNo: user?.phoneNo || '',
      address: {
        address: user?.address?.address || '',
        city: user?.address?.city || '',
        country: user?.address?.country || '',
        state: user?.address?.state || '',
        zipCode: user?.address?.zipCode || '',
      },
    };
  }

  function fetchExternalUserDetails(id: string) {
    setIsLoading(true);
    getExternalUserDetails(id)
      .then((res) => {
        setIsLoading(false);
        setExternalUserDetails(res.data);
        formik.resetForm({ values: getInitialValues(res.data) });
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleSubmit(data: Partial<IExternalUser>) {
    if (!isCreateMode && !externalUserDetails?.status) {
      return;
    }

    setIsUpdating(true);
    const request = isCreateMode ? createExternalUser : updateExternalUser;
    const requestBody: Partial<IExternalUser> = {
      ...data,
      ...(!isCreateMode && { _id: externalUserDetails?._id }),
    };
    request(requestBody)
      .then((res) => {
        setIsUpdating(false);
        setExternalUserDetails(res.data);
        showSnackbar({ severity: 'success', message: `External user ${mode}d successfully` });
        if (isCreateMode) {
          navigate(`/external-users/${window.btoa(res.data._id)}`, { replace: true });
        }
        fetchExternalUsers();
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || 'An error occurred. Please try again.';
        setIsUpdating(false);
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleDelete() {
    if (externalUserDetails) {
      onDeleteClick(externalUserDetails._id, externalUserDetails.name, () => {
        navigate('/external-users', { replace: true });
        fetchExternalUsers();
      });
    }
  }

  function handleBackClick() {
    navigate('/external-users', { replace: true });
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <PageTitleWithBack
            title={`${capitalize(mode)} External User`}
            onBackClick={handleBackClick}
          />
        </div>
        {!isCreateMode && externalUserDetails?.status && (
          <div>
            <Button
              color="error"
              variant="contained"
              onClick={handleDelete}
              startIcon={<DeleteIcon />}
            >
              Delete External User
            </Button>
          </div>
        )}
      </Box>
      <SS.BorderedBox>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  label="Full Name"
                  fullWidth
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Email"
                  fullWidth
                  InputProps={{ readOnly: !isCreateMode }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="panNo"
                  label="PAN Number"
                  fullWidth
                  value={formik.values.panNo}
                  onChange={formik.handleChange}
                  error={formik.touched.panNo && Boolean(formik.errors.panNo)}
                  helperText={formik.touched.panNo && formik.errors.panNo}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="numberOfWordsCapable"
                  label="Number of Words"
                  fullWidth
                  value={formik.values.numberOfWordsCapable}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value !== '') {
                      if (!REGEX.NUMBERS.test(value)) {
                        return;
                      }
                    }
                    formik.handleChange(event);
                  }}
                  error={
                    formik.touched.numberOfWordsCapable &&
                    Boolean(formik.errors.numberOfWordsCapable)
                  }
                  helperText={
                    formik.touched.numberOfWordsCapable && formik.errors.numberOfWordsCapable
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name="paymentMethod"
                  label="Payment Method"
                  fullWidth
                  value={formik.values.paymentMethod}
                  onChange={formik.handleChange}
                  error={formik.touched.paymentMethod && Boolean(formik.errors.paymentMethod)}
                  helperText={formik.touched.paymentMethod && formik.errors.paymentMethod}
                >
                  {getExternalUserPaymentMethodOptions().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="phoneNo"
                  label="Phone Number"
                  fullWidth
                  value={formik.values.phoneNo}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="skypeId"
                  label="Skype ID"
                  fullWidth
                  value={formik.values.skypeId}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
            <SS.PageTitle variant="body1" mt={3}>
              Address
            </SS.PageTitle>
            <Grid container rowSpacing={2} columnSpacing={1} mt={0.5}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="address.address"
                  label="Address"
                  fullWidth
                  value={formik.values.address?.address}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="address.city"
                  label="City"
                  fullWidth
                  value={formik.values.address?.city}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="address.state"
                  label="State"
                  fullWidth
                  value={formik.values.address?.state}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="address.country"
                  label="Country"
                  fullWidth
                  value={formik.values.address?.country}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="address.zipCode"
                  label="Zip Code"
                  fullWidth
                  value={formik.values.address?.zipCode}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
            {(isCreateMode || externalUserDetails?.status) && (
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                loading={isUpdating}
              >
                {isCreateMode ? 'Create External User' : 'Save Changes'}
              </LoadingButton>
            )}
          </form>
        )}
      </SS.BorderedBox>
    </Container>
  );
}

export default ExternalUserDetails;
