import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import LinearProgress from '@mui/material/LinearProgress';
import SendIcon from '@mui/icons-material/Send';

import S from './styles';
import useAuth from '../../hooks/useAuth';
import useSnackbar from '../../hooks/useSnackbar';
import { IMessage, MessageType } from '../../types/common';
import { getMessages, sendMessage } from '../../requests/common';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Messages({
  id,
  type = 'message',
  messageType,
  callback,
}: {
  id: string;
  type?: 'message' | 'comment';
  messageType?: MessageType;
  callback?(): void;
}) {
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const messagesRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [messages, setMessages] = useState<IMessage[]>([]);

  useEffect(() => {
    fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
    });
  }, [messages]);

  function fetchMessages(loading = true) {
    setIsLoading(loading);
    getMessages(id)
      .then((res) => setMessages(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message || `An error occurred while fetching ${type}s.`;
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsLoading(false));
  }

  function handleSendMessage() {
    setIsSending(true);
    sendMessage({
      entityId: id,
      body: message,
      userId: user?._id,
      userName: `${user?.firstName} ${user?.lastName}`,
      type: messageType,
    })
      .then(() => {
        setMessage('');
        callback?.();
        showSnackbar({
          severity: 'success',
          message: `${type === 'message' ? 'Message sent' : 'Comment added'} successfully`,
        });
        fetchMessages(false);
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as any)?.message ||
          `An error occurred while ${type === 'message' ? 'sending message' : 'adding comment'}.`;
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsSending(false));
  }

  function getFormattedDate(date: string, placeholder = 'N/A') {
    if (!date) return placeholder;
    return dayjs(date).format('D MMM YY hh:mm A');
  }

  return (
    <>
      <Box sx={{ height: '4px' }}>{isLoading && <LinearProgress />}</Box>
      <Box sx={{ maxHeight: '30em', overflowY: 'scroll' }} ref={messagesRef}>
        {messages.length > 0 ? (
          messages.map((message, index) => (
            <S.MessageItem key={index}>
              <S.MessageNameContainer>
                <div>
                  <S.MessageAvatar></S.MessageAvatar>
                </div>
                <div>
                  <div>{message.userName}</div>
                  <div className="message-time">{getFormattedDate(message.dateTime)}</div>
                </div>
              </S.MessageNameContainer>
              <div
                className="message-body"
                dangerouslySetInnerHTML={{ __html: message.body || '' }}
              />
            </S.MessageItem>
          ))
        ) : (
          <Box component="p" sx={{ ml: 2 }}>
            No {type}s found
          </Box>
        )}
      </Box>
      <S.MessageInputContainer>
        <ReactQuill
          theme="snow"
          modules={{
            toolbar: [
              [{ size: ['small', false, 'large', 'huge'] }],
              ['bold', 'italic'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              ['link', 'clean', 'unlink'],
            ],
          }}
          value={message}
          onChange={(html) => setMessage(html)}
        />
      </S.MessageInputContainer>
      <S.MessageActions>
        <div>
          <LoadingButton
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            onClick={handleSendMessage}
            disabled={message.trim().length === 0}
            loading={isSending}
          >
            {type === 'message' ? 'Send Message' : 'Add Comment'}
          </LoadingButton>
        </div>
      </S.MessageActions>
    </>
  );
}

export default Messages;
