import { useState } from 'react';
import { GridColDef, GridRowParams, GridRowsProp, MuiEvent } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';

import Tooltip from '../shared/Tooltip';
import useAuth from '../../hooks/useAuth';
import useSnackbar from '../../hooks/useSnackbar';

import { UserRole } from '../../types/users';
import {
  ORDER_STATUS_MAP,
  ORDER_STATUS_COLOR_MAP,
  ORDER_TEAM_STATUS_COLOR_MAP,
} from '../../constants';
import SS from '../shared/styles';

const initialDialogData = {
  id: '',
  name: '',
};

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  type?: 'orders' | 'related-orders';
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  onRowClick?(
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>
  ): void;
  fetchOrders?(): void;
  onDeleteClick?(id: string, name: string, callback: () => void): void;
}

function formatDate(date: string | null) {
  if (!date) {
    return date;
  }
  return dayjs(date).format('DD MMM YYYY');
}

function OrdersTable(props: IProps) {
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const {
    page,
    pageSize,
    rows,
    totalElements,
    loading,
    type = 'orders',
    fetchOrders,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onDeleteClick,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogData, setDialogData] = useState(initialDialogData);

  const isRelatedOrders = type === 'related-orders';
  const columns: GridColDef[] = [
    {
      field: 'orderId',
      headerName: 'Order Id',
      width: 180,
      sortable: false,
      renderCell: (params) =>
        isRelatedOrders ? (
          <SS.Link to={`/orders/${window.btoa(params.row._id)}`} target="_blank">
            {params.row.orderId}
          </SS.Link>
        ) : (
          renderCellWithTooltip(params.row.orderId as string)
        ),
    },
    {
      field: 'referenceId',
      headerName: 'Reference Id',
      width: 180,
      sortable: false,
      renderCell: (params) => renderCellWithTooltip(params.row?.referenceId || ''),
    },
    {
      field: 'client',
      headerName: 'Client',
      width: 240,
      sortable: false,
      renderCell: (params) => renderCellWithTooltip(params.row?.client?.name || ''),
    },
    {
      field: 'createdTime',
      headerName: 'Created Date',
      width: 115,
      sortable: false,
      renderCell: (params) => formatDate(params.row?.createdTime),
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 115,
      sortable: false,
      renderCell: (params) => formatDate(params.row?.dueDate),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <SS.Status sx={{ bgcolor: ORDER_STATUS_COLOR_MAP[params.row?.status] }}>
          {ORDER_STATUS_MAP[params.row?.status] || params.row?.status}
        </SS.Status>
      ),
    },
    {
      field: 'teamStatus',
      headerName: 'Team Status',
      width: 220,
      sortable: false,
      renderCell: (params) => (
        <div>
          {Object.entries(params.row?.teamStatus || {}).map(([teamName, status], i) => {
            return (
              <div key={i}>
                {`${teamName}: `}
                <span
                  style={{ fontWeight: 500, color: ORDER_TEAM_STATUS_COLOR_MAP[status as string] }}
                >
                  {startCase(lowerCase(status as string))}
                </span>
              </div>
            );
          })}
        </div>
      ),
    },
  ];

  function renderCellWithTooltip(content: string) {
    return (
      <Tooltip
        arrow
        title={
          <IconButton aria-label="copy" size="small" onClick={() => handleCopy(content)}>
            <ContentCopyIcon fontSize="small" htmlColor="#ffffff" />
          </IconButton>
        }
      >
        <span>{content}</span>
      </Tooltip>
    );
  }

  function getColumns() {
    if (user?.role === UserRole.SUPER_ADMIN && !isRelatedOrders) {
      return columns.concat([
        {
          field: 'actions',
          headerName: 'Actions',
          sortable: false,
          align: 'center',
          headerAlign: 'center',
          renderCell: (params) => (
            <IconButton
              size="small"
              onClick={(event) =>
                handleOptionsClick(event, params.row._id as string, params.row.orderId as string)
              }
            >
              <MoreIcon fontSize="small" />
            </IconButton>
          ),
        },
      ]);
    }

    return columns;
  }

  function handleMenuClose() {
    setAnchorEl(null);
    setDialogData(initialDialogData);
  }

  function handleOptionsClick(event: React.MouseEvent<HTMLElement>, id: string, name: string) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setDialogData({ id, name });
  }

  function handleDeleteClient() {
    if (dialogData.id) {
      onDeleteClick?.(dialogData.id, dialogData.name, () => {
        handleMenuClose();
        fetchOrders?.();
      });
    }
    handleMenuClose();
  }

  function handleCopy(content: string) {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        showSnackbar({ severity: 'success', message: 'Copied!' });
      })
      .catch(() => {
        showSnackbar({
          severity: 'error',
          message: 'Unable to copy',
        });
      });
  }

  return (
    <>
      <SS.DataGrid
        sx={{
          ...(isRelatedOrders && { border: 'none' }),
          '.MuiDataGrid-row': { cursor: onRowClick ? 'pointer' : 'default' },
          '.MuiDataGrid-cell': { ...(!isRelatedOrders && { wordBreak: 'break-word' }) },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
        rows={rows}
        columns={getColumns()}
        getRowId={(row) => row._id}
        getRowHeight={() => 'auto'}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        rowCount={totalElements}
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        loading={loading}
        pagination
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        {...(!isRelatedOrders && { onRowClick })}
      />
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleDeleteClient()}>Delete</MenuItem>
      </Menu>
    </>
  );
}

export default OrdersTable;
