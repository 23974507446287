import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';

const Styles = {
  MessageItem: styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    fontSize: '0.875rem',
    '&:not(:last-child)': { borderBottom: '1px solid #E4EBF8' },
    '.message-time': { fontSize: 12 },
    '.message-body': { padding: 0 },
  })),
  MessageNameContainer: styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
  })),
  MessageActions: styled(Box)(({ theme }) => ({
    display: 'flex',
    margin: theme.spacing(2),
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  })),
  MessageAvatar: styled(Avatar)(({ theme }) => ({
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: '0.3px solid #FF6F24',
    backgroundColor: '#FFEBE6',
    color: '#FF6F24',
    marginRight: theme.spacing(1),
  })),
  MessageInputContainer: styled(Box)(({ theme }) => ({
    padding: theme.spacing(2, 2, 0),
    borderTop: '1px solid #E4EBF8',
    '.ql-editor ': {
      minHeight: '150px',
      maxHeight: '150px',
    },
  })),
  DateFilter: styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
    width: 230,
    display: 'inline-flex',
    '.filter-button': {
      padding: '6px 21px',
      color: 'rgba(0, 0, 0, 0.6)',
      borderColor: '#c1c1c1',
      fontWeight: 400,
      justifyContent: 'space-between',
      transition: 'none',
      '&:hover': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
      },
    },
  })),
  DateFilterPopover: styled(Popover)(({ theme }) => ({
    marginTop: theme.spacing(0.5),
    '.container': { padding: theme.spacing(2) },
    '.date-picker-container': {
      marginTop: theme.spacing(2),
      display: 'flex',
      '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
    },
    '.date-picker': { fontSize: '0.875rem' },
  })),
};

export default Styles;
